import axiosClient from "../../apiClient";

export const ApiMixin = {
    methods: {
        doGet(api, params) {
            window.REQUEST_TIMESTAMP = new Date().getTime();
            return axiosClient.get(api, { params })
                .then(response => {
                    // TODO interceptor to handle error messages
                    return response.data;
                })
                .catch(function (error) {
                    if (this != undefined)
                    {
                        this.makeToast('danger', error);
                    }
                });
        },
        doPost(api, params) {
            window.REQUEST_TIMESTAMP = new Date().getTime();
            return axiosClient.post(api, params)
                .then(response => {
                    // TODO interceptor to handle error messages
                    return response.data;
                })
                .catch(function (error) {
                    if (this != undefined)
                    {
                        this.makeToast('danger', error);
                    }
                });
        }
    }
}