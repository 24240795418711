<template>
  <img v-if="!config.DISABLE_GLOBAL_HEADER_LOGO_LINK" @click="goToShop" id="LogoImage" :aria-label="$t('ariaLabel.logo')"
       :src="logoPath"/>
  <img v-else id="LogoImage" :aria-label="$t('ariaLabel.logo')" :src="logoPath" />
</template>

<script>
export default {
  name: "logo-image",
  computed: {
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
    config() {
      return window.config;
    }
  },
  methods: {
    goToShop() {
      if (window.config.DISABLE_GLOBAL_HEADER_LOGO_LINK) {
        return;
      }

      if (window.config.TABLE_CHECKOUT.enabled)
      {
        window.location = window.config.TABLE_CHECKOUT.homepage;
        return;
      }

      if (window.config.IS_PURE_ACCOUNTMANAGER)
      {
        return;
      }
      var vueInstance = this;
      if (!(vueInstance.$route.path == '/Shop'))
      {
        if (window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
        {
          vueInstance.$confirm(
              vueInstance.$t('notification.resetBasketInfo'),
              vueInstance.$t('subTitle.resetBasketInfo'),
              'info',
              {
                confirmButtonText: vueInstance.$t('button.confirm'),
                cancelButtonText: vueInstance.$t('button.cancel')
              }).then(() => {
            if (!(vueInstance.$route.path == '/Shop') && !window.TableMode)
            {
              vueInstance.$router.push({path: '/Shop'})
            }
          });
        }
        else
        {
          vueInstance.$router.push({path: '/Shop'});
        }

      }
    },
  }
}
</script>

<style scoped>

</style>