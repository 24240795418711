<template>
  <div class="topUpPageWrapper">
    <div class="col-12 subPageHeader">
      <div class="row">
        <h1>{{  $t('title.charge')}}</h1>
        <div v-if="!showTopUpPayment" class="ml-auto row" style="padding-right: 7px;">
          <b-button class="btn btn-primary btn-justify btn-right" @click="goBack(false)">
            {{$t('button.back')}}
          </b-button>
        </div>
      </div>
    </div>

    <div class="innerScroll">
      <b-container fluid>
        <b-row v-if="!showTopUpPayment">
          <b-col v-if="!Utils.isEmpty(config.TOPUP.predefinedValues)" id="predefinedTopUpValues" cols="12" md="6" class="paddingRight">
            <b-table-simple class="tableResponsive" simple small borderless caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="12" class="text-left">{{ $t('subTitle.chargeAmount') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="predefinedValue in config.TOPUP.predefinedValues" :key="predefinedValue">
                  <b-button class="btn btn-primary topUpBtn" v-bind:key="predefinedValue" @click="confirmTopUp(predefinedValue)" :aria-label="$t('ariaLabel.chargeAmount') + predefinedValue">{{ Utils.formatCurrency(predefinedValue, config.ACCOUNT_BALANCE_CURRENCY, true, config.ACCOUNT_BALANCE_SEPARATOR) }}</b-button>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
          <b-col v-if="!showTopUpPayment" cols="12" md="6" class="paddingLeftSm">
            <b-table-simple class="tableResponsive" simple small borderless caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="12" class="text-left">{{ $t('subTitle.chargeCustomAmount') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-input-group class="input-group topUpInputField" :prepend="Utils.getCurrencySymbol(config.ACCOUNT_BALANCE_CURRENCY)">
                    <currency-input class="form-control" id="chargeAmount" name="chargeAmount"
                                    v-model="chargeAmount"
                                    :options="{
                                      currency: config.ACCOUNT_BALANCE_CURRENCY,
                                      currencyDisplay: 'hidden',
                                      valueRange: { min: config.TOPUP.customField.minCustomFieldValue }}"
                                    aria-describedby="chargeAmount-feedback">
                    </currency-input>
                  </b-input-group>
                </b-tr>
                <b-tr>
                  <b-button type="button" @click.stop="confirmTopUp(chargeAmount)" class="btn btn-primary topUpBtn">{{ $t('button.confirm') }}</b-button>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row v-if="showTopUpPayment && paymentList.length > 1">
          <b-col id="paymentMethods" cols="12" md="12">
            <b-table-simple class="tableResponsive" small borderless caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th colspan="12" class="text-left">{{ $t('subTitle.paymentSelect') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td colspan="12" class="justify-content-center" style="padding-left: 0; padding-right: 0;">
                    <b-button v-if="payment.paymentType == 4 && paymentList.length > 1" style="margin-top: auto" class="btn btn-primary col-12" v-on:click="onSubmit">{{ $t('button.payByCreditCard') }}</b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-container>

      <credit-card-payment-saferpay v-if='showSaferpay == true' :subCode="currentPaymentSubCode"></credit-card-payment-saferpay>
      <credit-card-payment-securepay v-if='showSecurepay == true' :subCode="currentPaymentSubCode" ></credit-card-payment-securepay>
      <credit-card-payment-novalnet v-if='showNovalnet == true' :subCode="currentPaymentSubCode"></credit-card-payment-novalnet>
      <credit-card-payment-payone v-if='showPayone == true' :subCode="currentPaymentSubCode" :callback="closeTopUp" ></credit-card-payment-payone>
      <credit-card-payment-adyen v-if='showAdyen == true' :isTopup="true" @closeTopUpModal="closeTopUp"></credit-card-payment-adyen>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import CreditCardPaymentComponentSaferpay from "@/components/main-components/CreditCardPaymentComponentSaferpay.vue";
import CreditCardPaymentComponentSecurepay from "@/components/main-components/CreditCardPaymentComponentSecurepay.vue";
import CreditCardPaymentComponentNovalnet from "@/components/main-components/CreditCardPaymentComponentNovalnet.vue";
import CreditCardPaymentComponentPayone from "@/components/main-components/CreditCardPaymentComponentPayone.vue";
import CreditCardPaymentComponentAdyen from "@/components/main-components/CreditCardPaymentComponentAdyen.vue";
import CurrencyInput from "@/components/sub-components/CurrencyInput.vue";

export default {
  name: "top-up",
  components: {
    'currency-input': CurrencyInput,
    'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
    'credit-card-payment-securepay': CreditCardPaymentComponentSecurepay,
    'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
    'credit-card-payment-payone': CreditCardPaymentComponentPayone,
    'credit-card-payment-adyen': CreditCardPaymentComponentAdyen
  },
  data() {
    return {
      show: false,
      showTopUpPayment: false,
      currentPayment: "",
      currentPaymentSubCode: "",
      showSaferpay: false,
      showSecurepay: false,
      showNovalnet: false,
      showPayone: false,
      showAdyen: false,
      chargeAmount: null,
    };
  },
  computed: {
    Utils() {
      return Utils
    },
    config(){
      return window.config;
    },
    currentlySelectedPayment (){
      if(this.currentPayment == "Saferpay")
      {
        console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
        return this.currentPayment + "~~" + this.currentPaymentSubCode;
      }
      else if(this.currentPayment == "PayOne")
      {
        if(this.currentPaymentSubCode !== "")
        {
          return this.currentPayment + "~~" + this.currentPaymentSubCode;
        }
        else
        {
          return this.currentPayment;
        }
      }
      else
      {
        return this.currentPayment;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async confirmTopUp(amount) {
      var vueInstance = this;

      if (!Utils.isEmpty(vueInstance.chargeAmount)) {
        amount = vueInstance.chargeAmount;
      }

      await vueInstance.doPost('TCPConfigurePrepaymentJ.action', {
        json: "true",
        amount: amount.toString(),
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });

      await vueInstance.confirmAndPayOrder();
    },
    async confirmAndPayOrder() {
      var vueInstance = this;

      const result = await vueInstance.doGet('TCPPaymentJ.action', {
        json: "true",
        orderType: window.orderType,
        comment: this.comment
      });

      if (result.exception !== undefined) {
        return;
      }

      if (result.requiresLogin == true) {
        return;
      }

      vueInstance.processPayment();
      vueInstance.paymentList = result.paymentDetails.filter(function (x) {
        return (x.code != "customercard" && x.code != "cod")
      });
      if (vueInstance.paymentList.length == 1) {
        vueInstance.onSubmitTopUp(vueInstance.paymentList[0]);
      }
      vueInstance.showTopUpPayment = true;

      window.onmessage = async function (e) {
        let result = e.data.toString();
        console.log("WINDOW ONMESSAGE EVENT " + result);

        if (result == "S") {
          if (vueInstance.currentPaymentSubCode == "P") {
            //TODO
            vueInstance.closeTopUp(0);
            return;
          }

          await vueInstance.doPost('ProcessSaferpayJ.action', {
            json: "true",
            vresult: result
          });

          //TODO
          vueInstance.closeTopUp(0);
        } else if (result == "A") {
          vueInstance.showSaferpay = false;
          vueInstance.showSecurepay = false;
          vueInstance.showNovalnet = false;
          vueInstance.showPayone = false;
          vueInstance.showAdyen = false;

          setTimeout(async () => {
            vueInstance.makeToast('success', vueInstance.$t('notification.paymentAbort'));
          }, 300);

          if (window.isParent) {
            await vueInstance.$router.push({path: '/StudentAccounts'});
          } else {
            await vueInstance.$router.push({path: '/Account'});
          }

        } else if (result == "F") {
          vueInstance.showSaferpay = false;
          vueInstance.showSecurepay = false;
          vueInstance.showNovalnet = false;
          vueInstance.showPayone = false;
          vueInstance.showAdyen = false;

          setTimeout(async () => {
            vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
          }, 300);

          if (window.isParent) {
            await vueInstance.$router.push({path: '/StudentAccounts'});
          } else {
            await vueInstance.$router.push({path: '/Account'});
          }
        } else if (result.startsWith("payoneA")) {

          await vueInstance.doGet('ProcessPayOneJ.action', {
            json: "true",
            vresult: result.replace('payone', '')
          });

        } else if (result.startsWith("payoneB")) {
          setTimeout(async () => {
            vueInstance.makeToast('danger', vueInstance.$t('notification.paymentAbort'));
          }, 300);

          if (window.isParent) {
            await vueInstance.$router.push({path: '/StudentAccounts'});
          } else {
            await vueInstance.$router.push({path: '/Account'});
          }
        } else if (result.startsWith("payoneD")) {
          setTimeout(async () => {
            vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
          }, 300);

          if (window.isParent) {
            await vueInstance.$router.push({path: '/StudentAccounts'});
          } else {
            await vueInstance.$router.push({path: '/Account'});
          }
        }
      }
    },
    onSubmitTopUp(paymentMethod){
      this.currentPayment = paymentMethod.code;
      if(paymentMethod.subCode != null || paymentMethod.subCode != undefined)
      {
        if( paymentMethod.subCode != ""){
          this.currentPaymentSubCode = paymentMethod.subCode;
        }
      }
      this.onSubmit();
    },
    processPayment(){
      return;
    },
    async onSubmit() {
      var vueInstance = this;

      const result = await vueInstance.doPost('TCPPaymentSubmitJ.action', {
        deliveryAddress: "0",
        paymentMethod: this.currentlySelectedPayment,
        json: "true",
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });

      if (result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T") {
        console.log(vueInstance.currentPayment);
        vueInstance.showSaferpay = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Securepay") {
        console.log(vueInstance.currentPayment);
        vueInstance.showSecurepay = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Novalnet") {
        console.log(vueInstance.currentPayment);
        vueInstance.showNovalnet = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "PayOne") {
        vueInstance.showPayone = true;
        console.log(vueInstance.currentPayment);
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "Adyen") {
        console.log(vueInstance.currentPayment);
        vueInstance.showAdyen = true;
        vueInstance.$forceUpdate();
        return;
      }

      if (result.paymentMethod == "StripeModule") {
        return;
      }

      if (result.exception !== undefined) {
        vueInstance.makeToast('danger', this.$t("notification.chargeError"));
        vueInstance.goBack();
      }

      window.shoppingCart = null;
      await vueInstance.$router.push({path: '/Payment'});
    },
    async closeTopUp(status){
      let vueInstance = this;
      if(status >= 0)
      {
        setTimeout(async () => {
          vueInstance.makeToast('success', vueInstance.$t('notification.chargeSuccessful'));
          await vueInstance.doGet('TCPStartJ.action', {
            json: "true",
            emptyBasket: "true"
          });
          window.shoppingCart = null;
        }, 1000);

        if (window.isParent) {
          await vueInstance.$router.push({path: '/StudentAccounts'});
        } else {
          await vueInstance.$router.push({path: '/Account'});
        }
      }
    }
  },
  mounted() {
    const mainElement = document.getElementById('app');
    if (mainElement) {
      mainElement.focus();
    }

    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  beforeDestroy() {
    window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  async beforeRouteLeave (to,from,next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
      scrollDiv.scrollTop = 0;
    }

    clearInterval(this.intervalId);
    next();
  },
  async created() {
    let scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }
  },
};
</script>
<style scoped>

#content-wrapper{
  overflow-y: hidden;
  padding-top: 0px;
}

.topUpBtn {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0;
  width: 100%;
}

.topUpInputField{
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
  width: 100%;
}
</style>