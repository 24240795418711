<template>
<nav class="col-11" v-show="config.SHOW_GLOBAL_LEFT_NAVIGATION"> 
  <div id="global-left" class="elementBorder globalLeft fontContent">  
    <div id="logo-image-wrapper" v-show="config.SHOW_GLOBAL_LEFT_LOGO"><logo-image></logo-image></div> 
    <!-- Top Navigation Menu -->
    <div class="topnav">   
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> 
      <!-- Navigation links (hidden by default) -->
      <a id="bar-click" v-on:click="navFunction">
          <i class="fa fa-bars"></i>
      </a>  
      <div id="myNavLinks">    

        <u>
          <li v-for="menu in menus" v-bind:key ="menu.Order" class="active nav-item"> <a @click="goTo(menu.Link)">{{ $t(menu.Title) }}</a> </li>
        </u>  
      </div>
      <!-- "Bar icon" to toggle the navigation links -->
     
    </div>  
  </div>
 </nav>
</template>

<script>  
  import LogoImage from '../sub-components/LogoImage.vue'
  export default {
    name:"global-left",       
    components:{
       'logo-image' : LogoImage
    },   
    props: {
        menus: {
            Type: Array,
            required:true
        },
        config: {
            Type: Array
        }
    },
    methods: 
    {
      goTo(link){
        var vueInstance = this;       
        if(link == "/Shop")
        {         
          if(!(vueInstance.$route.path == '/Shop'))
          {     
            // if(window.shoppingCart != undefined)
            // {

            // }
            // else
            // {

            // }       
            vueInstance.$confirm(
              vueInstance.$t('notification.resetBasketInfo'),
              vueInstance.$t('subTitle.resetBasketInfo'),
              'info',
              { 
                  confirmButtonText: vueInstance.$t('button.confirm'),
                  cancelButtonText: vueInstance.$t('button.cancel')
              }).then(() => {
              if(!window.TableMode)
              {
                  return "/Shop";
              }
              else
              {
                  return "";
              }   
            });
          }  
        }
        else
        {
          this.$router.push({path: link});
        }
      },
      // checkLink(link){
      //   // if(link == "/Shop")
      //   // {
      //   //     var vueInstance = this;
      //   //     if(!(vueInstance.$route.path == '/Shop'))
      //   //     {
      //   //         vueInstance.$confirm(
      //   //             vueInstance.$t('notification.resetBasketInfo'),
      //   //             vueInstance.$t('subTitle.resetBasketInfo'),
      //   //             'info',
      //   //             { 
      //   //                 confirmButtonText: vueInstance.$t('button.confirm'),
      //   //                 cancelButtonText: vueInstance.$t('button.cancel')
      //   //             }).then(() => {
      //   //             if(!window.TableMode)
      //   //             {
      //   //                 return "/Shop";
      //   //             }
      //   //             else
      //   //             {
      //   //                 return "";
      //   //             }   
      //   //         });
      //   //     }  
      //   // }
      //   // else
      //   // {
      //   //   return link;
      //   // }
      // },
      navFunction()
      {
        var x = document.getElementById("myNavLinks");
        if (x.style.display === "block") {
          x.style.display = "none";
        } else {
          x.style.display = "block";
        }
      }
    }
  }

</script>

<style scoped>

.topnav {
  overflow: hidden;
  background-color: var(--background-color);
  position: relative;  
  text-align: start;
}

.topnav u {
  list-style-type: none;
  text-decoration: none;
}

/* Style navigation menu links */
.topnav a {
  color: var(--text-color);
  
  text-decoration: none;
  font-size: 17px;
  display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  /* background-color: #ddd;
  color: black; */
}

/* Style the active link (or home/logo) */
.active {
  /* background-color: var(--background-color); */
  color: var(--text-color);
}

.nav-item{
  min-height: 110px; border: 0.5px solid white; padding: 30px; font-size: 30px; min-width: 250px;
}

@media screen and (max-width: 1000px) {
    #myNavLinks{
        display:none;
    }
    #bar-click {
        display:block;
    }
}
@media screen and (min-width: 1000px) {
    #bar-click {
        display:none;
    }
}

.nav-item a{
  font-size: 30px;
  text-align:left;
}
</style>