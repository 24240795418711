<template>
    <div class="qrLandingPageWrapper">

        <!-- <video autoplay muted loop id="myVideo">
            <source src="@/assets/videos/landing-page.mp4" type="video/mp4">
        </video>



        <div class="qrLandingPageBody">
            <div class="col-12">
                <img style="max-width:600px;width:100%" src="@/assets/images/logo.png" />
            </div>   

            <div class="col-12 fontTitle fontLandingTitle" v-if="landSuccess">
                {{$t('qrLandingPageTitle')}} {{shop}}
            </div>            

            <div class="col-12 fontContent fontLandingContent" v-if="landSuccess">
                {{$t('qrLandingPageBody')}} {{table}}
            </div>            

            <div class="col-12 btnLandingWrapper" v-if="landSuccess">
                <b-button class="btn btn-primary btnLanding" v-if="openTableExists" @click="payOrder">{{$t('button.pay')}}</b-button>
                <b-button class="btn btn-primary btnLanding" @click="startOrder">{{$t('button.order')}}</b-button>
            </div>   
        </div> -->

       


        <table class="shoppingCartItemsTable"  style="table-layout:fixed">
            <thead>
                <th class="shoppingCartHeaderBig">{{ $t('text.article') }}</th>
                <!-- <th class="shoppingCartHeaderSmall"></th> -->
                <th class="shoppingCartHeaderBig">{{ $t('text.sum') }}</th>
            </thead>

            <tr v-for="item in orderDetails.order.menuItems" v-bind:key="item.kkProdId">
                <td class="shoppingCartLine">{{item.name}}
                    <!-- <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                        <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                    </div> -->
                </td>
                <!-- <td class="shoppingCartLine">
                    {{item.formattedPrice}}
                </td> -->

                <!-- <td class="shoppingCartLine">
                </td> -->
                <!-- <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td>   <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} </span>-->
                <td class="shoppingCartLine mainColor" style="font-weight:600;">{{item.weightOrVolume != undefined ? item.formattedPrice : item.formattedPrice}}  </td>
            </tr>    

             <tr style="border-top:2px solid var(--main-color)">
                    <td class="shoppingCartLine" style="font-weight:600;">{{$t('sum')}}
                        <!-- <div v-if="item.hasOptions" style="padding:0;text-align:right;">
                            <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
                        </div> -->
                    </td>
                    <!-- <td class="shoppingCartLine">
                        {{item.formattedPrice}}
                    </td> -->

                    <!-- <td class="shoppingCartLine">
                    </td> -->
                    <!-- <td class="shoppingCartLine mainColor" style="text-align:center; font-size:14px;">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}} </td> <span style="font-weight:400; font-size:14px;">{{item.weightOrVolume != undefined ? " - " + item.formattedPrice + "/kg" : ""}} -->
                    <td class="shoppingCartLine mainColor" style="font-weight:800;">{{ orderDetails.order.formattedTotal }}</td>
                </tr>               
        </table>

        <div class="form-group">
            <input name="tipField" id="tipField" class="form-control tipField" type="number" placeholder="Add Tip"/>
            <b-button class="btn btn-primary tipFieldBtn"  @click="addTip">+</b-button>
        </div>      

        <b-button class="btn btn-primary" @click="payAtTable">{{$t('button.pay')}}</b-button>
        <b-button class="btn btn-primary" @click="cancel">{{$t('button.cancel')}}</b-button>
        
        <credit-card-payment-saferpay v-if=' showSaferpay == true '></credit-card-payment-saferpay>
        <credit-card-payment-novalnet v-if=' showSaferpay == true '></credit-card-payment-novalnet>                
        
    </div>
</template>


<script>
import CreditCardPaymentComponentSaferpay from '../main-components/CreditCardPaymentComponentSaferpay.vue'
import CreditCardPaymentComponentNovalnet from '../main-components/CreditCardPaymentComponentNovalnet.vue'
import $ from 'jquery'
// import { required, sameAs } from 'vuelidate/lib/validators'

export default {
     data() {
        return {
            orderDetails: {
                Type:Object
            },
            showSaferpay: false,
   
            // footerSaveDisplay: "",
            // headerSaveDisplay: "",
            // landSuccess: false,
            // openTableExists: false,
            // openTable: {
            //     Type:Object,
            //     default: null
            // }
        }
	},
    components: {
        'credit-card-payment-saferpay': CreditCardPaymentComponentSaferpay,
        'credit-card-payment-novalnet': CreditCardPaymentComponentNovalnet,
    },
    props:{
        // shop: {
        //     Type: String
        // },
        // table: {
        //     Type: String
        // }
    },
    computed: {
        basketItems(){
 
            return window.openTable.itemList;
        }
    },    
    methods: { 
        async addItemsToOrder() {
          var vueInstance = this;

          const result = await vueInstance.doPost('TCPAddItemsToOrderJ.action', {
            json: "true",
            emptyBasket: "true"
          });

          window.openTable = result.order;

          setTimeout(() => {
            vueInstance.getOrderDetails();
          }, 500);
        },
        async addTip() {
          var vueInstance = this;

          var tip = $('#tipField').val();
          const result = await vueInstance.doPost('TCPAddTipJ.action', {
            json: "true",
            tip: tip,
            calculateOrder: window.customerLoggedIn ? true : false,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if ((result.exception !== undefined) || (result.item !== undefined)) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.getItemFailed'));
            return;
          }

          vueInstance.addItemsToOrder();

          $('#tipField').val('');
        },
        async payAtTable() {
          var vueInstance = this;
          const result = await vueInstance.doPost('TCPPayAtTableJ.action', {
            guid: window.openTable.guid,
            amount: window.openTable.total,
            lockTable: "false",
            json: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if ((result.exception !== undefined) || (result.item !== undefined)) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.getItemFailed'));
            return;
          }

          vueInstance.onSubmit();
        },
        async onSubmit() {
          window.onmessage = async function (e) {

            if (e.data == "S" || e.data == "A" || e.data == "F") {
              vueInstance.isLoading = true;
            }

            if (e.data == "S") {
              await vueInstance.doGet('SaferpayJ.action', {
                json: "true"
              });

              vueInstance.isLoading = false;
              vueInstance.$router.push({path: '/CheckoutFinished'});
            } else if (e.data == "A") {
              vueInstance.makeToast('danger', vueInstance.$t('notification.paymentAborted'));
              vueInstance.$router.push({path: '/Address'});

            } else if (e.data == "F") {
              vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
            }
          }

          this.isLoading = true;

          var vueInstance = this;
          const result = await vueInstance.doPost('TCPPaymentSubmitJ.action', {
            deliveryAddress: "0",
            paymentMethod: "Saferpay~~P",
            json: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (result.paymentMethod == "Saferpay~~P") {
            vueInstance.isLoading = false;

            vueInstance.showSaferpay = true;

            setTimeout(() => {
              var objDiv = document.getElementsByClassName("content")[0];
              var iFrame = document.getElementById("ExternalPaymentFormFrame");

              objDiv.scrollTop = objDiv.scrollHeight;
              iFrame.scrollIntoView({behavior: "smooth"});
            }, 300);

            return;
          }

          if (result.paymentMethod == "StripeModule") {
            return;
          }

          if (result.exception !== undefined) {
            vueInstance.isLoading = false;
            vueInstance.makeToast('danger', vueInstance.$t('notification.exceptionMessage'));
            return;
          }

          if (result.page == "tcp.catalog.checkoutfinished.page") {
            if (vueInstance.currentlySelectedPayment == "cod" || vueInstance.currentlySelectedPayment == "customercard") {
              await vueInstance.doGet('TCPCheckoutFinishedJ.action', {
                json: "true"
              });
            }

            vueInstance.isLoading = false;
            window.shoppingCart = undefined;
            vueInstance.$router.push({path: '/CheckoutFinished'});
            return;
          }

          vueInstance.isLoading = false;
          window.shoppingCart = null;
          vueInstance.$router.push({path: '/Payment'});
        } ,
        cancel(){
            this.$router.go(-1);
        },
        async getOrderDetails() {
          var vueInstance = this;
          const result = await vueInstance.doGet('TCPOrderDetailsJ.action', {
            guid: window.openTable.guid,
            json: "true",
            shopId: window.openTable.shopId,
          });

          if ((result.exception !== undefined) || (result.item !== undefined)) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.getItemFailed'));
            return;
          }

          vueInstance.orderDetails = result;
        }
    },
    mounted() {
        this.getOrderDetails();
    },
    beforeMount() {
        
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave (to, from , next) {
        next();
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {  
        next();   
    },
}
</script>

<style scoped>

.tipFieldBtn {
    width:30%;
    display:inline-block;
       margin:-4px 0 0 5px;
}


.tipField {
    width:60%;
    display:inline-block;
    margin-top: 5px;
}

    .shoppingCartHeader{
        width:30%;
    }

    .shoppingCartLine{
        height:40px;    
    }

    .shoppingCartHeaderBig{
        width:40%;
    }

    .shoppingCartHeaderSmall{
        width:20%;
    }

    .shoppingCartItemsTable{
        width:100%;
    }

    .shoppingCartSummaryTable{
        width:100%;
        border-top: 1px solid var(--main-color);
    }

    .shoppingCartSummaryLine{
        font-weight:600;    
    }

</style>