<template>
    <div class="shopCard elementBorder" @click="selectShop">
        <div class="shopCardImage">
            <img :src="image" />          
        </div>
        <div class="shopCardData">
            <p class="mainColor" style="font-weight:500;">{{ name }}</p>
      
            <div v-for="address in addresses" v-bind:key="address.formattedAddress" v-html="address.formattedAddress"></div>
            <!-- + '\n' + (address.emailAddr == undefined ? '' : address.emailAddr) -->

            <a @click.stop v-if="url != undefined" :href="url" class="shopUrl">{{ $t('text.shopContact') }}</a>


            
        </div>
    </div>
</template>

<script>
export default {
    name:'shop-card',
    props: {
        id:{
            Type: Number, 
            required:true
        },
        name:{
            Type: String,
            required:true
        },
        code:{
            Type: String,
            required:true
        },
        image:{
            Type: String,            
        },
        addresses:{
            Type: Array,            
        },
        url: {
            Type: String
        },
        callback: {
            Type: Function
        },        
    },
    mounted() {
    },
    methods: {
        selectShop() {
            this.callback(this.id, this.code);
        }
    }
}
</script>