import Vue from 'vue';
import VueRouter from 'vue-router';
import VueSimpleAlert from "vue-simple-alert";
import App from './App.vue';
import VueCookies from 'vue-cookies'
import VueValidate from 'vuelidate'
import VueLoading from 'vue-loading-overlay'
import Vuex from 'vuex'
import VueAxios from "vue-axios";
import axios from 'axios';

import {BootstrapVue, BootstrapVueIcons, IconsPlugin} from "bootstrap-vue";
import routes from "./routes";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCartPlus,
  faCircleMinus,
  faCirclePlus,
  faCircleInfo,
  faTrashCan,
  faPlus,
  faCreditCard,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {ApiMixin} from "@/components/mixins/apiMixin";
import {PaymentMixin} from "@/components/mixins/paymentMixin";
import {AlertMixin} from "@/components/mixins/alertMixin";
import {loadCss} from "@/utils/loadCss";
import i18n from "@/i18n";

Vue.use(Vuex)
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VueSimpleAlert);
Vue.use(VueCookies);
Vue.use(VueValidate);
Vue.use(VueLoading);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.mixin(ApiMixin);
Vue.mixin(AlertMixin);
Vue.mixin(PaymentMixin);

Vue.config.productionTip = false

// Loading a CSS file at runtime
loadCss(process.env.BASE_URL + '/settings/resources/custom.css').then(() => {
  console.log('CSS file loaded successfully');
}).catch((error) => {
  console.error(error);
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faCartPlus, faCircleMinus, faCirclePlus, faCircleInfo, faTrashCan, faPlus, faCreditCard, faClockRotateLeft)

const router = new VueRouter({ routes });

const vueApp = new Vue({
  router,
  i18n,
  render: h => h(App),
  routes
});

// Global error handler
vueApp.errorHandler = (err, vueInstance, info) => {

  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", vueInstance);
  console.log("Error info:", info);

  // UI notification
  vueInstance.makeToast('warning', vueInstance.$t('notification.genericError'));
};

vueApp.$mount('#app');

// eslint-disable-next-line no-unused-vars
Vue.mixin({
  data: function() {
    return {
      globalCartObject: {
        Type:Array
      },
      TableMode : false
    }
  },
  computed: {
    shoppingCart() {
      return this.globalCartObject;
    }
  },
  methods: {
    updateShoppingCart: async function () {
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPConfirmOrderJ.action', {
        calculateOrder: window.customerLoggedIn ? true : false,
        orderType: window.customerLoggedIn ? window.orderType : undefined,
      });

      window.shoppingCart = result.basketTile.basketItems;
      window.shoppingTile = result.basketTile;
    }
  }
});

