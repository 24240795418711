<template>
    <div class="orderConfirmationWrapper">

        <div class="col-12 subPageHeader" style="padding-left: 0;"  >
            <h1>{{ $t('subTitle.orderConfirmation') }}</h1>
        </div>

      <div class="innerScroll">
        <flow :currentPoint="2"> </flow>

        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>


        <div class="col-12 checkoutPadding">
            <ul class="col-12" style="padding-left: 0px;padding-right: 0px; padding-top:0px;">
                <div style="margin-left:10%; margin-right:10%;">
                    <div v-for="item in pointCampaigns" v-bind:key="item.campaignId"  style="text-align:right; padding: 5px; border-bottom:0.2px solid lightgray">
                        <span style="font-size:20px;float:left;">{{ getCampaignName(item.campaignId) }}</span>
                        
                        <span v-if="item.gainedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsGained') }} </span>
                            <span style="color:var(--main-color); font-size:20px;"> {{ item.gainedPoints }} </span>
                        </span>
                        
                        <span v-if="item.usedPoints > 0" style="display: inline-block; width: 30%; text-align: center; ">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsBurned') }} </span>
                            <span style="color:var(--main-color); font-size:20px;"> {{ item.usedPoints }} </span>
                        </span>
                    </div>
                </div>
                <br/>

                <table class="shoppingCartItemsTable"  style="table-layout:fixed;" v-if="basketItems.length > 0">
                    <thead>
                        <th style="width:100%;"></th>
                        <!-- <th class="shoppingCartHeaderBig" style="text-align:left;">{{ $t('text.article') }}</th>
                        <th class="shoppingCartHeader" style="width:17%;"></th>
                        <th class="shoppingCartHeader">{{ $t('text.sum') }}</th>
                        <th class="shoppingCartHeaderSmall"></th>
                        <th class="shoppingCartHeaderSmall"></th>
                        <th class="shoppingCartHeaderSmall"></th> -->
                    </thead>
        
                    <tr v-for="item in basketItems" v-bind:key="item.id" style="height:65px;">
                        <td class="shoppingCartEntry">
                            <cart-entry :item="item" :callbackAddNormal="addArticle" :callbackEdit="changeItemQuantity" :callbackAddWeight="weightAlert" :callbackDelete="deleteItem" :callbackRemove="removeArticle"></cart-entry>
                        </td>
                    </tr>            
                </table>

      
                <div class="shoppingCartDiscounts" v-if="discounts.length > 0">
                    <div class="shoppingCartDiscountsTitle">{{ $t('text.discounts') }}</div>
                    <div v-for="discount in discounts" v-bind:key="discount.id">
                        <div class="shoppingCartDiscountsEntry">
                            <span class="shoppingCartDiscountsEntryName">{{discount.description}}</span>
                            <span class="shoppingCartDiscountsEntryValue">{{formatDiscount(discount.value)}}</span>
                        </div>
                    </div>
                </div>
            </ul>

            <div class="col-12">
                <div class="col-6" style="font-size:20px; text-align:left;">{{$t('sum')}}:</div>
                <div class="col-6" style="font-size:30px; text-align:right;padding-left: 0; padding-right: 0;">{{basketTotal}} </div>
            </div>

            <div class="col-12" v-if="paymentLists.length > 0">
                <!-- <div class="shoppingCartDiscountsTitle">{{ $t('discounts') }}</div> -->
                <!-- <div v-for="paymentList in paymentLists" v-bind:key="paymentList.paymentId" class="col-12">
                        <span class="col-6 shoppingCartDiscountsEntryName" style="font-size:20px; text-align:left;">{{$t('subTitle.subsidy')}}</span>
                        <span class="col-6 shoppingCartDiscountsEntryValue" style="font-size:30px; text-align:right;">{{formatDiscount(paymentList.paymentAmount)}}</span>
                </div> -->
                <span class="col-6" style="font-size:16px; text-align:left;">{{$t('subTitle.subsidy')}}</span>
                <span class="col-6" style="font-size:22px; text-align:right; color:gray;">{{formatCurrency(paymentLists[0].paymentAmount)}}</span>
            </div>

            <div class="col-12" v-if="customerLoggedIn == true && promotionsEnabled == true">
                <input type="text" class="col-12 commentField" v-model="promoBarcode" :placeholder="$t('field.promotionBarcode')" />
                <b-button @click="onPromotionBarcodeSubmit" class=" col-2 btn btn-primary floatRight" style="">{{ $t('button.promotionBarcodeCheck') }}</b-button>
            </div>

            <input v-if="config.SHOW_DESCRIPTION_TEXT_INPUTS" type="text" style="margin-bottom: 10px;" class="col-12 commentField" v-model="comment" :placeholder="$t('field.comment')" />


            <div class="col-12" v-if="config.SHOW_ORDERCONFIRMATION_TC_CONSENT">
              <i18n class="text-left" path="text.acceptTermsAndPrivacy" tag="p">
                <template v-slot:terms>
                  <a :href="config.COMPANY.links.privacy" target="_blank">{{ $t('link.terms') }}</a>
                </template>
                <template v-slot:privacy>
                  <a :href="config.COMPANY.links.terms" target="_blank">{{ $t('link.privacy') }}</a>
                </template>
              </i18n>
            </div>
            <div class="col-12" v-if="ageVerification">
              <p class="text-left">{{ $t('text.acceptCustomerAge') }}</p>
            </div>

            <div class="forwardBackward">
              <b-button class="btn btn-primary" @click="goBack(false)" style="margin-left: 0" >{{$t('button.back')}}</b-button>
              <b-button type="button" @click="onSubmit" class="btn btn-primary" style="margin-right: 0">{{$t('button.continue')}}</b-button>
            </div>

        </div>
      </div>
    </div>
</template>

<script>
import FlowIndicator from '../main-components/FlowIndicator.vue'
import VueLoading from 'vue-loading-overlay'
import ShoppingCartEntry from '../sub-components/ShoppingCartEntry.vue'
import $ from 'jquery'
import Swal from "sweetalert2";

export default {
    data(){
        return {
            comment:"",
            basketItems: {
                Type: Array
            },
            basketTotal: "",
            quantityButtonDisabled: 'qtyBtnDisabled',
            pointCampaigns: [],   
            discounts: [],
            paymentLists: [],
            pointCampaignsDefinitions: [],
            isLoading: false,
            delivery_fee_article_prod_id: 0,
            customerLoggedIn: false,   
            promoBarcode:""  
        }
    },
    computed: {
        config() {
            return window.config;
        },  
        ageVerification(){
            return window.ageVerification;
        } ,
        promotionsEnabled(){
            return window.config.PROMOTION_CODE_ENABLED;
        }
    },
    components: {
        'loading' : VueLoading,
        'flow' : FlowIndicator,
        'cart-entry' : ShoppingCartEntry,
    },
    mounted(){
        window.paymentOrigin = "Shop";
    },
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            if(myDiv != null)
            myDiv.scrollTop = 0;
        })        
    }, 
    async beforeMount() {
      var vueInstance = this;
      vueInstance.isLoading = true;

      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {json: "true"});

      vueInstance.isLoading = false;

      if (result.requiresLogin == true) {
        let path = window.immediate ? "/Guest" : "/Login";
        vueInstance.$router.push(path);
        return;
      }

      vueInstance.customer = result.customer;
      if (result.wondCustomer != undefined) {
        vueInstance.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
      }

      if (result.customer != undefined) {
        if (result.customer.type != undefined) {
          if (result.customer.type == 2) {
            window.isGuestCheckout = true;
          }
        }
      }

      window.pointCampaignsDefinitions = vueInstance.pointCampaignsDefinitions;
      vueInstance.customerLoggedIn = true;
      window.customerLoggedIn = true;
      vueInstance.calculateOrder();
    },
    methods: { 
        async onPromotionBarcodeSubmit() {
          var vueInstance = this;
          vueInstance.isLoading = true;
          if (this.promoBarcode == "") {
            console.log(this.$t('emptyPromotionCode'));
          }

          window.promotionBarcode = this.promoBarcode;
          const result = await vueInstance.doPost('TCPCalculateOrderJ.action', {
            orderType: window.orderType,
            json: "true",
            promotionBarcode: this.promoBarcode,
            comment: this.comment
          });

          vueInstance.isLoading = false;
          if (result.exception != undefined) {
            if (result.exception.code == 611) {
              vueInstance.makeToast('danger', vueInstance.$t('invalidPromotionCode'));
            }

            vueInstance.promoBarcode = "";
            window.promotionBarcode = "";
          }

          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTile = result.basketTile;
          vueInstance.basketTotal = result.basketTile.formattedTotal;
          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

          vueInstance.dataCounter++;
          await vueInstance.updateCart();
          vueInstance.$forceUpdate();
        },
        formatDiscount(value){
            var formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: window.config.SUBSIDY_CURRENCY,

            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });

            return formatter.format(value); /* $2,500.00 */
        },
        formatCurrency(value) {
            // switch (this.config.) {
            //     case value:

            //         break;

            //     default:
            //         break;
            // }
            // var formatter = new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 4});
            // return formatter.format(value);
            var formatter = new Intl.NumberFormat('de-DE', {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        getCampaignName(campaignId){
            for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
            {
                if(this.pointCampaignsDefinitions[i].id == campaignId)
                {
                    return this.pointCampaignsDefinitions[i].description;
                }
            }

            return "";
        },
        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        async changeItemQuantity(element, articleId) {
          var value = element.srcElement.value;
          if (!this.isNumeric(value)) {
            this.makeToast('danger', this.$t('notification.inputQuantityNotValid'));
            element.srcElement.value = "1";
            return;
          }

          var vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: articleId,
            quantity: value,
            orderType: window.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (value == 0) {
            await vueInstance.deleteItem(articleId);
            return;
          }

          vueInstance.isLoading = false;
          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal;
          vueInstance.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          vueInstance.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.changeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.changeItemMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.changeItemQtyFailed'));
            return;
          }
        },  
        async addItemsToOrder() {
          if (window.TableMode == true && window.openTable != undefined) {
            var vueInstance = this;
            vueInstance.isLoading = true;

            const result = await vueInstance.doPost('TCPAddItemsToOrderJ.action', {
              json: "true",
              emptyBasket: true
            });

            vueInstance.isLoading = false;
            window.openTable = result.order;
          }
        },
        async calculateOrder() {
          let vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPCalculateOrderJ.action', {
            orderType: window.orderType,
            json: "true",
            promotionBarcode: window.promotionBarcode
          });

          vueInstance.isLoading = false;

          if (result.requiresLogin == true) {
            vueInstance.$router.push({path: "/Login"});
          }

          if (result.exception !== undefined) {
            vueInstance.makeToast('danger', this.$t("exceptionMessage"));
            return;
          }

          if (result.errorCode > 0) {
            vueInstance.makeToast('danger', result.erroMsg);
            return;
          }

          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          vueInstance.basketTotal = result.basketTile.formattedTotal;
          vueInstance.pointCampaigns = result.basketTile.pointCampaigns != undefined ? result.basketTile.pointCampaigns : [];
          vueInstance.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          vueInstance.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          if (result.basketTile.basketItems == undefined || result.basketTile.basketItems.length == 0) {
            vueInstance.$router.push({path: "/Menu"});
            vueInstance.makeToast('danger', vueInstance.$t('notification.noProducts'));
          }
        },
        weightAlert(){
            this.makeToast('danger', this.$t('notification.weightArticleQuantityChange'))
        },
        async deleteItem(article) {
          let vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPRemoveMenuItemFromCartJ.action', {
            id: article.id,
            orderType: window.orderType,
          });

          vueInstance.isLoading = false;
          if (result.exception !== undefined) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemFailed'));
            return;
          }

          await vueInstance.updateCart();
          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

          if (result.basketTile.basketItems.length < 1) {
            vueInstance.$router.push({path: '/Menu'});
            return;
          }

          vueInstance.basketTotal = result.basketTile.formattedTotal;
          vueInstance.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          vueInstance.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          vueInstance.$forceUpdate();
          vueInstance.dataCounter++;
        },
        goBack(){
            this.$router.go(-1);
        },
        async addArticle(article) {
          var vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: article.id,
            quantity: article.quantity + 1,
            orderType: window.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (article.quantity + 1 == 0) {
            await vueInstance.deleteItem(article);
            return;
          }

          vueInstance.isLoading = false;

          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";

          if (result.basketTile.basketItems.length < 1) {
            vueInstance.$router.push({path: '/Menu'});
            return;
          }

          vueInstance.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          vueInstance.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          vueInstance.$forceUpdate();
          await vueInstance.updateCart();
          vueInstance.dataCounter++;

          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('removeItemMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemFailed'));
            return;
          }
        },
          async updateCart() {
            let vueInstance = this;
            vueInstance.isLoading = true;

            const result = await vueInstance.doPost('TCPConfirmOrderJ.action', {
              calculateOrder: window.customerLoggedIn ? true : false,
              orderType: window.customerLoggedIn ? window.orderType : undefined,
              promotionBarcode: vueInstance.promoBarcode != undefined ? vueInstance.promoBarcode : undefined
            });

            vueInstance.isLoading = false;

            if (result.exception !== undefined) {
              vueInstance.makeToast('danger', this.$t("exceptionMessage"));
              return;
            }

            if (result.errorCode > 0) {
              vueInstance.makeToast('danger', result.erroMsg);
              return;
            }

            window.xtoken = result.xsrfToken != undefined ? atob(result.xsrfToken) : undefined;
            window.shoppingCart = result.basketTile.basketItems;
            window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
            vueInstance.basketItems = result.basketTile.basketItems;
            vueInstance.basketTotal = result.basketTile.formattedTotal;
          },
        async removeArticle(article) {
          var vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: article.id,
            quantity: article.quantity - 1,
            orderType: window.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (article.quantity - 1 == 0) {
            vueInstance.deleteItem(article);
            return;
          }

          vueInstance.isLoading = false;

          if (result.basketTile.basketItems.length < 1) {
            vueInstance.$router.push({path: '/Menu'});
            return;
          }

          window.shoppingCart = result.basketTile.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          vueInstance.discounts = result.basketTile.discounts != undefined ? result.basketTile.discounts : [];
          vueInstance.paymentLists = result.basketTile.paymentsList != undefined ? result.basketTile.paymentsList : [];

          vueInstance.$forceUpdate();
          await vueInstance.updateCart();
          vueInstance.dataCounter++;

          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('removeItemMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemFailed'));
            return;
          }
        },
        onSubmit() {

            window.comment = this.comment;
            this.addItemsToOrder();

            var path = "";
            if(window.TableMode == true)
            {
                if(window.openTable != undefined)
                    path = '/TablePay'
                else
                    path = '/Payment'
            }
            else
            {
                switch(window.orderType)
                {
                    case 'delivery':{
                        path = '/Address'
                        break;
                    }
                    case 'takeaway':{
                        path = '/Payment'
                        break;
                    }
                    default: {
                        path = '/Payment'
                        break;
                    }

                }
            }
            
            this.$router.push({ path: path });     
        }  
    },
    async created() {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'hidden';
      }
    },
    beforeRouteLeave(to, from, next) {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'auto';
      }

      Swal.close();
      next();
    }
}
</script>

<style scoped>

.shoppingCartItemsTable {
    color: var(--text-color);
    width:100%;
    background-color:#f2f2f2;
}

.commentField {
    width:100%;
    height:100%;
    border: 0.2px solid var(--main-color);
}


.shoppingCartDiscounts {
    border-bottom: 1px solid rgba(236,236,236,1);
    /* border-top: 1px solid rgba(236,236,236,1); */
    padding:20px;
    text-align:left;    
}

.shoppingCartDiscountsEntry {
    text-align:left;    
    padding-left:5%;
}

.shoppingCartDiscountsTitle {
    padding: 0px;
    text-align:left;
    margin-bottom:10px;
    font-weight:600;
    font-size:15px;
    color: var(--main-color);
}

.shoppingCartDiscountsEntryName{
    width:40%;
    display: "inline-block"
}

.shoppingCartDiscountsEntryValue{
    margin-left:10%;
}

.forwardBackward {
  border-top: 1px solid var(--main-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.innerScroll{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 280px);
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .innerScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 220px);
    width: 100%;
  }
}

#content-wrapper
{
  overflow-y: hidden;
}

</style>