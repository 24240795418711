// OrderOptionsPage.vue

<template>            
    <div id="orderOptionsForm">
        <div v-if="showChoice"> <!-- wrapper to prevent showing an empty shop-card when the element is still loading -->
            <div class="orderOptionsTitle col-lg-12 mx-auto fontTitle">
                {{ $t('title.orderOptions') }}
            </div> 

            <div class="col-lg-12  mx-auto fontField" style="text-align:left;">{{ $t('field.orderType') }}</div>

            <select-picker class="col-lg-12 mx-auto" :options="orderTypeOptions" :callback="setOrderType" v-model="fldOrderType"></select-picker>

            <div v-if="showDateTimeOptions">             
                <div v-if="showDateTimeOptions" class="col-lg-12 mx-auto fontField" style="text-align:left;"> {{  $t('field.date') }}</div>
                <select-picker v-if="showDateTimeOptions" class="col-lg-12  mx-auto" :options="dateOptions" :callback="setDate" v-model="fldDay"></select-picker>
            </div>        
            
            <div name="wrap" :show="false" v-if="showDateTimeOptions && showTimeOption">
                <div class="col-lg-12 mx-auto fontField" style="text-align:left;">{{  $t('field.time') }}</div>
                <select-picker class="col-lg-12  mx-auto" :options="timeOptions" :callback="setTime"  v-model="fldTime"></select-picker>
            </div>
            
            <div :show="false" class="col-12 mx-auto  floatRight" style="padding:0;">
                <div class="btn btn-group  floatRight">
                    <b-button type="button" class="btn btn-primary floatRight" @click="selectTime">{{ $t('button.confirm') }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import SelectPicker from '../sub-components/SelectPicker.vue'
import { required, minLength } from 'vuelidate/lib/validators'
import moment from 'moment'
import Utils from "@/utils/utils";

export default {
    data(){
        return {
            timeSlotDays: {
                Type:Array
            },
            dateSetId: -1,
            dateSet: "",
            timeSetId: -1,
            showTimeOption: false,
            orderTypeSet:{
                Type:String,
                default: '0'
            },
            orderTypes: {
                Type:Array
            },
            fldDay : {
                type: String,
                minLength: minLength(4)
            },
            fldTime: {
                type: String,
                minLength: minLength(4)
            },
            fldOrderType: {
                type: String,
                minLength: minLength(4)
            },
            timeslots: [],
            showChoice: false,
            showDateTimeOptions: false,
            shopName: ""
        }
    },
    validations: {
        dateSetId: {
            required
        },
        timeSetId: {
            required
        },
        fldOrderType: {
            required
        }
    },
    computed: {
        dateSetBool() {
            if(this.dateSetId.value !== undefined)
                return true;
            else
            {
                console.log(false);
                return false;
            }
        },  
        config(){
            return window.config;
        },
        dateOptions () {
            var list = [];
            var formattedList = [];
            if(this.timeSlotDays === undefined)
            {
                return formattedList;
            }

            var daysWithTimeslots = this.filterDays(this.timeSlotDays);                    
            for(var i = 0; i < daysWithTimeslots.length; i++)
            {
                list.push(new Date(daysWithTimeslots[i].date));
            }
            
            var options = this.config.ORDER_OPTIONS_DAY_OPTIONS;
            // var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            for(var y = 0; y < list.length; y++)
            {     
                formattedList.push( { title: list[y].toLocaleDateString(this.$i18n.locale.replace('_', '-'), options), idxDate: y, idxTime: null, day:list[y] } );
            }

            return formattedList;
        },
        timeOptions () {
            var list = [];
    
            if(this.timeSlotDays === undefined || this.timeslots === undefined || this.timeslots === null)
            {
                return list;
            }

            for(var i = 0; i < this.timeslots.length; i++)
            {               
                var toTimeArray = this.timeslots[i].toTime.split(':');
                var toTime = toTimeArray[0] + ':' + toTimeArray[1];

                var timeTitle = toTime;

                if (this.config.USE_TIMESLOT_SELECT_FROM_TO) {
                    var fromTimeArray = this.timeslots[i].fromTime.split(':');
                    var fromTime = fromTimeArray[0] + ':' + fromTimeArray[1];

                    timeTitle =  fromTime + " - " + toTime;
                }

                var newOption = {                   
                    title:timeTitle, //fromTime + " - " +
                    idxDate: this.dateSetId, idxTime: i
                };

                list.push(newOption);
            }

            if(list.length > 0)
            {
                console.log("AP setTime Liste:");
                console.log(list[0]);
                this.setTime(list[0]);
            }
            
            return list;
        },
        orderTypeOptions() {  
            if( this.orderTypes.filter(function(y) { return (y.code == 0) }).length > 0 )
            {
                return this.config.SETTING_ORDER_TYPES;
            }

            var orderTypesOptions = [];
            if(this.orderTypes.length == 0 || this.orderTypes == null || this.orderTypes == undefined)
            {
                return orderTypesOptions;
            }
            for(var i = 0; i < this.orderTypes.length; i++)
            {
                if(this.orderTypes[i] == undefined)
                {
                    continue;
                }
                console.log(this.orderTypes[i].name);
                orderTypesOptions.push(this.orderTypes[i].name)
            }
            return orderTypesOptions;
        }
    },
    props: {
        callback: {
            Type: Function
        }
    },
    methods: {
        moment() {
            return moment();
        },
        getDateRemoveDays(dateString, daysToRemove) {
            var date = new Date(dateString);
            date.setDate(date.getDate() - daysToRemove);     
            return date;
        },
        filterDays(days) {
            var vueInstance = this;          
            var currentDate = new Date();
            var blockedDays = -1;
            if(!(this.shopName == undefined || this.shopName == null || this.shopName == "") && window.config.ORDER_BLOCK_NEXT_DAYS_SPECIFC_SHOPS.some(function(value) { return value.shopName == vueInstance.shopName})){
                blockedDays = window.config.ORDER_BLOCK_NEXT_DAYS_SPECIFC_SHOPS.find(o => o.shopName ==  this.shopName).blockNextDays;
            } else {
                blockedDays = window.config.ORDER_BLOCK_NEXT_DAYS;
            }

            return days.filter(function(x) { return (x != null ) })
            .filter(function(x) { return (x.timeslots != undefined ) })
            .filter(function(y) { return (y.timeslots.length > 0) })
            .filter(function(z) { return ( vueInstance.getDateRemoveDays(z.date, blockedDays) >  currentDate )});
        },
        async selectTime() {
          if (this.orderTypeSet != "delivery" && (this.dateSetId == -1 || this.timeSetId == -1 || this.timeSlotDays == undefined)) {
            this.makeToast('danger', this.$t('notification.orderOptionsNotSelected'));
            return;
          }

          Date.prototype.yyyymmdd = function () {
            var mm = this.getMonth() + 1;
            var dd = this.getDate();

            return [this.getFullYear(),
              (mm > 9 ? '' : '0') + mm,
              (dd > 9 ? '' : '0') + dd
            ].join('-');
          };

          var vueInstance = this;
          var pickupTime = "";
          var date = new Date();

          switch (vueInstance.orderTypeSet) {
            case 'delivery':
              if (!Utils.isEmpty(vueInstance.timeSlotDays)) {
                var daysWithTimeslots = vueInstance.filterDays(vueInstance.timeSlotDays);

                if (daysWithTimeslots.length == 0) {
                  vueInstance.makeToast('danger', vueInstance.$t('notification.noTimeslotsAvailable'));
                  return;
                }

                date = new Date(vueInstance.timeSlotDays[vueInstance.dateSetId].date);
                pickupTime = date.yyyymmdd() + "T" + vueInstance.timeslots[vueInstance.timeSetId].toTime;
              } else {
                pickupTime = daysWithTimeslots[0].date;
              }
              console.log("delivery timeslot selected " + pickupTime);
              break;
            case 'takeaway':
              if (vueInstance.timeSlotDays != undefined) {
                date = new Date(vueInstance.timeSlotDays[vueInstance.dateSetId].date);
                pickupTime = date.yyyymmdd() + "T" + vueInstance.timeslots[vueInstance.timeSetId].toTime;
              } else {
                pickupTime = daysWithTimeslots[0].date;
              }
              break;
            case 'dinein':
              if (vueInstance.timeSlotDays != undefined) {
                date = new Date(vueInstance.timeSlotDays[vueInstance.dateSetId].date);
                pickupTime = date.yyyymmdd() + "T" + vueInstance.timeslots[vueInstance.timeSetId].toTime;
              } else {

                pickupTime = daysWithTimeslots[0].date;
              }
              break;
            default:

              break;
          }

          window.timeString = pickupTime;

          const result = await vueInstance.doPost('TCPTimeSubmitJ.action', {
            pickupTime: pickupTime,
            json: "true"
          });

          vueInstance.timeSlotDays = result.availabilityList;
          window.timeSet = true;
          vueInstance.$router.push({path: "/Menu"});
        },
        setDate(dateObj) {
            this.showTimeOption = false;
            this.$forceUpdate();
            this.dateSetId = dateObj.idxDate;   
            window.dateSetId = dateObj.idxDate;
            this.dateSet = moment(dateObj.day).format("YYYY-MM-DD");       
            setTimeout(() => {                             
                this.getTimeslots();
            }, 10);
        },
        async getTimeslots() {
          var vueInstance = this;

          var orderType = -1;
          switch (this.orderTypeSet) {
            case "dinein":
              orderType = 1;
              break;
            case "takeaway":
              orderType = 2;
              break;
            case "delivery":
              orderType = 3;
              break;
          }

          const result = await vueInstance.doGet('TCPGetTimeSlotOccupancyJ.action', {
            orderType: orderType,
            day: this.dateSet,
            json: "true"
          });

          if (!Utils.isEmpty(result.timeSlots))
          {
            vueInstance.timeslots = result.timeSlots.filter(slot => slot.slots > 0 && slot.usable > 0);
          }

          // AP 20220509 A timing error overwrote timeSetId. Selecting a Timeslost should still select the correct one.
          if (vueInstance.timeOptions.length > 0 && orderType == 3) {
            vueInstance.timeSetId = vueInstance.timeOptions[0].idxDate;
          }
          vueInstance.showTimeOption = true;
          setTimeout(() => {
            vueInstance.$forceUpdate();
          }, 10);
        },
        setTime(timeObj) {            
            this.timeSetId = timeObj.idxTime;
            window.timeSetId = timeObj.idxTime;     
        },
        setOrderType(orderTypeString) {
            this.orderTypeSet = orderTypeString;
            
            this.showTimeOption = false;
            console.log(orderTypeString);

            switch(this.orderTypeSet)
            {
                case 'delivery':
                    if(window.config.SHOW_DELIVERY_DATE_AND_TIME_SELECT){
                        this.showDateTimeOptions = true;
                    }else{
                        this.showDateTimeOptions = false;
                    }
                    if(this.dateOptions != undefined)
                    {
                        if(this.dateOptions.length > 0)
                        {
                            setTimeout(() => {                             
                                this.setDate(this.dateOptions[0]);
                            }, 10);
                            
                        }
                    }
                    break;
                case 'takeaway':
                    this.showDateTimeOptions = true;
                    if(this.dateOptions != undefined)
                    {
                        if(this.dateOptions.length > 0)
                        {
                            setTimeout(() => {                             
                                this.setDate(this.dateOptions[0]);
                            }, 10);
                            
                        }
                    }
                    break;
                case 'dinein':
                    this.showDateTimeOptions = true;
                    if(this.dateOptions != undefined)
                    {
                        if(this.dateOptions.length > 0)
                        {
                            setTimeout(() => {                             
                                this.setDate(this.dateOptions[0]);
                            }, 10);
                        }
                    }                 
                    break;
                default:
                    this.showDateTimeOptions = false;
                    break;
            }

            window.orderType = orderTypeString;
            if(window.config.COOKIES_ENABLED){
                this.$cookies.set('orderType', orderTypeString);
            }            
        }
    },
    components:{
        'select-picker' : SelectPicker,        
    },
    async beforeMount() {
      var vueInstance = this;
      vueInstance.isLoading = true;

      const result = await vueInstance.doGet('TCPTimeJ.action', {
        json: "true",
        enableCaching: false,
        crcIn: "e046f966"
      });

      vueInstance.shopName = result.shopName;
      vueInstance.orderTypes = result.orderTypes;
      vueInstance.$forceUpdate();
      vueInstance.isLoading = false;
      if (result.availabilityList === undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.noTimeslotsAvailable'));
      }
      vueInstance.timeSlotDays = vueInstance.filterDays(result.availabilityList);
      vueInstance.showChoice = true;
      vueInstance.setOrderType(vueInstance.orderTypeOptions[0]);
    }
}
</script>