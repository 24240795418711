<template>
  <div>
    <b-card>
      <b-card-header>
        Weekly Food Plan
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col v-for="(day, index) in daysOfWeek" :key="index" class="mb-4" md="4">
            <b-card>
              <b-card-header>
                {{ day }}
                <b-button variant="outline-primary" size="sm" class="float-right" @click="addProduct(index)">
                  Add Product
                </b-button>
              </b-card-header>
              <b-card-body>
                <ul class="list-group">
                  <li v-for="(product, productIndex) in weekPlan[index]" :key="productIndex" class="list-group-item d-flex justify-content-between align-items-center">
                    {{ product }}
                    <b-button variant="outline-danger" size="sm" @click="removeProduct(index, productIndex)">
                      Remove
                    </b-button>
                  </li>
                </ul>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Modal for Adding Product -->
    <b-modal v-model="showModal" @ok="confirmAddProduct">
      <template #modal-title>Add Product for {{ daysOfWeek[activeDay] }}</template>
      <b-form-group label="Product Name">
        <b-form-input v-model="newProduct" required></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      weekPlan: [[], [], [], [], [], [], []],
      showModal: false,
      newProduct: '',
      activeDay: null,
    };
  },
  methods: {
    addProduct(dayIndex) {
      this.activeDay = dayIndex;
      this.newProduct = '';
      this.showModal = true;
    },
    confirmAddProduct() {
      if (this.newProduct) {
        this.weekPlan[this.activeDay].push(this.newProduct);
        this.newProduct = '';
      }
    },
    removeProduct(dayIndex, productIndex) {
      this.weekPlan[dayIndex].splice(productIndex, 1);
    },
  },
};
</script>

<style>
/* Add any custom styles here if needed */
</style>
