<template>
    <div style="padding-top: 10vh;">

        <div v-if="paymentFailed"> {{$t('notification.paymentError')}}</div>
        <b-button v-if="paymentFailed" @click="returnToOrder()" class="btn btn-primary">{{$t('button.paymentEndBack')}}</b-button>

        <!-- <b-button @click="loginTest">Test</b-button> -->

    </div>
</template>

<script>

export default {
    data() {
        return {
            paymentFailed: false,
        }
    },
    props:{
        payment: {
            Type: String
        },
        result: {
            Type: String
        }
    },
    methods: {
        returnToOrder(){
            this.$router.push({ path: '/OrderConfirmation' });
        },
    },
    beforeMount(){
        window.shoppingCart = null;
    },
    async mounted() {
      var config = window.config;
      var vueInstance = this;
      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });

      vueInstance.customer = result.customer;
      if (result.customer != undefined) {
        if (result.customer.type != undefined) {
          if (result.customer.type == 2) {
            window.isGuestCheckout = true;
          }
        }
      }

      console.log("POSTING TOP MESSAGE: " + vueInstance.result);
      window.top.postMessage(vueInstance.result, '*');

      if (vueInstance.payment == "novalnet" && vueInstance.result == "S") {
        vueInstance.isLoading = false;
        vueInstance.$router.push({path: '/CheckoutFinished'});
      }

      if (vueInstance.result == "S") {
        vueInstance.$router.push({path: '/CheckoutFinished'});
      } else if (vueInstance.result == "F") {
        vueInstance.paymentFailed = true;
        if (!config.COOKIES_ENABLED == false && config.TABLE_CHECKOUT.enabled) {
          window.location = config.TABLE_CHECKOUT.homepage;
        }
      } else if (vueInstance.result == "A") {
        vueInstance.paymentFailed = true;
        if (config.COOKIES_ENABLED == false && config.TABLE_CHECKOUT.enabled) {
          window.location = config.TABLE_CHECKOUT.homepage;
        }
      }
    }
}

</script> 