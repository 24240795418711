<template>
    <div v-if="!hideOnErrorSSO">
        <b-navbar toggleable="md" type="light" variant="white" v-if="config.USE_CONTROL_GROUP_NAVBAR">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
        <div id="ControlGroupButtonClusterFixed" class="justify-content-center" v-if="!isInIFrame">
          <div class="headerColor col-auto mt-2" v-if="config.SHOW_HEADER_LOGOUT_TIMER">{{ timeRemaining() }}</div>
          <b-button v-if="config.SHOW_GLOBAL_CONTROL_GROUP_LOGO" class="btnLogo" @click.stop="goToHome"><img class="logoButton" :src="logoPath" /></b-button>
          <b-button v-if="showQRLandingButton" class="btn btn-secondary justify-content-center shoppingCartIcon headerColor" @click="goToQRLanding" :aria-label="$t('ariaLabel.toQRLanding')"><span class="gg-home justify-content-center"></span></b-button>
          <b-button class="btn btn-secondary justify-content-center shoppingCartIcon headerColor" v-if="!guestCheckout && showAccountButton && !accountManagerMode" @click="goToAccount" :aria-label="$t('ariaLabel.toAccountButton')"><span class="gg-user justify-content-center"></span></b-button>
        </div>  
        </b-collapse>
                <b-button v-if="!config.IS_PURE_ACCOUNTMANAGER && showShoppingCardOnLoginPage" class="btn btn-secondary justify-content-center shoppingCartIcon headerColor" @click="goToCart" :aria-label="$t('ariaLabel.shoppingCartButton')">
                <a aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40.971" height="28.709" viewBox="0 0 40 28">&gt;
                        <g transform="translate(-1490.289 -272.683)">
                            <path d="M1518.26,276.8h-21.411l-.85-4.119h-5.709v2.74h3.477l3.529,17.065h17.2v-2.739h-12.179l13.237-2.128Zm-19.2,10.693-1.645-7.952h17.333l-1.412,5.658Z" style="fill: var(--global-header-color);"></path>
                            <path d="M1500.583,294.538a2.958,2.958,0,1,0,2.957,2.959h0A2.96,2.96,0,0,0,1500.583,294.538Zm0,4.119a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1500.583,298.657Z" transform="translate(-0.405 -1.208)" style="fill: var(--global-header-color);"></path>
                            <path d="M1512.593,294.693a2.958,2.958,0,1,0,2.957,2.959h0A2.961,2.961,0,0,0,1512.593,294.693Zm0,4.119h0a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1512.593,298.812Z" transform="translate(-1.069 -1.217)" style="fill: var(--global-header-color);"></path>
                        </g>
                        <circle cx="25" cy="17" r="10.4817748" style="fill: var(--main-color)" v-if="cartCount > 0">
                        </circle>
                        <text dominant-baseline="middle" text-anchor="middle" fill="var(--btn-text-color)" font-size="14" x="24" y="19" v-if="cartCount > 0">{{cartCount}}</text>
                    </svg>
                </a>
            </b-button>
  </b-navbar>
            <div id="ControlGroupButtonClusterFixed" class="justify-content-center" v-else-if="!isInIFrame">
            <div class="headerColor col-auto mt-2" v-if="config.SHOW_HEADER_LOGOUT_TIMER">{{ timeRemaining() }}</div>
           <b-button v-if="config.SHOW_GLOBAL_CONTROL_GROUP_LOGO" class="btnLogo" @click.stop="goToHome"><img class="logoButton" :src="logoPath" /></b-button>
           <b-button v-if="showQRLandingButton" class="btn btn-secondary justify-content-center shoppingCartIcon headerColor" @click="goToQRLanding" :aria-label="$t('ariaLabel.toQRLanding')"><span class="gg-home justify-content-center"></span></b-button>
           <b-button class="btn btn-secondary justify-content-center shoppingCartIcon headerColor" v-if="!guestCheckout && showAccountButton && !accountManagerMode" @click="goToAccount" :aria-label="$t('ariaLabel.toAccountButton')"><span class="gg-user justify-content-center"></span></b-button>
            <b-button v-if="!config.IS_PURE_ACCOUNTMANAGER" class="btn btn-secondary justify-content-center shoppingCartIcon headerColor" @click="goToCart" :aria-label="$t('ariaLabel.shoppingCartButton')">
                <a aria-hidden="true">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40.971" height="28.709" viewBox="0 0 40 28">&gt;
                    <g transform="translate(-1490.289 -272.683)">
                      <path d="M1518.26,276.8h-21.411l-.85-4.119h-5.709v2.74h3.477l3.529,17.065h17.2v-2.739h-12.179l13.237-2.128Zm-19.2,10.693-1.645-7.952h17.333l-1.412,5.658Z" style="fill: var(--global-header-color);"></path>
                      <path d="M1500.583,294.538a2.958,2.958,0,1,0,2.957,2.959h0A2.96,2.96,0,0,0,1500.583,294.538Zm0,4.119a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1500.583,298.657Z" transform="translate(-0.405 -1.208)" style="fill: var(--global-header-color);"></path>
                      <path d="M1512.593,294.693a2.958,2.958,0,1,0,2.957,2.959h0A2.961,2.961,0,0,0,1512.593,294.693Zm0,4.119h0a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1512.593,298.812Z" transform="translate(-1.069 -1.217)" style="fill: var(--global-header-color);"></path>
                    </g>
                    <circle cx="25" cy="17" r="10.4817748" style="fill: var(--main-color)" v-if="cartCount > 0">
                    </circle>
                    <text dominant-baseline="middle" text-anchor="middle" fill="var(--btn-text-color)" font-size="14" x="24" y="19" v-if="cartCount > 0">{{cartCount}}</text>
                  </svg>
                </a>
            </b-button>
        </div>  
    </div>
</template>

<script>
export default {    
    data(){
        return {
            cartCount: 0,
            tableMode: false,
            guestCheckout: false,
            showAccountButton: true,
            showQRLandingButton: false,
            showShoppingCardOnLoginPage: false,
            hideOnErrorSSO: false,
        }
    }, 
    name:"control-group-fixed",   
    props:  {       
        changeLocale: {
            Type: Function,
            required: true
        },
        config: {
            Type: Array
        }
    },
    computed:{
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
        accountManagerMode(){
            return this.config.IS_PURE_ACCOUNTMANAGER;
        }
        // tableMode() {
        //     return window.TableMode;
        // }
    },
    beforeMount(){
        var vueInstance = this;
        setInterval(function(){
                        vueInstance.$forceUpdate(); 
            if(vueInstance.$route.path == "/Account" || vueInstance.$route.path == "/Login"){
                vueInstance.showAccountButton = false;
            }else{
                vueInstance.showAccountButton = true;
            }

            if(vueInstance.config.SHOW_GLOBAL_CONTROL_GROUP_HOME){
                if(vueInstance.$route.path == "/Account" && vueInstance.config.SHOW_ACCOUNT_QRLANDING_HOMEBUTTON){
                    vueInstance.showQRLandingButton = true;
                } else if(vueInstance.$route.path == "/ErrorSSO") {
                  vueInstance.hideOnErrorSSO = true;
                } else {
                  vueInstance.showQRLandingButton = false;
                }
            }

            if(vueInstance.$route.path == "/Login")
            {
              if(window.customerLoggedIn == undefined || !window.customerLoggedIn)
              {
                vueInstance.showShoppingCardOnLoginPage = false;
              }
              else
              {
                vueInstance.showShoppingCardOnLoginPage = true;
              }

            }
            else
            {
              vueInstance.showShoppingCardOnLoginPage = true;
            }

            if(vueInstance.config.IS_PURE_ACCOUNTMANAGER){
                vueInstance.showQRLandingButton = false;
            }

            vueInstance.guestCheckout = vueInstance.isGuestCheckout();

            var quantitySumm = 0;
            if(window.shoppingCart != undefined)
            {
                for(var i = 0; i < window.shoppingCart.length; i++)
                {
                    if(window.shoppingCart != undefined && window.shoppingCart[i] != undefined)
                    {
                        quantitySumm += window.shoppingCart[i].quantity;
                    }                    
                }       
            }
            
            vueInstance.cartCount = quantitySumm
            vueInstance.tableMode = window.TableMode;
        }, 400);
    }, 
    methods: {
        isGuestCheckout(){
            if(window.isGuestCheckout == true)
                return true;
            else
                return false;
        },
        timeRemaining(){
            var vueInstance = this;
            var remainingTimeMessage = "";

            if (vueInstance.config.SHOW_HEADER_LOGOUT_TIMER) {
              var remainingTimeInMillisecounds = window.remainingSessionTime;
              if (remainingTimeInMillisecounds > 0) {
                  var timeString = "";
                  if ((remainingTimeInMillisecounds / 60000) > 1) {
                      timeString = Math.round(remainingTimeInMillisecounds / 60000) + " min";
                  }else{
                      timeString = "< 1 min";
                  }
                  remainingTimeMessage = this.$t('text.timeRemaining') + "" + timeString;
              }
            }

            return remainingTimeMessage;
        },
        goToQRLanding() {
            //avoid redundant navigation since its throws a exception in the console of the site
            if(!(this.$route.path == '/QRLanding'))
            {
                this.$router.push({ path: '/QRLanding?h=true' })
            }        
        },
        goToAccount() {
            //avoid redundant navigation since its throws a exception in the console of the site
            if(!(this.$route.path == '/Account'))
            {
                this.$router.push({ path: '/Account' })
            }            
        },
        goToCart() {
            if(window.shopSelected != true)
            {
                return;
            }
            //avoid redundant navigation since its throws a exception in the console of the site
            if(!(this.$route.path == '/OrderConfirmation'))
            {
                this.$router.push({ path: '/OrderConfirmation' })
            }            
        },
        goToHome() {
            var vueInstance = this;
            if(!(vueInstance.$route.path == '/Shop'))
            {
                if(window.shoppingCart != undefined && window.shoppingCart != null && window.shoppingCart.length > 0)
                {
                    vueInstance.$confirm(
                        vueInstance.$t('notification.resetBasketInfo'),
                        vueInstance.$t('subTitle.resetBasketInfo'),
                        'info',
                        { 
                            confirmButtonText: vueInstance.$t('button.confirm'),
                            cancelButtonText: vueInstance.$t('button.cancel')
                        }).then(() => {
                        if(!(vueInstance.$route.path == '/Shop') && !window.TableMode)
                        {
                            vueInstance.$router.push({ path: '/Shop' })
                        }   
                    });
                }
                else
                {
                    if(!(vueInstance.$route.path == '/Shop') && !window.TableMode)
                    {
                        vueInstance.$router.push({ path: '/Shop' })
                    }   
                }
             
            }
            // else
            // {
            //     vueInstance.$router.push({ path: '/Shop' })
            // }
        
            //avoid redundant navigation since its throws a exception in the console of the site
                    
        }
    }
}
</script>

<style scoped>

.btn-secondary {
    height:50px;
    width:50px;
    border: none; 
    background-color: inherit;
}

/* path:hover{
    fill: white !important;
} */

/* .btn-secondary:hover{
    color:white !important;
}

.btn-secondary:hover + path{
    fill: white !important;
} */

.cartCount {
    padding-left: 2px;   
}

button {
    text-align:center;
    margin:0px;    
}

.btnLogo
{
    background-color: inherit;
    border: none;        
}

.logoButton {
    width: 80px;
    height: auto;   
    background-size:100% 100%;
    max-height: 56px;
}

@media screen and (max-width: 1000px){
    .logoButton{
        width:100px;
    }
}

.gg-user {
   height:20px;
   margin-left:8px;
   align-content: center;
}

.gg-home {
   margin: auto;
   align-content: center;
}

.shoppingCartIcon a svg {
    width: 25px;
    height: auto;
}

.shoppingCartIcon:focus{
    background-color:var(--focus-color);
    border:0px;
    box-shadow:none;
    border-radius:2px;
}

.navbar-collapse{
    flex-basis:unset;
}

</style>