<template>
  <transition name="fade">
    <dialog>
    <b-modal v-model="show" size="lg" title="modalTitle" centered aria-labelledby="modalTitle" hide-footer>
        <template #modal-header="{ close }">
            <slot name="header"/>
            <b-button type="button" class="btn btn-primary" @click="close()" :aria-label="$t('ariaLabel.closeModalButton')" style="min-width: auto; border-color: gray !important; margin-top: 3px;">
                <i class="fa fa-times" style=""></i>
            </b-button>
        </template>

        <template #default>
            <slot name="body" />
        </template>

        <template #modal-footer>
            <slot name="footer" />
             
        </template>
    </b-modal>
    </dialog>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector("body").classList.remove("overflow-hidden");
    },
    openModal() {       
      this.show = true;
      document.querySelector("body").classList.add("overflow-hidden");
    }
  }
};
</script>


<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  display:block;
  bottom: 0;
  left: 0;
  z-index: 200000;
  overflow-x: hidden;
  overflow-y: auto;
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;

    @media screen and (max-width: 720px) {
      display:none;
      // height:90%;
    }
  }
  &__dialog {
    position: relative;
    width: 900px;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 50px auto;    
    display: flex;
    flex-direction: column;
    z-index: 200002;
    @media screen and (max-width: 992px) {
      width: 90%;
      // height:90%;
    }
    
    @media screen and (max-width: 720px) {
      box-shadow: 0 0 300px rgb(0, 0, 0);
      // height:90%;
    }
  }
  &__close {
    min-width: 30px;
    height: 30px;
    border-radius:5px;
    border:none;
    background-color: var(--main-color);
    color:var(--main-color-highlight);
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 20px 0px;
  }
  &__body {
    padding: 0px 10px 10px 10px;
    overflow: revert;
    display: flow-root;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal{
  &__close:hover {
    width: 30px;
    height: 30px;
    border-radius:5px;
    border:0.2px solid var(--main-color);
    background-color: var(--main-color-highlight);
    color: var(--main-color);
  }
}
</style>
