<template>
    <div class="shopCards startPageBackgroundImage">

        <modal ref="shopModal" >
            <template v-slot:header>                
                <h1>{{selectedShop.name}}</h1>
            </template>

            <template v-slot:body>
                <div class="col-12">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding-left:0;" >
                        <div style="text-align:start; padding-bottom:10px;" v-if="showShopExtraDetails" v-html="selectedShop.custom6"> </div>
                        <div style="text-align:start" v-if="shopShopModalAddress" v-html="selectedShop.addresses[0].formattedAddress"></div>
                        <img class="imageModal" :src="selectedShop.image" />
                    </div> 
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="padding:0;">
                        <order-options-page></order-options-page>  
                    </div>     
                </div>
            </template>

            <template v-slot:footer>
                <div class="d-flex align-items-center justify-content-between">
                </div>
            </template>
        </modal>
        
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <!-- wrapper to prevent showing an empty shop-card when the element is still loading -->
        <div v-if="showChoice" class="shopCards">
            <div class="shopPageTitle">
                    {{ $t('subTitle.chooseShop') }}
            </div>   
            <shop-carousel v-if="showCarousel" :shops="shops" :callbackSelect="selectShop"> </shop-carousel>
            <div class="col-12 shopCardWrapper" style="padding: 2% 10% 15% 10%; align-items: center;justify-content: center;">
                <shop-card v-for="shop in shops" v-bind:key="shop.id" :id="shop.id" :addresses="shop.addresses" :image="shop.image" :name="shop.name" :url="shop.custom10" :code="shop.custom2" :callback="selectShop"></shop-card>
            </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </div>
</template>

<script>
import Modal from '../sub-components/Modal.vue'
import ShopCard from '../main-components/ShopCard.vue'
import VueLoading from 'vue-loading-overlay'
import Carousel from '../sub-components/ShopCarousel.vue'
import OrderOptionsPage from '../page-components/OrderOptionsPage.vue'
import $ from 'jquery'
import Utils from "@/utils/utils";
import {UserTypes} from "@/constants/constants";

export default {
    data() {
        return {
            isLoading: false,
            shops: {
                Type: Array
            },
            visible : true,
            showChoice: false,
            selectedShop: {
                Type: Object
            },
            shopIdPreselected: 0,
            jwtString: "",
            showCarousel: true,
            shopShopModalAddress: false,
            showShopExtraDetails: false,
            deltaY: 0
        }
    },
    computed:{
        config() {
            return window.config;
        }
    },
    created() {
      this.loadShops();
    },
    beforeMount(){
        let vueInstance = this;
        if((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
        {
            vueInstance.isLoading = true;
        }

        if(window.preselectedShopCode !== undefined)
        {
            vueInstance.shopIdPreselected = window.preselectedShopCode;
        }
        else
        {
            vueInstance.shopIdPreselected = vueInstance.shopId;
            window.preselectedShopCode = vueInstance.shopId;
        }   
        
        if(this.jwt != undefined){
            window.jwt = this.jwt;
        }
        if (this.code != undefined) {
            window.ssoCode = this.code;
        }
    },
    async mounted() {
        this.showChoice = false;
        var vueInstance = this;
        var config = window.config;

        if((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null))
        {
            vueInstance.isLoading = true;
        }

        if(config.TABLE_CHECKOUT.enabled)
        {
            if (window.shopId != undefined && window.immediate)
            {
              let path = "/QRLanding?s=" + window.shopId + "&i=" + window.immediate;
              await vueInstance.$router.push({path: path});
            }
            else if (window.shopId != undefined && window.tableId != undefined)
            {
                if (vueInstance.jwt != undefined || vueInstance.code != undefined)
                {
                    let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId; 
                    if (vueInstance.jwt != undefined) {
                    path = path + "&id_token=" + vueInstance.jwt; 
                    } else if(vueInstance.code != undefined){
                    path = path + "&code=" + vueInstance.code; 
                    }
                    window.jwt = undefined;
                    window.ssoCode = undefined;
                    await vueInstance.$router.push({path: path});
                }
                else
                {
                    //nur mit shop und tableid senden
                    let path = "/QRLanding?s=" + window.shopId + "&t=" + window.tableId;
                    await vueInstance.$router.push({path: path});
                }
            }
            else
            {
                    let path = "/QRLanding?h=true"; 
                    await vueInstance.$router.push({path: path});
            }

            if(window.tableId == undefined || window.tableId == null){
                window.location.href = config.TABLE_CHECKOUT.homepage;
            }else{
                await vueInstance.$router.push({path: "/Menu"});
            }
            return; 
        }

        await this.checkJWT();

        vueInstance.shopIdPreselected = vueInstance.shopId;
        vueInstance.isLoading = true;
        setTimeout(async () => {
          if (window.screen.width > 700 && config.SHOW_GLOBAL_HEADER_BANNER_IMAGE == true) {
            $(".header").addClass("header-big");
            $(".content").addClass("header-big-content-margin");
            $("#content-wrapper").bind("wheel", function (event) {
              vueInstance.handleWheelContent(event);
            });
          } else {
            $(".content").addClass("header-small-content-margin");
          }

          var instance = this;

          instance.visible = true;
          window.promotionBarcode = "";

          const response = await this.doGet('TCPShopsJ.action', {
            json: "true", emptyBasket: true
          });

          vueInstance.isLoading = false;
          instance.isLoading = false;
          instance.shops = response.shops;

          if (response.emptyBasket) {
            window.shoppingCart = response.basketTile.basketItems;
            instance.updateShoppingCart();
          }

          if (Utils.isEmpty(instance.shops)) {
            instance.makeToast('danger', vueInstance.$t('notification.noShopsAvailable'));
            return;
          }

          if (instance.shops.length === 1 && config.FLAG_AUTOSELECTSHOP) {
            instance.selectShop(instance.shops[0].id);
          }

          instance.visible = false;

          if (vueInstance.shopIdPreselected > 0) {
            console.log("ShopCode was passed! auto-selecting.");
            await vueInstance.selectShopCode(vueInstance.shopIdPreselected);
          }

          instance.showChoice = true;

          instance.visible = false;
          window.shopObj = null;
          window.timeString = null;
        }, 5);
    },
    props: {
        callback: {
            Type: Function
        },
        jwt: {
            Type:String,
            Default: "",
        },
        shopId:{
            Type: Number,
            Default: 0
        },
        code: {
            Type:String,
            Default: "",
        },
        validationType: {
          Type:String,
          Default: "",
        },
    },
    methods: { 
        async getToken() {
          let vueInstance = this;

          const result = await vueInstance.doGet('TCPLoginJ.action', {
            json: "true"
          });

          if (result.xsrfToken != undefined) {
            window.xtoken = atob(result.xsrfToken);
          }
        },  
        async checkJWT() {
          var config = window.config;
          this.showCarousel = config.SHOW_SHOP_CAROUSEL;
          this.shopShopModalAddress = config.SHOW_SHOP_DETAILS_ADDRESS;
          this.showShopExtraDetails = config.SHOW_SHOP_EXTRA_DETAILS;
          window.shopSelected = false;
          let vueInstance = this;

          vueInstance.isLoading = true;
          vueInstance.jwtString = vueInstance.jwt;

          if ((vueInstance.jwtString != "" && vueInstance.jwtString != undefined && vueInstance.jwtString != null) || (vueInstance.code != "" && vueInstance.code != undefined && vueInstance.code != null)) {
            vueInstance.getToken();

            const result = await vueInstance.doPost('TCPLoginWithJWTJ.action', {
              jwt: !Utils.isEmpty(vueInstance.jwtString) ? vueInstance.jwtString : undefined,
              code: !Utils.isEmpty(vueInstance.code) ? vueInstance.code : undefined,
              shopCode: !Utils.isEmpty(vueInstance.shopId) ? vueInstance.shopId : undefined,
              validationType: !Utils.isEmpty(vueInstance.validationType) ? vueInstance.validationType : undefined,
              returnCustomerData: "true"
            });

            if (result.exception !== undefined || result.errorCode < 0) {
              setTimeout(() => {
                vueInstance.makeToast('danger', vueInstance.$t('notification.SSOLoginError'));
              }, 300);
              await vueInstance.$router.push({path: "/ErrorSSO"});
              return;
            }

            if (result.requiresLogin == true) {
              console.log("Routing to LOGIN");
              await vueInstance.$router.push({path: "/Login"});
              return;
            }

            if (result.customer == undefined) {
              console.log("JWT Login unsuccesful!");
              if (result.requiresLogin == false) {
                console.log("A user is already logged in, JWT ignored!");
                return;
              }
              if (config.FORCE_LOGIN_BEFORE_USE) {
                console.log("Routing to LOGIN");
                await vueInstance.$router.push({path: "/Login"});
                return;
              }
            }

            if (!Utils.isEmpty(vueInstance.code)) {
              window.preselectedShopCode = vueInstance.code;
            }

            vueInstance.isLoading = false;
            vueInstance.showChoice = true;
            vueInstance.customer = result.customer;
            window.customerLoggedIn = true;

            if (config.USE_EXTERNAL_LANGUAGE && window.config.COOKIES_ENABLED) {
              this.$cookies.set('locale', !Utils.isEmpty(result.customer.locale) ? result.customer.locale : config.LANGUAGE_LIST[0].value);
            }

            if (vueInstance.shopIdPreselected > 0) {
              console.log("ShopCode was passed! auto-selecting.");
              await vueInstance.selectShopCode(vueInstance.shopIdPreselected);
            }

            if (UserTypes.PARENT === result.customer.custom3) {
              console.log("Routing to StudentAccounts");
              window.isParent = true;
              await vueInstance.$router.push({path: '/StudentAccounts'});
            }
            else if (!Utils.isEmpty(vueInstance.shopId)) {
              console.log("Routing to Menu");
              await vueInstance.$router.push({path: "/Menu"});
            }
            else if (config.IS_PURE_ACCOUNTMANAGER) {
              console.log("Routing to Account");
              await vueInstance.$router.push({path: "/Account"});
            }
          } else {
            vueInstance.showChoice = true;

            vueInstance.isLoading = false;

            if (config.IS_PURE_ACCOUNTMANAGER) {
              console.log("Routing to Account");
              await vueInstance.$router.push({path: "/Account"});
            }

            if (!config.FORCE_LOGIN_BEFORE_USE) {
              return;
            }
            console.log("forceLogin");
            await vueInstance.forceLogin();
          }
        },
        async loadShops() {
          this.isLoading = true;
          let vueInstance = this;

          const response = await vueInstance.doGet('TCPShopsJ.action', { json: "true", emptyBasket: true });
          if (response.shops.empty) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.noShopsAvailable'));
            return;
          }
          if (response.emptyBasket) {
            window.shoppingCart = response.basketTile.basketItems;
            window.shoppingTile = response.basketTile;
            // vueInstance.updateShoppingCart();
          }

          vueInstance.shops = response.shops;

          if (vueInstance.shops.length === 1 && vueInstance.config.FLAG_AUTOSELECTSHOP) {
            vueInstance.selectShop(vueInstance.shops[0].id);
          }

          this.isLoading = false;
        },
        async forceLogin() {
          let vueInstance = this;

          const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
            json: "true",
          });

          if (result.requiresLogin == true) {
            console.log("Routing to LOGIN");
            await vueInstance.$router.push({path: "/Login"});
            return;
          }

          vueInstance.customer = result.customer;

          if (result.customer != undefined) {
            window.isGuestCheckout = result.customer.type != undefined && result.customer.type == 2 ? true : false;
          } else {
            // JP 20221005
            if (window.config.FORCE_LOGIN_BEFORE_USE == true) {
              console.log("Routing to LOGIN");
              await vueInstance.$router.push({path: "/Login"});
              return;
            }
          }
        },
        updateShoppingCart: async function () {
          let vueInstance = this;

          const result = await vueInstance.doPost('TCPConfirmOrderJ.action', {
            calculateOrder: window.customerLoggedIn ? true : false,
            orderType: window.customerLoggedIn ? window.orderType : undefined,
            promotionBarcode: window.promoBarcode != undefined ? window.promoBarcode : undefined
          });

          window.shoppingCart = result.basketItems;
          window.subsidy = result.basketTile.paymentsList != undefined ? vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount) : "";
          window.xtoken = result.xsrfToken != undefined ? atob(result.xsrfToken) : undefined;
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat(this.config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        maximizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").addClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").addClass("header-big-content-margin");
                $(".content").removeClass("header-small-content-margin");
            }
           
        },
        minimizeHeader() {
            var config = window.config;
            if(config.SHOW_GLOBAL_HEADER_BANNER_IMAGE)
            {
                $(".header").removeClass("header-big");
                $(".header").addClass("animTransition");
                $(".content").removeClass("header-big-content-margin");
                $(".content").addClass("header-small-content-margin");
            }       
        },
        handleWheelContent(event) {
            if (event.originalEvent !== undefined && Utils.isGreater(event.originalEvent.deltaY, 0))
            {
              this.minimizeHeader();
            }
        },
        async selectShop(shopId, shopCode) {
          let vueInstance = this;
          vueInstance.isLoading = true;
          let config = window.config;

          await vueInstance.doGet('TCPSelectShopJ.action', {
            id: shopId,
            json: "true"
          });

          vueInstance.isLoading = false;
          window.shopSelected = true;
          window.shop = shopId;
          var shopcopyid = shopId;
          vueInstance.selectedShop = vueInstance.shops.filter(shop => shop.id.toString() === shopcopyid.toString())[0];
          window.ageVerification = config.AGE_VERIFICATION_SHOPCODES.includes(shopCode) ? true : false;
          window.shopObj = vueInstance.selectedShop;

          vueInstance.$refs.shopModal.openModal();
        },
        async selectShopCode(shopCode) {
          var vueInstance = this;
          var config = window.config;
          vueInstance.isLoading = true;
          var shopcopycode = shopCode;
          var shop = vueInstance.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
          if (shop == undefined) {
            console.log("ShopCode " + shopCode + " not found! Shop could not be auto-selected!")
            return;
          }

          await vueInstance.doGet('TCPSelectShopJ.action', {
            id: shop.id,
            json: "true"
          });

          vueInstance.isLoading = false;
          console.log("Select Shop success: " + shopCode);
          vueInstance.selectedShop = vueInstance.shops.filter(shop => shop.custom2.toString() === shopcopycode)[0];
          window.shopSelected = true;
          window.shop = vueInstance.selectedShop.id;
          window.shopObj = vueInstance.selectedShop;
          vueInstance.$refs.shopModal.openModal()
          window.ageVerification = config.AGE_VERIFICATION_SHOPCODES.includes(shopCode) ? true : false;
        }
    },
    components: {
        'shop-card' : ShopCard,
        'shop-carousel' : Carousel,
        'loading': VueLoading,
        'modal' : Modal,
        'order-options-page':OrderOptionsPage
    },
    watch: {
      '$i18n.locale': function(newVal, oldVal) {
        if(newVal != oldVal)
        {
          this.loadShops();
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {
         // eslint-disable-next-line no-unused-vars
         next(vm => {          
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            
            if(myDiv != undefined)
              if(myDiv != null)
                myDiv.scrollTop = 0;                            
        })        
    },
}
</script>

<style scoped>
    .shopCardWrapper {
        margin-bottom:50px;
        display:flow-root;
    } 
@media screen and (max-width:770px) {
    .shopCardWrapper {
        display:flow-root;
    }
}

</style>