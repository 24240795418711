import moment from "moment";
import {Currencies} from "@/constants/constants";

export default class Utils {
    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static formatCurrency(amount, currency, withSymbol = true, separator = '.') {

        // Ensure the amount is a number with two decimal places
        const formattedAmount = parseFloat(amount).toFixed(2).replace('.', separator);

        if (!withSymbol) {
            return formattedAmount;
        }

        // Handle formatting based on currency
        switch (currency) {
            case Currencies.EUR:
                return `${formattedAmount} €`;  // EUR after amount
            case Currencies.CHF:
                return `CHF ${formattedAmount}`; // CHF before amount
            default:
                return `${formattedAmount} ${currency}`; // Default to currency after amount
        }
    }

    static getCurrencySymbol(currency) {
        // Handle formatting based on currency

        const formattedCurrency = (currency || '').trim().toUpperCase();

        switch (formattedCurrency) {
            case Currencies.EUR:
                return `€`;  // EUR after amount
            case Currencies.CHF:
                return `CHF`; // CHF before amount
            default:
                return `CHF`; // Default to currency after amount
        }
    }

    static adjustInnerScrollHeight() {
        const innerScroll = document.querySelector('.innerScroll');
        const siteHeader = document.getElementById('siteHeader');
        const subPageHeader = document.querySelector('.subPageHeader');
        const footer = document.querySelector('.footer');

        const siteHeaderHeight = siteHeader ? siteHeader.offsetHeight : 0;
        const subPageHeaderHeight = subPageHeader ? subPageHeader.offsetHeight : 0;
        const footerHeight = footer ? footer.offsetHeight : 0;

        const availableHeight = window.innerHeight - siteHeaderHeight - subPageHeaderHeight - footerHeight;

        if (innerScroll) {
            innerScroll.style.setProperty('height', `${availableHeight}px`, 'important');
        }
    }

    static getCurrencySeparator(currency) {
        // Handle formatting based on currency
        switch (currency) {
            case Currencies.EUR:
                return `,`;  // EUR after amount
            case Currencies.CHF:
                return `.`; // CHF before amount
            default:
                return `.`; // Default to currency after amount
        }
    }

    static getDecimalSeparator(formattedAmount = "0.00") {
        let lastDigitIndex = Utils.findDigitIndex(formattedAmount, formattedAmount.length - 1);

        if(lastDigitIndex >= 0)
        {
            let indexSeparator = Utils.findNoNDigitIndex(formattedAmount, lastDigitIndex);

            if(indexSeparator >= 0)
            {
                return formattedAmount.charAt(indexSeparator);
            }
        }

        //Default separator
        return '.'
    }

    static findDigitIndex(str, startIndex) {

        for (let i = startIndex; i >= 0; i--) {

            if (!isNaN(str[i]) && str[i] !== ' ') {
                return i;
            }
        }

        return -1;
    }

    static findNoNDigitIndex(str, startIndex) {

        for (let i = startIndex; i >= 0; i--) {

            if (isNaN(str[i])) {
                return i;
            }
        }

        return -1;
    }

    static getCurrentDate() {
        const date = new Date();
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }

    static parseTimeHHMMSS(timeStr) {
        let [hours, minutes, seconds] = timeStr.split(':');
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        return date;
    }

    static isGreater(valueToCompare, value) {
        return valueToCompare != undefined && valueToCompare > value;
    }

    static isEmpty(value) {
        // Check for null or undefined
        if (value == null || value == undefined) return true;

        // Check for empty string
        if (typeof value === 'string' && value.trim() === '') return true;

        // Check for empty array
        if (Array.isArray(value) && value.length === 0) return true;

        // Check for empty object
        if (typeof value === 'object' && Object.keys(value).length === 0) return true;

        return false;
    }

    static getStartOfMonth(date) {
        return moment(date).startOf('month').format('YYYY-MM-DD');
    }

    static getEndOfMonth(date) {
        return moment(date).endOf('month').format('YYYY-MM-DD');
    }

    static getStartOfNextMonth() {
        return moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    }

    static getEndOfNextMonth() {
        return moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
    }

    static getStartOfPreviousMonth() {
        return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    }

    static getEndOfPreviousMonth() {
        return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    }

    static getStartOfYear(date) {
        return moment(date).startOf('year').format('YYYY-MM-DD');
    }

    static getEndOfYear(date) {
        return moment(date).endOf('year').format('YYYY-MM-DD');
    }
    
    static getStartOfPreviousYear() {
        return moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    }

    static getEndOfPreviousYear() {
        return moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
    }

    static getStartOfNextYear() {
        return moment().add(1, 'years').startOf('year').format('YYYY-MM-DD');
    }

    static getEndOfNextYear() {
        return moment().add(1, 'years').endOf('year').format('YYYY-MM-DD');
    }

    static formatDate(date, locale) {
        if (!date) return '';
        return new Intl.DateTimeFormat(locale).format(new Date(date));
    }
}
