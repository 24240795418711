<template>
    <div class="passwordResetPageWrapper">
      <div class="col-12 subPageHeader" style="position:relative;">
        <div class="row">
          <h1>{{ $t('title.passwordReset') }}</h1>

          <div class="ml-auto row" style="padding-right: 7px;">
            <b-button ref="backButton" class="btn btn-primary  btn-justify btn-right" @click="goBack(false)">
              {{$t('button.back')}}
            </b-button>
          </div>
        </div>
      </div>

      <div class="innerScroll">
      <b-container fluid>
        <b-form @submit.stop.prevent>
          <b-form-row>
            <b-col cols="12" md="12">
              <b-form-group class="form-group fontField" label-for="passwordOld">
                <template v-slot:label>
                  {{ $t('field.passwordOld') }} <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <b-form-input v-if="hidePasswordOld" class="form-control form-control-lg" id="passwordOld" name="passwordOld" type="password" v-model="$v.form.passwordOld.$model"
                                :state="validateState('passwordOld')"
                                aria-describedby="passwordOld-feedback" />
                  <b-form-input v-else class="form-control form-control-lg" id="passwordOld" name="passwordOld" type="text" v-model="$v.form.passwordOld.$model"
                                :state="validateState('passwordOld')"
                                aria-describedby="passwordOld-feedback" />
                  <b-input-group-append>
                    <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" class="btn-corner-right" type="button" @click="showPasswordOld()" :aria-label="hidePasswordOld ? $t('ariaLabel.showPasswordNew') : $t('ariaLabel.hidePasswordNew')">
                      <b-icon-eye-fill v-if="hidePasswordOld" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                      <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback class="text-left" id="passwordOld-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" md="12">
              <b-form-group class="form-group fontField" label-for="passwordNew">
                <template v-slot:label>
                  {{ $t('field.passwordNew') }} <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <b-form-input v-if="hidePasswordNew" class="form-control form-control-lg" id="passwordNew" name="passwordNew" type="password" v-model="$v.form.passwordNew.$model"
                                :state="validateState('passwordNew')"
                                aria-describedby="passwordNew-feedback" />
                  <b-form-input v-else class="form-control form-control-lg" id="passwordNew" name="passwordNew" type="text" v-model="$v.form.passwordNew.$model"
                                :state="validateState('passwordNew')"
                                aria-describedby="passwordNew-feedback" />
                  <b-input-group-append>
                    <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" class="btn-corner-right" type="button" @click="showPasswordNew()" :aria-label="hidePasswordNew ? $t('ariaLabel.showPasswordRepeat') : $t('ariaLabel.hidePasswordRepeat')">
                      <b-icon-eye-fill v-if="hidePasswordNew" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordRepeat')"></b-icon-eye-fill>
                      <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordRepeat')"></b-icon-eye-slash-fill>
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback class="text-left" v-if="form.passwordNew && !$v.form.passwordNew.valid" id="passwordNew-feedback">{{ $t('validation.passwordPolicy') }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col cols="12" md="12">
              <b-form-group class="form-group fontField" label-for="passwordNew">
                <template v-slot:label>
                  {{ $t('field.passwordNewRepeat') }} <span class="text-danger">*</span>
                </template>
                <b-input-group>
                  <b-form-input v-if="hidePasswordNewRepeat" class="form-control form-control-lg" id="passwordNewRepeat" name="passwordNewRepeat" type="password" v-model="$v.form.passwordNewRepeat.$model"
                                :state="validateState('passwordNewRepeat')"
                                aria-describedby="passwordNewRepeat-feedback" />
                  <b-form-input v-else class="form-control form-control-lg" id="passwordNewRepeat" name="passwordNewRepeat" type="text" v-model="$v.form.passwordNewRepeat.$model"
                                :state="validateState('passwordNewRepeat')"
                                aria-describedby="passwordNewRepeat-feedback" />
                  <b-input-group-append>
                    <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" class="btn-corner-right" type="button" @click="showPasswordNewRepeat()" :aria-label="hidePasswordNewRepeat ? $t('ariaLabel.showPasswordRepeat') : $t('ariaLabel.hidePasswordRepeat')">
                      <b-icon-eye-fill v-if="hidePasswordNewRepeat" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordRepeat')"></b-icon-eye-fill>
                      <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordRepeat')"></b-icon-eye-slash-fill>
                    </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback class="text-left" id="passwordNewRepeat-feedback">{{ $t('validation.fieldWarningPasswordEqual') }}</b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
          <div style="padding-left: 10px; width: 100%;">
            <b-button type="submit" @click="onSubmit" :disabled="$v.$invalid" style="margin-top: 43px;" class="btn btn-primary btn-lg btn-block btnSubmit">{{$t('button.submit')}}</b-button>
          </div>
          </b-form-row>
        </b-form>
      </b-container>
      </div>
    </div>
</template>
<script>
import { required, requiredIf, sameAs} from 'vuelidate/lib/validators'
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-vue'
import Swal from "sweetalert2";
import Utils from "@/utils/utils";

export default {
  mounted() {
    const mainElement = document.getElementById('app');
    if (mainElement) {
      mainElement.focus();
    }

    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  beforeDestroy() {
    window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  data() {
    return {
      form: {
        passwordOld: undefined,
        passwordNew: undefined,
        passwordNewRepeat: undefined,
      },
      shake: false,
      good: "",
      hidePasswordOld: true,
      hidePasswordNew: true,
      hidePasswordNewRepeat: true,
    }
  },
  computed: {
    config() {
      return window.config;
    },
  },
  components: {
    BIconEyeFill,
    BIconEyeSlashFill,
  },
  validations: {
    form: {
      passwordOld: {
        required,
      },
      passwordNew: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.password.required),
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          const hasMinLength = value.length >= window.config.CUSTOMER_DATA.FIELDS.password.minLength;
          const hasMaxLength = value.length <= window.config.CUSTOMER_DATA.FIELDS.password.maxLength;
          return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial &&
              hasMinLength &&
              hasMaxLength
          );
        },
      },
      passwordNewRepeat: {
        required,
        sameAsPassword: sameAs('passwordNew')
      }
    },
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showPasswordOld() {
      this.hidePasswordOld = !this.hidePasswordOld;
    },
    showPasswordNew() {
      this.hidePasswordNew = !this.hidePasswordNew;
    },
    showPasswordNewRepeat() {
      this.hidePasswordNewRepeat = !this.hidePasswordNewRepeat;
    },
    goBack() {
      this.$router.go(-1);
    },
    async onSubmit() {
      let vueInstance = this;
      this.$v.$touch();

      if (vueInstance.$v.$invalid) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.fillAllRequiredFields'));
        return;
      }

      const result = await vueInstance.doPost('TCPChangePasswordSubmitJ.action', {
        json: "true",
        currentPassword: this.form.passwordOld,
        password: this.form.passwordNew,
        passwordConfirmation: this.form.passwordNewRepeat,
        xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
      });

      if (result.errorCode < 0) {
        vueInstance.makeToast('danger', result.errorMsg);
        return;
      }

      if (result.exception != undefined) {
        vueInstance.makeToast('danger', vueInstance.$t('notification.passwordPolicy'));
        return;
      }

      vueInstance.makeToast('success', vueInstance.$t('notification.passwordResetSuccessful'));

      vueInstance.$router.push({path: '/Account'});
      this.shake = false;
    }
  },
  async created() {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }
  },
  beforeRouteLeave(to, from, next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
    }

    Swal.close();
    next();
  }
}

</script>

<style scoped>

input[type="password"]::-ms-reveal {
  display: none;
}

#content-wrapper
{
  overflow-y: hidden;
  padding-top: 0px;
}

.btn-corner-right{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>