<template>
  <div class="editCustomerPageWrapper" style="padding-left: 15px; padding-right: 15px;">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="col-12 subPageHeader" style="position:relative;">
      <div class="row">
        <h1>{{  $t('title.orderHistory')}}</h1>
        <div class="ml-auto row" style="padding-right: 7px;">
          <b-button class="btn btn-primary  btn-justify btn-right" @click="goBack(false)">
            {{$t('button.back')}}
          </b-button>
        </div>
      </div>
    </div>

    <div class="innerScroll">
        <date-search-table :useFutureFilters="true" :callback="retrieveOrders" style="padding-left: 0; padding-right: 0;"  />
        <b-container fluid>
        <b-row class="d-none d-md-flex">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('text.transDate') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.status') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentOrders)" class="va-middle">
              <b-tr v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.shopDescription }}</b-td>
                <b-td class="text-left">{{ item.status }}</b-td>
                <b-td class="text-right">{{ item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openOrderDetails(item.guid, item.shopId)">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="5">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentOrders)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="3"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary"><b>{{ ordersFormattedTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
        <b-row class="d-md-none">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.date') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.status') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentOrders)" class="va-middle">
              <b-tr v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.status }}</b-td>
                <b-td class="text-right">{{ item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openOrderDetails(item.guid, item.shopId)">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
                <b-td/>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="5">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentOrders)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="2"><b>{{ ordersFormattedTotal }}</b></b-td>
                <b-td variant="secondary" colspan="3"></b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
      </b-container>
    </div>

    <modal ref="orderDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.order') }}</h2>
      </template>

      <template v-slot:body>
        <order-details :order="currentOrderSelected"></order-details>
        <div class="align-items-center justify-content-between">
          <b-button class="btn btn-primary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        </div>
      </template>
    </modal>
    <!--<modal ref="orderDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.order') }}</h2>
      </template>

      <template v-slot:body>
        <order-details :order="currentOrderSelected" :callback-close="closeOrderDetails"></order-details>
        <b-button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-secondary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        <b-button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-danger" @click="cancelOrder()">{{ $t("button.cancel") }}
        </b-button>
      </template>
    </modal>-->
  </div>
</template>

<script>

import OrderDetails from '../sub-components/OrderDetails.vue'
import Modal from "../sub-components/ModalAccessible.vue";
import Utils from "@/utils/utils";
import DateSearchTable from "@/components/main-components/DateSearchTable.vue";
import Swal from "sweetalert2";
import VueLoading from "vue-loading-overlay";

export default {
  data() {
    return {
      isLoading: false,
      recentOrders: {
        Type: Array,
        default: []
      },
      currentOrderSelected: {
        Type: Object
      },
      ordersFormattedTotal: 0.00,
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    }
  },
  components: {
    'loading': VueLoading,
    DateSearchTable,
    'modal': Modal,
    'order-details': OrderDetails,
  },
  methods: {
    async retrieveOrders(dateFrom, dateTo) {
      let vueInstance = this;
      vueInstance.isLoading = true;

      const result = await vueInstance.doGet('TCPRetrieveOrdersJ.action', {
        dateFrom: dateFrom,
        dateTo: dateTo,
      });

      vueInstance.isLoading = false;

      if (result.errorCode < 0) {
        vueInstance.makeToast('danger', result.errorMsg);
        return;
      }

      vueInstance.recentOrders = result.orders;
      vueInstance.ordersFormattedTotal = result.ordersFormattedTotal;
    },
    async openOrderDetails(guid, shopId) {
      let vueInstance = this;

      const result = await vueInstance.doGet('TCPOrderDetailsJ.action', {
        guid: guid,
        json: "true",
        shopId: shopId
      });

      vueInstance.currentOrderSelected = result.order;
      vueInstance.currentOrderSelected.guid = result.guid;
      vueInstance.$refs.orderDetailsModal.openModal();
    },
    cancelOrder(){
      let vueInstance = this;
      vueInstance.$confirm(this.$t("stornoOrder")).then(() => {
        vueInstance.makeToast('success', this.$t("notification.orderCanceled"));
        vueInstance.$refs.transactionDetailsModal.closeModal();
      });
    },
    closeOrderDetails() {
      this.$refs.orderDetailsModal.closeModal();
      this.currentOrderSelected = null;
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }

    await this.retrieveOrders(Utils.getStartOfMonth(), Utils.getEndOfMonth());
  },
  mounted() {
    const mainElement = document.getElementById('app');
    if (mainElement) {
      mainElement.focus();
    }

    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  beforeDestroy() {
    window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  beforeRouteLeave (to,from,next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
    }

    Swal.close();
    next();
  }
}
</script>

<style scoped>
.innerScroll{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 280px);
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .innerScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 340px);
    width: 100%;
  }
}

.fas {
  margin-right: 5px;
}

#content-wrapper
{
  overflow-y: hidden;
}

.va-middle td{
  vertical-align: middle;
}

</style>