<template>
    <div class="menuWrapper col-12" style="padding-left: 0; padding-right: 0px">

        <modal ref="articleDetailsModal">
            <template v-slot:header>
                <h3 :class="!selectedArticleIsOnStock && articleOutOfStock">{{articleWebDescription}}</h3>
            </template>

            <template v-slot:body>
                <div class="articlePageWrapper" v-if="articleSelected">
                    <article-page :callbackBack="closeArticlePage" :key="articlePageKey" :isFarLeft="isArticleFirst()" :isFarRight="isArticleLast()" :isOnStock="selectedArticleIsOnStock" :article="selectedArticle" :callbackPrevious="previousArticle" :callbackNext="nextArticle"></article-page>    
                </div>
            </template>

            <template v-slot:footer>
            </template>
        </modal>

        <loading :active.sync="isLoading" 
            :can-cancel="true"
            :is-full-page="true"
            loader='bars'></loading>

        <!-- <div class="col-12" style="padding:0px; border-bottom:0.5px solid var(--main-color);position: sticky; top:0;">             -->
      <div class="col-12 mainSearchBar">

            <div style="padding-left: 35px; padding-top: 7px">
            <div v-show="config.SHOW_PRODUCT_SEARCH_BAR" class="searchContainer col-lg-3 col-md-3 col-sm-12" style="float:right; padding:1px; border:1px solid var(--body-color); margin:1px 18px 5px 1px;">
                <input type="text" id="searchField" style="margin-bottom:1px;width:65%;" v-model="searchPhrase" :placeholder="$t('field.search')">
                <button v-if="searchPhrase != ''" type="submit" @click="clearSearch()"  style="margin-left:0px;widht: 10px" class="btn btnSearchField">
                    <i class="fa fa-times floatRight"></i>
                </button>
                <button type="submit" @click="doSearch()" style="margin-left:6px;" class="btn btnSearchField"><i class="fa fa-search floatRight"></i></button>
            </div>
            </div>

            <div class="col-1 categoryScrollIcon mainCategoryScrollIcon" @click="scrollCategories(-1)" style="padding-left: 15px;">
                <i class="fa fa-chevron-left"></i>
            </div>

            <div  id="menuCategories" class="col-lg-8 col-md-8 col-sm-12" ><!--  style="padding:5px 5px 2px 5px;" -->
                <div v-for="category in filterCategories(categories)" v-bind:key="category.id">
                    <a :class="(currentCategory.id == category.id) && activeGroupClass" class="category" style="color:gray;"  @click="showCat(category.id)" >
                            {{ category.name }}                    
                    </a>
                </div> 
            </div>
            
            <div class="col-1 categoryScrollIcon mainCategoryScrollIcon" style="float:right" @click="scrollCategories(1)">
                <i class="fa fa-chevron-right"></i>
            </div>
        </div>
      <div class="productsAreaPositioner">
            <div class="col-12" id="menuSubCategoriesWrapper" style="padding: 10px 15px 0px 15px">
              <div style="border-bottom:0.5px solid var(--main-color);">
                <div class="col-1 categoryScrollIcon subCategoryScrollIcon" @click="scrollSubCategories(-1)">
                    <i class="fa fa-chevron-left"></i>
                </div>

                <div class="col-1 categoryScrollIcon subCategoryScrollIcon" style="float:right" @click="scrollSubCategories(1)">
                    <i class="fa fa-chevron-right"></i>
                </div>   

                <div id="menuSubCategories">   
                    <div v-for="category in filterCategories(currentCategory.children)" v-bind:key="category.id">
                        <a :class="(currentSubCategory.id == category.id) && activeGroupClass" class="category" style="color:gray;"  @click="showSubCat(category.id)" >
                            {{ category.name }}
                        </a>
                    </div>
                </div>
              </div>
            </div>

            <div class="col-12" id="menuSubSubCategoriesWrapper" style="padding:0px;padding-top:10px;border-bottom:0.5px solid var(--main-color);">
                <div class="col-1 categoryScrollIcon subCategoryScrollIcon subSubCategoryScrollIcon" @click="scrollSubSubCategories(-1)">
                    <i class="fa fa-chevron-left"></i>
                </div>

                <div id="menuSubSubCategories">          
                    <div v-for="category in filterCategories(currentSubCategory.children)" v-bind:key="category.id">
                        <a :class="(currentSubSubCategory.id == category.id) && activeGroupClass" class="category" style="color:gray;"  @click="showSubSubCat(category.id)" >
                            {{ category.name }}                    
                        </a>
                    </div>

                    <div class="col-1 categoryScrollIcon subCategoryScrollIcon subSubCategoryScrollIcon" style="float:right" @click="scrollSubSubCategories(1)">
                        <i class="fa fa-chevron-right"></i>
                    </div>
                </div>        
            </div>

            <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12" style="padding-left: 13px; padding-right: 13px;">
                <h3 class="groupTitle">{{getCurrentGroupTitle()}}</h3>
            
                <div id="menuProducts"  class="articleGroupBody" style="" v-if="productsLoaded">            
                    <article-card v-for="item in productArray" v-bind:key="item.id" :cartQuantity="checkItemCart(item.id)" :article="item" :callbackAdd="selectArticle" :callbackAddWeight="selectWeightArticle" :callbackRemove="removeArticle" :callbackSelected="articlePage"></article-card>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12" style="padding-left: 0;padding-right: 8px;">
                <div id="menuCart">              
                    <div class="col-12 shoppingCartSection">
                        <div class="shoppingCartSectionTitle"> {{ $t('subTitle.yourOrder') }} </div>
                        <span v-if="basketItems.length == 0"> {{ $t('text.shoppingCartEmpty') }}</span>

                        <div id="menuCartElement" class="menuCartElement" v-for="item in basketItems" v-bind:key="item.id">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:0;">
                                <div class="col-12" style="padding:0; padding-left:5px; text-align:left;font-size:14px;" v-html="trimDescriptionShort((item.prodText !== undefined) ? item.prodText :item.text)">
                                    
                                </div>
                                <div class="col-12 mainColor" style="padding:0; padding-left:5px; text-align:center; text-weight:bold;">
                                    {{ item.formattedDiscountedPrice != undefined ? item.formattedDiscountedPrice : item.formattedTotalPrice}} {{item.weightOrVolume != undefined ? '/kg' : ''}}
                                </div>
                            </div>
                            
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="padding:5px 5px 0px 0px">                                
                                <div class="col-4" style="padding:0;">
                                    <div style="padding:0;" @click="removeItemQuantity(item.id, item.quantity)">
                                        <svg class="minusIcon" viewBox="0 0 100 100">
                                            <circle cx="50" cy="50" r="45" fill="none" stroke-width="5.5"></circle>
                                            <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
                                        </svg>
                                    </div>
                                </div>

                                <div class="col-4 mainColor" style="padding:0;">
                                    <!-- TODO: NEW FIELD! -->
                                    <input v-if="config.SHOW_BASKET_QUANTITY_INPUT" class="qtyInput" @change="changeItemQuantity($event,item.prodId)" type="number" :value="item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity"/>
                                    <div v-if="!config.SHOW_BASKET_QUANTITY_INPUT"> {{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}}</div>
                                </div>
                                
                                <div class="col-4" style="padding:0;">
                                    <div :class="(item.weightOrVolume != undefined) && quantityButtonDisabled" @click="(item.weightOrVolume != undefined) ? weightAlert() : addItemQuantity(item.id, item.quantity)">
                                      <svg class="addIcon" viewBox="0 0 100 100">
                                            <circle cx="50" cy="50" r="45" fill="none" stroke-width="5.5"></circle>
                                            <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
                                            <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7"></line>
                                        </svg>
                                    </div>
                                </div>
                            </div>                
                        </div>

                        <div class="col-12 d-flex flex-wrap" v-if="subsidy != undefined && !(subsidy === '')">
                            <div class="my-auto mx-sm-auto" style="font-size:14px;">{{$t('text.subsidyShortDescription')}}</div>
                            <div class="my-auto ml-auto" style="font-size:18px; padding:2px;">{{subsidy}} </div>
                        </div>

                        <div class="col-12 d-flex flex-wrap">
                            <div class="my-auto mx-sm-auto" style="font-size:18px;">{{$t('sum')}}:</div>
                            <div class="my-auto ml-auto" style="font-size:25px; padding:2px;">{{basketTotal}} </div>
                        </div>

                        <b-button class="btn btn-primary btn-secondary floatRight" style="width:100%;text-align:start;" @click="checkout()" v-if="basketItems.length != 0"> {{ $t('button.proceed') }}</b-button>
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
import ArticleCard from '../main-components/ArticleCard.vue'
import Modal from '../sub-components/Modal.vue'
import VueLoading from 'vue-loading-overlay'
import ArticlePage from '../page-components/ArticlePage.vue'
import $ from 'jquery'
import Utils from "@/utils/utils";

export default {
    computed: {
        config(){
            return window.config;
        }
    },
    data() {
        return {
            swiper:{
                Type:Object
            },
            articlePageKey: 0, 
            delivery_fee_article_prod_id: 0,
            categories: {
                Type:Array,
                default: []
            },
            offset: 0,
            articleWebDescription: "",
            limit: 20,
            searchPhrase:"",
            whereToSearch: 0,
            productsLoaded: false,        
            currentCategory: {
                Type: Object,
                default: {
                    id : 0  ,
                    children: []                  
                }
            },
            articleOutOfStock:"articleCardDisabled",
            currentSubCategory: {
                Type: Object,
                default: {
                    id : 0  ,
                    children: []                  
                }
            },
            currentSubSubCategory: {
                Type: Object,
                default: {
                    id : 0  ,
                    children: []                  
                }
            },
            productArray:{
                Type: Array,
                default: [
                    {
                        id: 0
                    }
                ]
            },
            basketItems: {
                Type:Array,
                default: []
            },
            basketTotal: "",
            selectedArticle:{
                Type: Object
            },
            selectedArticleIsOnStock:{
                Type:Boolean
            },
            articleSelected: false,
            quantityButtonDisabled: 'qtyBtnDisabled',  
            activeGroupClass : "activeCategory",
            currentlyLoadingProducts: false,


            //OLD TCPMenuJ
            MenuGroups: {
                Type:Array,
                default: [ ]
            },
            ArticleGroups: {
                Type:Array,
                default: [ ]
            },
            groups: [],
            currentGroupHtml:"",
            isLoading: false,
            loadingFinished:false,
            currentGroup: {
                Type: Object,
                default: {
                    id : 0,
                    name: "",
                    items : [],
                    image1 : ""
                }
            },
            subsidy: "",
            dietaryInfo: []

        }        
    },
    components: {
        'article-card': ArticleCard,
        'loading': VueLoading,
        'article-page': ArticlePage,
        'modal': Modal,        
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if(newVal != oldVal)
            {
                this.loadCategories();
            }
        }
    },
    async created() {
      this.isLoading = true;

      if (window.dietaryInfo != undefined) {
        this.dietaryInfo = window.dietaryInfo;
      }

      if (window.menus != undefined || window.articles != undefined) {
        var menuGroups = window.menus;
        var articleGroups = window.articles;
        this.MenuGroups = menuGroups;
        this.ArticleGroups = articleGroups;
        this.groups = window.articles;
        this.isLoading = false;
        this.loadingFinished = true;

        if (window.gc != undefined && window.gc > 0) {
          this.showGroup(window.gc);
        } else {
          this.showGroup(this.groups[0]);
        }

        return;
      }

      var vueInstance = this;

      const result = await vueInstance.doGet('TCPCategoryTreeJ.action', {
        json: 'true',
        orderType: window.orderType,
      });

      vueInstance.categories = result.categories;
      if (vueInstance.categories != undefined && vueInstance.categories.length > 0) {
        vueInstance.showCat(vueInstance.filterCategories(vueInstance.categories)[0].id);
      }
      vueInstance.isLoading = false;
      vueInstance.loadingFinished = true;
    },
    methods:{ 
        async loadCategories() {
          this.isLoading = true;
          let vueInstance = this;

          const result = await vueInstance.doGet('TCPCategoryTreeJ.action', {
            json: 'true',
            orderType: window.orderType,
          });

          vueInstance.categories = result.categories;
          if (vueInstance.currentCategory.id > 0) {
            if (vueInstance.currentSubCategory.id > 0) {
              vueInstance.showSubCat(vueInstance.currentSubCategory.id);
            } else {
              vueInstance.showCat(vueInstance.currentCategory.id);
            }
          } else if (vueInstance.categories.length > 0) {
            vueInstance.showCat(vueInstance.filterCategories(vueInstance.categories)[0].id);
          }
          vueInstance.isLoading = false;
          vueInstance.loadingFinished = true;
        },
        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        async removeItemQuantity(cartId, quantity) {
          let value = quantity - 1;
          let vueInstance = this;

          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: cartId,
            quantity: value,
            orderType: window.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          vueInstance.isLoading = false;
          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal;

          if (result.basketTile.paymentsList != undefined) {
            vueInstance.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
            window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
          } else {
            vueInstance.subsidy = "";
            window.subsidy = ""
          }
          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.increaseItemQuantityMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.increaseItemQuantityMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.addItemFailed'));
            return;
          }
        },
        async addItemQuantity(cartId, quantity) {
          var value = quantity + 1;
          var vueInstance = this;

          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: cartId,
            quantity: value,
            orderType: window.orderType,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          vueInstance.isLoading = false;
          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal
          if (result.basketTile.paymentsList != undefined) {
            vueInstance.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
            window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
          } else {
            vueInstance.subsidy = "";
            window.subsidy = "";
          }
          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.increaseItemQuantityMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.increaseItemQuantityMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.addItemFailed'));
            return;
          }
        },
        async changeItemQuantity(element, articleId) {
          var value = element.srcElement.value;
          if (!this.isNumeric(value)) {
            this.makeToast('danger', this.$t('notification.inputQuantityNotValid'));
            element.srcElement.value = "1";
            return;
          }

          var vueInstance = this;
          var cartId = 0;
          vueInstance.isLoading = true;

          if (vueInstance.basketItems != undefined) {
            for (var i = 0; i < vueInstance.basketItems.length; i++) {
              if (vueInstance.basketItems[i].prodId == articleId) {
                cartId = vueInstance.basketItems[i].id;
              }
            }
          }

          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: cartId,
            quantity: value,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (value == 0) {
            vueInstance.deleteItem(articleId, element);
            return;
          }

          vueInstance.isLoading = false;
          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal;
          if (result.basketTile.paymentsList != undefined) {
            vueInstance.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
            window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
          } else {
            vueInstance.subsidy = "";
            window.subsidy = "";
          }
          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.changeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.changeItemMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.changeItemQtyFailed'));
            return;
          }
        },  
        weightAlert(){
            this.makeToast('danger', this.$t('notification.weightArticleQuantityChange'))
        },
        getCurrentGroupTitle(){
            if(this.searchPhrase != "")
            {
                return this.$t('text.searchResults') + " '" + this.searchPhrase + "'";
            }

            if(this.currentSubSubCategory.id > 0)
            {
                return this.currentSubSubCategory.name;
            }

            if(this.currentSubCategory.id > 0)
            {
                return this.currentSubCategory.name;
            }

            if(this.currentCategory.id > 0)
            {
                return this.currentCategory.name;
            }
        },
        closeArticlePage(total, refreshNeeded = false){           
            var vueInstance = this;
            if(total != "")
            {
                vueInstance.basketTotal = total;
            }
            var config = window.config;

            if (window.subsidy != undefined) {
                this.subsidy = window.subsidy;    
            }else{
                this.subsidy = "";
            }

            setTimeout(() => {
                vueInstance.$refs.articleDetailsModal.closeModal();
                vueInstance.basketItems = window.shoppingCart;
                vueInstance.$forceUpdate();
                    //if done too soon categories are not yet updated and currentCategory.id is 0 - hence why the timeout is here
                    setTimeout(() => { 
                         if(refreshNeeded && config.FORCE_SHOW_ARTICLE_DETAILS_ON_ADD)
                            console.log("refresh needed");
                            //vueInstance.reloadProducts();
                    }, 50);    
               
            }, 50);        
        },
        filterCategories(categories){
            if(Array.isArray(categories))
            {
                var filtered = categories.filter(function(x) { return x.numberOfProducts >= 0 })
                    .sort((a, b) => ((a.sortOrder > b.sortOrder) && (a.sortOrder > 0 && b.sortOrder > 0)) ? 1 : -1)
                    .sort((a, b) => (parseInt(a.custom3) > parseInt(b.custom3) ) ? 1 : -1);
                
                return filtered;
            }
            else
            {                
                return categories;
            }
                
        },
        async loadMoreProducts() {
          var config = window.config;
          var vueInstance = this;
          this.currentlyLoadingProducts = true;
          vueInstance.offset += vueInstance.limit;

          const result = await vueInstance.doPost('TCPProductSearchJ.action', {
            dataDescriptor: {
              limit: vueInstance.limit,
              offset: vueInstance.offset,
              orderBy: config.ORDER_PRODUCTS_BY,
              priceTypeRequired: vueInstance.getOrderTypeShort(),
              fillCustomAttrArray: "false",
              fillDescription: "true",
              fillMiscItems: "false",
              fillProductOptions: "false",
              fillProductQuantities: "false",
            },
            json: 'true',
            orderType: window.orderType,
            dietaryInfo: config.USE_ALLERGEN_FILTERS && vueInstance.dietaryInfo.length > 0 ? vueInstance.dietaryInfo : undefined,
            productSearch: {
              returnCategoryFacets: "true",
              returnManufacturerFacets: "true",
              priceTypeRequired: vueInstance.getOrderTypeShort(),
              searchText: this.searchPhrase != "" ? vueInstance.searchPhrase : undefined,
              whereToSearch: vueInstance.whereToSearch,
              categoryId: this.currentSubSubCategory.id > 0 ? this.currentSubSubCategory.id : (this.currentSubCategory.id > 0 ? this.currentSubCategory.id : this.currentCategory.id)
            },
          });

          vueInstance.productArray.push(...result.products.productArray);
          vueInstance.currentlyLoadingProducts = false;
          window.imageBaseUrl = result.imageBaseURL;
        },
        clearSearch(){
            this.searchPhrase = "";
            this.doSearch();
        },
        doSearch()
        {
            this.offset = 0;
            if(this.searchPhrase == "")
                this.whereToSearch = 0;
            else
                this.whereToSearch = -99;

            console.log("searching for: " + this.searchPhrase);
            this.getAndFillProductsFromCategory(this.currentCategory);  
        
        },
        checkout(){
            var vueInstance = this;
            vueInstance.$router.push({ path: '/OrderConfirmation' })
        },
   
         filterSubItems(){
            if(this.currentGroup.items == undefined)
            {
                return [];
            }

            var filteredItems = [];
            if(window.orderType != undefined)
            {      
                var tagToCheck = "";
                switch(window.orderType)
                {
                    case 'delivery':
                        tagToCheck="DE";
                        break;
                    case 'takeaway':
                        tagToCheck="TA";
                        break;
                    case '':
                        break;
                    default:
                        break;
                }

                for(var i= 0; i < this.currentGroup.items.length; i++)
                {
                    var subItem = this.currentGroup.items[i];     
                    var subItemPrices = subItem.prices;
                    var prices = subItemPrices.filter(function(x) { return x.type == tagToCheck });
                    if(prices.length > 0)
                    {
                        filteredItems.push(subItem);
                    }
                }
            
            }  
            return filteredItems;      
        },  
        previousArticle(article){    
            console.log("previous article");     
            for(var i = 1; i < this.productArray.length; i++)
            {
                console.log(this.productArray[i]);
                console.log(article);
                if(this.productArray[i].id == article.kkProdId)
                {           
                    this.getSelectedArticleInfo(this.productArray[i-1]);
                    this.selectedArticle = this.productArray[i-1];
                    this.articleSelected = true;
                    this.articlePageKey++;                    
                }
            }
        },
        nextArticle(article){
            for(var i = 0; i < this.productArray.length - 1; i++)
            {
            if(this.productArray[i].id == article.kkProdId)
                {        
                    this.getSelectedArticleInfo(this.productArray[i+1]);        
                    this.selectedArticle = this.productArray[i+1];
                    this.articleSelected = true;
                    this.articlePageKey++;              
                }             
            }
        },
        async getSelectedArticleInfo(article) {
          var vueInstance = this;
          const result = await vueInstance.doGet('TCPGetProductDetailsJ.action', {
            kkProdId: article.id,
            json: "true",
            includeAllergenIds: "true",
            includePropertyIds: "true",
            includeNutriValues: "true"
          });

          if ((result.exception !== undefined) || (result.item !== undefined)) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.getItemFailed'));
            return;
          }

          if (result.product.recipe != undefined) {
            var quantityParsed = parseInt(result.product.recipe);
            if (quantityParsed > 0) {
              vueInstance.selectedArticleIsOnStock = true;

            } else {
              vueInstance.selectedArticleIsOnStock = false;

            }
          } else {
            vueInstance.selectedArticleIsOnStock = true;

          }

          if (result.product.locked) {
            vueInstance.selectedArticleIsOnStock = false;
          }

          vueInstance.articleWebDescription = result.product.name;
        },
        articlePage(article, quantity, text) {
            var vueInstance = this;
            this.selectedArticle = article;
            this.articleSelected = true;

            if(text != undefined)
                this.articleWebDescription = text;

            if(quantity > 0)
                vueInstance.selectedArticleIsOnStock = true;

            vueInstance.$refs.articleDetailsModal.openModal();
        },
        selectWeightArticle(article, quantity) {
            this.articlePage(article, quantity);
        },        
        async selectArticle(articleId, element) {
          var vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doPost('TCPAddMenuItemToCartJ.action', {
            productId: articleId,
            json: "true",
            orderType: window.orderType,
            quantity: 1,
            calculateOrder: window.customerLoggedIn ? true : false
          });

          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal;
          vueInstance.$forceUpdate();
          vueInstance.isLoading = false;
          if (result.basketTile.paymentsList != undefined) {
            vueInstance.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
            window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
          } else {
            vueInstance.subsidy = "";
            window.subsidy = "";
          }
          if (element != undefined)
            element.shopCartQuantity = element.shopCartQuantity + 1;
          if ((result.exception !== undefined) || (result.item !== undefined)) {
            if (result.exception !== undefined)
              vueInstance.makeToast('danger', vueInstance.$t('notification.addItemFailed'));
            else
              vueInstance.makeToast('danger', vueInstance.$t('notification.addItemFailed'));
            return;
          }
        },
        async deleteItem(articleId, element) {
          var vueInstance = this;
          vueInstance.isLoading = true;

          var elementCartId = 0;
          if (window.shoppingCart != undefined) {
            for (var i = 0; i < window.shoppingCart.length; i++) {
              if (window.shoppingCart[i].prodId == articleId) {
                elementCartId = window.shoppingCart[i].id;
              }
            }
          }

          const result = await vueInstance.doPost('TCPRemoveMenuItemFromCartJ.action', {
            id: elementCartId,
            orderType: window.orderType
          });

          vueInstance.isLoading = false;

          if (result.exception !== undefined) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemFailed'));
            return;
          }

          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal;
          if (result.basketTile.paymentsList != undefined) {
            vueInstance.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
            window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
          } else {
            vueInstance.subsidy = "";
            window.subsidy = "";
          }
          vueInstance.$forceUpdate();

          if (element != undefined) {
            element.shopCartQuantity = 0;
            vueInstance.$forceUpdate();
          } else {
            if (!window.config.FORCE_SHOW_ARTICLE_DETAILS_ON_ADD) {
              vueInstance.productsLoaded = false;

              setTimeout(() => {
                vueInstance.productsLoaded = true;
              }, 10);
            }
          }
        },
        async removeArticle(articleId, element) {
          var vueInstance = this;
          vueInstance.isLoading = true;

          let cartId = 0;
          let currentCartQuantity = 0;

          if (vueInstance.basketItems != undefined) {
            for (var i = 0; i < vueInstance.basketItems.length; i++) {
              if (vueInstance.basketItems[i].prodId == articleId) {
                cartId = vueInstance.basketItems[i].id;
                currentCartQuantity = vueInstance.basketItems[i].quantity;
              }
            }
          }

          let newCartQuantity = currentCartQuantity - 1;
          const result = await vueInstance.doPost('TCPEditCartSubmitJ.action', {
            basketId: cartId,
            quantity: newCartQuantity,
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (newCartQuantity === 0) {
            await vueInstance.deleteItem(articleId, element);
            return;
          }

          vueInstance.isLoading = false;
          if (result.exception !== undefined) {
            if (result.exception.code === 64) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemMinError'));
              return;
            } else if (result.exception.code === 65) {
              vueInstance.makeToast('danger', vueInstance.$t('removeItemMaxError'));
              return;
            }
            vueInstance.makeToast('danger', vueInstance.$t('notification.removeItemFailed'));
            return;
          }

          window.shoppingCart = result.basketTile.basketItems;
          vueInstance.basketItems = result.basketTile.basketItems;
          vueInstance.basketTotal = result.basketTile.formattedTotal;

          if (result.basketTile.paymentsList != undefined) {
            vueInstance.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
            window.subsidy = vueInstance.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
          } else {
            vueInstance.subsidy = "";
            window.subsidy = "";
          }
          if (element != undefined)
            element.shopCartQuantity = newCartQuantity;
        },
        checkItemCart(prodId) {     
            var vueInstance = this;        
            if(vueInstance.basketItems != undefined)
            {
                for(var i = 0; i < vueInstance.basketItems.length; i++)
                {                              
                    if(vueInstance.basketItems[i].prodId == prodId)
                    {                       
                        return vueInstance.basketItems[i].quantity;
                    }
                }
            }

            return 0;       
        },
        getOrderTypeShort(){
            switch(window.orderType)
            {
                case 'delivery':
                    return "DE";
    
                case 'takeaway':
                    return "TA";
        
                case 'dinein':
                    return "DI";         
            }
        },
        async getAndFillProductsFromCategory(category) {
          var config = window.config;
          var vueInstance = this;
          vueInstance.isLoading = true;
          vueInstance.productsLoaded = false;

          const result = await vueInstance.doPost('TCPProductSearchJ.action', {
            dataDescriptor: {
              limit: vueInstance.limit,
              offset: vueInstance.offset,
              orderBy: config.ORDER_PRODUCTS_BY,
              fillCustomAttrArray: "true",
              fillDescription: "true",
              fillMiscItems: "true",
              fillProductOptions: "true",
              fillProductQuantities: "true",
            },
            json: 'true',
            orderType: window.orderType,
            dietaryInfo: config.USE_ALLERGEN_FILTERS && vueInstance.dietaryInfo.length > 0 ? vueInstance.dietaryInfo : undefined,
            productSearch: {
              returnCategoryFacets: "true",
              returnManufacturerFacets: "true",
              priceTypeRequired: vueInstance.getOrderTypeShort(),
              searchText: vueInstance.searchPhrase != "" ? vueInstance.searchPhrase : undefined,
              whereToSearch: vueInstance.whereToSearch,
              categoryId: vueInstance.searchPhrase != "" ? undefined : category.id
            }
          });

          vueInstance.productArray = result.products.productArray;
          vueInstance.productsLoaded = true;
          window.imageBaseUrl = result.imageBaseURL;
          vueInstance.checkOverflow();
          vueInstance.checkOverflowSub();
          vueInstance.checkOverflowSubSub();
          vueInstance.$forceUpdate();
          vueInstance.isLoading = false;
        },
        isArticleFirst(){
            if(this.productArray.indexOf(this.selectedArticle) == 0)
                return true;
            else
                return false;
        },
        isArticleLast(){
            if(this.productArray.indexOf(this.selectedArticle) == (this.productArray.length-1))
                return true;
            else
                return false;
        },
        onSubmit(event) {
            event.preventDefault();           
            this.$router.push({ path: '/Address' })
        },
        showGroup(groupId) {
            var selectedGroup = this.ArticleGroups.filter(function(x) { return x.groupId == groupId });
            this.currentGroup = selectedGroup[0];
        },
        resetFilters(){
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);

            if(myDiv != undefined)
            {
                myDiv.scrollTop = 0;
            }

            this.offset=0;
            this.searchPhrase = "";
            this.currentlyLoadingProducts = false;

        },
        showCat(categoryId) {
            this.resetFilters();
            this.currentCategory = this.categories.filter(function(x) { return x.id == categoryId })[0];
            this.currentSubCategory = {};
            this.getAndFillProductsFromCategory(this.currentCategory);
            var element = document.getElementById("menuSubCategoriesWrapper"); 
            var subelement = document.getElementById("menuSubSubCategoriesWrapper"); 
            subelement.style.display = "none";      
            if(this.currentCategory.children == undefined)
            {
                element.style.display = "none";                
            }
            else
            {
                element.style.display = "block";
            }
        },
        showSubCat(categoryId) {
            this.resetFilters();
            var catWithChildren = this.categories.filter(function(x) { return x.children != undefined });
            this.currentSubSubCategory = {
                id:0,
                children: []
            };
            for(var i = 0; i < catWithChildren.length; i++ )
            {
                var subCats = catWithChildren[i].children.filter(function(x) { return x.id == categoryId });
                if(subCats.length > 0)
                {
                    this.currentSubCategory = subCats[0];
                    this.getAndFillProductsFromCategory(this.currentSubCategory);
                    var element = document.getElementById("menuSubSubCategoriesWrapper"); 

                    if(this.currentSubCategory.children == undefined)
                    {
                        element.style.display = "none";                
                    }
                    else
                    {
                        element.style.display = "block";
                    }
                }
            }                      
        },
        showSubSubCat(categoryId) {
            this.resetFilters();
            var catWithChildren = this.currentSubCategory;            
            var subCats = catWithChildren.children.filter(function(x) { return x.id == categoryId });
 
            if(subCats.length > 0)
            {
                this.currentSubSubCategory = subCats[0];
                this.getAndFillProductsFromCategory(this.currentSubSubCategory);
            }   
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat(this.config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(value);
        },
        maximizeHeader() {
            $(".header").addClass("header-big");
            $(".header").addClass("animTransition");
            $(".content").addClass("header-big-content-margin");
            $(".content").removeClass("header-small-content-margin");
        },
        minimizeHeader() {
            $(".header").removeClass("header-big");
            $(".header").addClass("animTransition");
            $(".content").removeClass("header-big-content-margin");
            $(".content").addClass("header-small-content-margin");
        },
        trimDescriptionShort(description){
            if(description == undefined)
            {
                return "";
            }
            
            var config = window.config;
            var trimTo = config.TRIM_ARTICLE_DESCRIPTION_TO_SHORT;

            if(description.length <= trimTo)
            {
                return description;
            }

            return description.substring(0, trimTo) + "...";
        },
        handleScrollContent(event){
            try {
                var elem = $(event.currentTarget);                
                if (elem[0].scrollHeight - elem.scrollTop() <= (elem.outerHeight() * 2.35 ) && !this.currentlyLoadingProducts) {      
                    console.log("LOADING MORE PRODUCTS!");
                    this.loadMoreProducts();
                }   
            }
            catch (exception_var) {
                console.log(exception_var);
            }               
        },
        handleWheelContent(event) {
          if (Utils.isGreater(event.originalEvent.deltaY, 0))
          {
              if($(".header").hasClass("header-big"))
              {
                  var myDiv = document.getElementById('content-wrapper');
                  myDiv.scrollTop = 0;
                  this.minimizeHeader();
              }
          }
        },
        scrollCategories(selector){      
            var leftPos = $('#menuCategories').scrollLeft();
            var newLeftPos = 0;
            if(selector > 0)
                newLeftPos = leftPos + 350;
            else
                newLeftPos = leftPos - 350;

            $("#menuCategories").animate({ scrollLeft: newLeftPos }, 100);
        },

        scrollSubCategories(selector){            
            var leftPos = $('#menuSubCategories').scrollLeft();
            var newLeftPos = 0;
            if(selector > 0)
                newLeftPos = leftPos + 350;
            else
                newLeftPos = leftPos - 350;

            $("#menuSubCategories").animate({ scrollLeft: newLeftPos }, 100);
        },

        scrollSubSubCategories(selector){             
            var leftPos = $('#menuSubSubCategories').scrollLeft();
            var newLeftPos = 0;
            if(selector > 0)
                newLeftPos = leftPos + 350;
            else
                newLeftPos = leftPos - 350;

            $("#menuSubSubCategories").animate({ scrollLeft: newLeftPos }, 100);
        },

        checkOverflow() 
        {            
            if($("#menuCategories")[0] == undefined)
            {
                return;
            }              

            if($("#menuCategories")[0].scrollWidth + 20 < $("#menuCategories").width() || $("#menuCategories")[0].scrollWidth + - 20 < $("#menuCategories").width())
            {
                $(".mainCategoryScrollIcon").css("display", "none");
            }
            else
            {
                $(".mainCategoryScrollIcon").css("display", "block");
            }
        },
        checkOverflowSub() 
        {            
            if($("#menuSubCategories")[0] == undefined)
            {
                return;
            }

            if($("#menuSubCategories")[0].scrollWidth + 10 < $("#menuSubCategories").width() || $("#menuSubCategories")[0].scrollWidth + -10 < $("#menuSubCategories").width())
            {
                $(".subCategoryScrollIcon").css("display", "none");
            }
            else
            {
                $(".subCategoryScrollIcon").css("display", "block");
            }
        },
        checkOverflowSubSub() 
        {             
            if($("#menuSubSubCategories")[0] == undefined)
            {
                return;
            }

            if($("#menuSubSubCategories")[0].scrollWidth + 10 < $("#menuSubSubCategories").width() || $("#menuSubSubCategories")[0].scrollWidth + -10 < $("#menuSubSubCategories").width())
            {
                 $(".subSubCategoryScrollIcon").css("display", "none");
            }
            else
            {
                 $(".subSubCategoryScrollIcon").css("display", "block");
            }
        }
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave (to, from , next) {
        $("#app").removeClass("hideScroll");
        $(".content").unbind("scroll");

        next();
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteEnter (to, from, next) {
         next(vm => {
            var myDiv = document.getElementById('contentSection');
            $("#contentSection").scrollTop(0);
            $("#app").scrollTop(0);
            myDiv.scrollTop = 0;
            if(vm.groups.length > 0)
                vm.showGroup(vm.groups[0].groupId);                                    
        })        
    },
    beforeMount(){
        var vueInstance = this;

        if(window.timeSet != true)
        {
            vueInstance.$router.push({path:"/Shop"});
        }

        if(window.config.LAZY_LOAD_LIMIT != undefined)
        {
            if(window.config.LAZY_LOAD_LIMIT > 0)
            {
                vueInstance.limit = window.config.LAZY_LOAD_LIMIT;
            }
        }
        
        vueInstance.updateShoppingCart();
    },
    async mounted() {
      var config = window.config;
      var vueInstance = this;

      if (window.dietaryInfo != undefined) {
        vueInstance.dietaryInfo = window.dietaryInfo;
      }

      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });

      if (window.config.FORCE_LOGIN_BEFORE_USE && result.requiresLogin) {
        vueInstance.$router.push({path: '/Login'});
        return;
      } else {
        if (result.requiresLogin == false) {
          window.customerLoggedIn = true;
        }
      }

      if (config.USE_ALLERGEN_FILTERS && result.wondCustomer != undefined && result.wondCustomer.dietaryInfo != undefined) {
        vueInstance.dietaryInfo = result.wondCustomer.dietaryInfo;
      }

      setTimeout(() => {
        vueInstance.basketTile = window.shoppingTile;
        if (window.shoppingTile != undefined) {
          vueInstance.basketTotal = window.shoppingTile.formattedTotal;
        }
        vueInstance.basketItems = window.shoppingCart;
      }, 300);

      if (result.customer != undefined) {
        if (result.customer.type != undefined) {
          if (result.customer.type == 2) {
            window.isGuestCheckout = true;
          }
        }
      }


      this.$forceUpdate();

      $(".header").removeClass("header-big");
      $(".header").addClass("animTransition");
      $(".content").removeClass("header-big-content-margin");
      $(".content").addClass("header-small-content-margin");
      $("#content-wrapper").scrollTop = 0;
      $("#app").addClass("hideScroll");
      // $("#content-wrapper").bind("wheel", function (event) {
      //     vueInstance.handleWheelContent(event);
      // });

      $(".content").bind("scroll", function (event) {
        vueInstance.handleScrollContent(event);
      });

      $("#content-wrapper").bind("scroll", function (event) {
        vueInstance.handleScrollContent(event);
      });


      $("#searchField").on('keyup', function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
          vueInstance.doSearch();
        }
      });

      setTimeout(() => {
        vueInstance.updateShoppingCart();
        setTimeout(() => {
          vueInstance.basketTile = window.shoppingTile;
          vueInstance.basketItems = window.shoppingCart;
          vueInstance.subsidy = window.subsidy;
        }, 300);
      }, 300);

    }
}
</script>

<style scoped>
#content-wrapper{
    /* overflow-y:hidden !important; */
}

@media screen and (max-width: 1500px) {
    #bar-click {
        display:block;
    }
}
@media screen and (min-width: 1500px) {
    #bar-click {
        display:none;
    }
}

#menuCategories{    
    background-color:var(--background-color);
    display: flex;
    color: gray;
    text-align:start;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

#menuCategories div{  
    min-height:30px; 
    display:flex;
    min-width:fit-content;
    padding-left:15px;
    padding-right:15px;
}

#menuSubCategories{
    background-color:var(--background-color);
    display: flex;
    color: gray;
    text-align:start;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    padding-bottom: 8px;
}

#menuSubCategories div {   
    min-height:30px; 
    display:flex;
    min-width:fit-content;
    padding-left:0;
    padding-right:15px;
}

#menuSubSubCategories {
    background-color:var(--background-color);
    display: flex;
    color: gray;
    text-align:start;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

#menuSubSubCategories div {
    min-height:30px; 
    display:flex;
    min-width:fit-content;
    padding-left:15px;
    padding-right:15px;
}

#menuProducts {
   background-color: var(--products-area-color);
}

#menuCart{
    padding:0px 5px 0px 15px;
}

@media  screen and (max-width:1000px) {    
    #menuCart{
        display:none;
    }
}

.categoryScrollIcon{
    padding:10px 0 12px 0;
    width:3%;
}

.subCategoryScrollIcon{
    padding:0 0 0px 0;
}

@media  screen and (max-width:770px) {
    .categoryScrollIcon{
        display:none !important; 
    }    
}

.groupTitle {
    background-color: var(--products-area-color);
    text-align:left;
    margin-bottom:0;
    text-transform: var(--categories-text-transform); 
    padding-left: 10px;
    padding:10px;
    border-top-right-radius: 15px; 
    border-top-left-radius: 15px;
}

.shoppingCartSectionTitle{
    border-bottom: 3px solid var(--body-color);
    color:var(--main-color);
    font-weight:700;
    font-size:22px;
    width:100%;
    padding:3px;
    margin-bottom:5px;
}

.addIcon, .minusIcon{
    height:21px;
    stroke:var(--main-color-highlight);
}

.addIcon line, .minusIcon line{
    fill: white;
    stroke: white;
}

.addIcon circle, .minusIcon circle{
    fill: var(--main-color);
    stroke: var(--main-color);  
}

.addIcon:hover circle, .minusIcon:hover circle{
    fill: white;
    stroke: var(--main-color);
}

.addIcon:hover line, .minusIcon:hover line{
    fill: white;
    stroke: var(--main-color);
}

.shoppingCartSection{
    padding:10px;
    box-shadow:var(--box-shadow);
    
}

.menuCartElement{
    height: 100px;
    padding-top: 7px;
    border-bottom: 0.1em solid var(--body-color);
}

.searchContainer {
    height: 35px;
}

#searchField:focus{
    border:none !important;
    box-shadow:none !important;
}

.btnSearchField:focus{
     border:none !important;
    box-shadow:none !important;
}

.qtyInput {
    width:inherit;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.qtyInput{
    border: 0.2px solid var(--main-color);
}

.mainSearchBar{
    padding:0px;
    position: fixed; 
    width:80%;
    margin: 0 10% 0 10%;
    left:0px;
    z-index: 9999; 
    background-color:white;
    top:82px;    
}

.mainSearchBar::after{
  content: '';
  position: absolute;
  bottom: 0px; /* Abstand von unten */
  left: 18px; /* Abstand von links */
  right: 18px; /* Abstand von rechts */
  border-bottom:0.5px solid var(--main-color);
}

.productsAreaPositioner{
    padding-top: 55px;
}

@media screen and (max-width: 4000px) {
    .mainSearchBar {
        top:90px;    
    }

    .productsAreaPositioner {
        padding-top: 55px;
    }
}

@media screen and (max-width: 1200px) {
    .mainSearchBar {
        margin:0 0 0 0;
        width: 100%;
        top:82px;
    }

    .productsAreaPositioner{
        padding-top: 55px;
    }
}

@media screen and (max-width:770px)
{
    .mainSearchBar {
        top:80px;
    }

    .productsAreaPositioner{
        padding-top: 55px;
    }
}

@media screen and (max-width:575px)
{
    .mainSearchBar {
        top:82px;
    }
}

@media screen and (max-width:475px)
{
    .mainSearchBar {
        top:80px;
    }
}

.addIcon{
    padding-left:10px;
    padding-right:10px;
    float: right;
}

.minusIcon{
    float: left;
}

</style>