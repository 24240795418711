<template>
  <div class="editCustomerPageWrapper" style="padding-left: 15px; padding-right: 15px;">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="col-12 subPageHeader" style="position:relative;">
      <div class="row">
        <h1>{{  $t('title.studentAccounts')}}</h1>
        <div class="ml-auto row" style="padding-right: 7px;">
          <b-button class="btn btn-primary  btn-justify btn-right" @click="goBack(false)">
            {{$t('button.back')}}
          </b-button>
        </div>
      </div>
    </div>

    <div class="innerScroll">
      <b-button v-if="config.REGISTER_CHILD.enabled" class="btn btn-primary btn-lg btn-block" @click="goToRegisterAsChild()" style="margin-left: 0; margin-right: 0;">
        <font-awesome-icon :icon="['fas', 'plus']" /> {{ $t('button.registerAsChild') }}
      </b-button>
      <b-container fluid>
        <b-row class="d-none d-md-flex">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.description') }}</b-th>
                <b-th class="text-left">{{ $t('table.cardNumber') }}</b-th>
                <b-th class="text-right">{{ $t('table.prepayBalanceCash') }}</b-th>
                <b-th class="text-left" />
              </b-tr>
            </b-thead>
             <b-tbody v-if="!Utils.isEmpty(children)" class="va-middle">
              <b-tr v-for="child in children" v-bind:key="child.id">
                <b-td class="text-left">{{ child.description }}</b-td>
                <b-td class="text-left">{{ child.cardNum }}</b-td>
                <b-td class="text-right">{{ Utils.formatCurrency(getPrepayBalance(child), config.ACCOUNT_BALANCE_CURRENCY) }}</b-td>
                <b-td class="d-flex align-items-center justify-content-sm-center">
                  <b-button-group class="btn btn-block btn-sm">
                    <b-button v-b-tooltip :title="$t('button.charge')"  @click="openModalTopUp(child.id)"><font-awesome-icon :icon="['fas', 'credit-card']" /></b-button>
                    <b-button v-b-tooltip :title="$t('button.transactionHistory')" @click="showTransactionHistory(child.id)"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /></b-button>
                  </b-button-group>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="4">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-row>
        <b-row class="d-md-none">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.description') }}</b-th>
                <b-th class="text-right">{{ $t('table.prepayBalanceCash') }}</b-th>
                <b-th class="text-left" />
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(children)" class="va-middle">
              <b-tr v-for="child in children" v-bind:key="child.id">
                <b-td class="text-left">{{ child.description }}</b-td>
                <b-td class="text-right">{{ Utils.formatCurrency(getPrepayBalance(child), config.ACCOUNT_BALANCE_CURRENCY) }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button-group class="btn btn-block btn-sm">
                    <b-button v-b-tooltip :title="$t('button.charge')"  @click="openModalTopUp(child.id)"><font-awesome-icon :icon="['fas', 'credit-card']" /></b-button>
                    <b-button v-b-tooltip :title="$t('button.transactionHistory')" @click="showTransactionHistory(child.id)"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /></b-button>
                  </b-button-group>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="3">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import loading from "vue-loading-overlay";

export default {
  components: {loading},
  data() {
    return {
      isLoading: false,
      children: {
        Type: Array,
        default: []
      },
      customerCardWithCreditFunktion: false,
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    },
  },
  methods: {
    getPrepayBalance(child) {
      let separator = Utils.getDecimalSeparator(child.formattedPrepayBalanceCash);
      return (child.prepayBalanceCash + child.prepayBalanceVoucher + child.prepayBalanceBonus).toFixed(this.config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
    },
    async openModalTopUp(id) {
      window.isParent = true;
      await this.selectChild(id);
      this.$router.push({path: '/TopUp' });
    },
    async showTransactionHistory(id) {
      await this.selectChild(id);
      await this.$router.push({path: '/TransactionHistory'});
    },
    async getStudentAccounts() {
      let vueInstance = this;

      vueInstance.isLoading = true;
      const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });
      vueInstance.isLoading = false;

      if (result.exception !== undefined) {
        console.log(result.exception);
        return;
      }

      vueInstance.children = result.wondCustomer.children;
    },
    goToRegisterAsChild(){
      this.$router.push({path: '/RegisterAsChild' });
    },
    goToAccount(){
      this.$router.push({path: '/Account' });
    },
    async selectChild(id) {
      var vueInstance = this;

      await vueInstance.doGet('TCPSelectChildJ.action', {
        json: "true",
        wondChildId: id
      });
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    await this.getStudentAccounts();

    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }
  },
  beforeRouteLeave (to,from,next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
    }

    clearInterval(this.intervalId);
    next();
  }
}
</script>
<style scoped>

.innerScroll{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 280px);
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .innerScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 340px);
    width: 100%;
  }
}

.va-middle td{
  vertical-align: middle;
}

#content-wrapper
{
  overflow-y: hidden;
}
</style>