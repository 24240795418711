<template>

  <b-container fluid class="footer" style="z-index: 10;">
    <b-row class="d-none d-md-flex">
      <b-col cols="12" md="8">
        <div id="footerLinks" class="text-center text-md-left footer-col" v-for="link in config.FOOTER.links" :key="link.Key">
          <a class="mainColor" :href="link.Url" target="_blank">{{ $t(link.Text) }}</a>
        </div>
      </b-col>
      <b-col cols="12" md="4">
        <div class="text-center text-md-right footer-col" v-if="!config.USE_EXTERNAL_LANGUAGE">
          <b-button-group v-if="config.LANGUAGE_LIST != undefined && config.LANGUAGE_LIST.length > 1" id="languagesToolbar">
            <b-button id="btnLanguages" class="btn btn-inverted-header" v-for="entry in config.LANGUAGE_LIST" :key="entry.value" v-on:click="changeLocale(entry.value)" :aria-label="$t('ariaLabel.changeLanguage') + entry.title">{{entry.title}}</b-button>
          </b-button-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-none d-md-flex" v-if="config.FOOTER.company.enabled">
      <b-col cols="12">
        <div class="text-center text-md-left footer-col">
          <div class="text-left mainColor" v-html="config.FOOTER.company.html" />
        </div>
      </b-col>
    </b-row>

    <b-row class="d-md-none">
      <b-col cols="4">
        <b-button class="float-left btn btn-inverted-header" v-b-toggle.collapse-footer v-model="isOpen" v-on:click="setToggleState"><b-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'"></b-icon></b-button>
      </b-col>
      <b-col cols="8">
        <div class="float-right" v-if="!config.USE_EXTERNAL_LANGUAGE">
          <b-button-group v-if="config.LANGUAGE_LIST != undefined && config.LANGUAGE_LIST.length > 1" id="languagesToolbar">
            <b-button id="btnLanguages" class="btn btn-inverted-header" v-for="entry in config.LANGUAGE_LIST" :key="entry.value" v-on:click="changeLocale(entry.value)" :aria-label="$t('ariaLabel.changeLanguage') + entry.title">{{entry.title}}</b-button>
          </b-button-group>
        </div>
      </b-col>
      <b-collapse id="collapse-footer">
        <b-col cols="12">
          <div class="text-left text-md-left footer-col" v-for="link in config.FOOTER.links" :key="link.Key">
            <a :href="link.Url" class="text-center mainColor" target="_blank">{{ $t(link.Text) }}</a>
          </div>
        </b-col>
        <b-col cols="12" v-if="config.FOOTER.company.enabled">
          <div class="text-left text-md-left footer-col">
            <div class="text-left mainColor" v-html="config.FOOTER.company.html" />
          </div>
        </b-col>
      </b-collapse>
    </b-row>
  </b-container>
</template>

<script>
export default {
    data() {
      return {
        isOpen: false, // Initially opened
      };
    },
    name:"global-footer",
    methods: {
      setToggleState() {
        this.isOpen = !this.isOpen;
      }
    },
    props: {
        footerLinks : {
            Type: Array            
        },
        changeLocale: {
            Type: Function,
            required: true
        },
        config: {
            Type: Array
        }
    },
    computed: {
        showHeaderFooter(){
            if(window.showHeaderFooter === undefined){
                return true;
            }else{
                return window.showHeaderFooter;
            }
        },
        isInIFrame(){
            try{
                return window.self !== window.top;
            } catch (e) {
                return true;
            }
        },
    }
}
</script>

<style scoped>
  .mainColor{
    color: var(--global-footer-color) !important;
  }

  .footer {
    background-color: var(--global-footer-bg) !important;
    color: var(--global-footer-color) !important;
    width:80%;
    position:fixed !important;
    bottom:0 !important;
    background-blend-mode: darken;
  }

  .footer-col {
    padding-right: 7px;
  }

  @media (max-width: 1200px) {
    .footer {
      width:100%;
    }
  }
</style>