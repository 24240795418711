<template>
    <div class="accountPageWrapper">
        <loading :active.sync="isLoading" 
            :can-cancel="true"        
            :is-full-page="true"
            loader='bars'></loading>

        <div class="col-12 subPageHeader">
          <div class="row">
            <h1>{{ $t('title.account') }}</h1>

            <div class="ml-auto row" style="padding-right: 7px;">
              <b-button class="btn btn-primary btn-justify btn-right" v-if="!config.IS_PURE_ACCOUNTMANAGER" @click="goBack(false)">
                {{$t('button.back')}}
              </b-button>
              <b-button v-if="showEditAddressForm == false && showNewAddressForm == false" class="btn btn-primary btn-justify btn-right" @click="logout">{{$t('button.logout')}}</b-button>
            </div>
          </div>
        </div>

      <div class="innerScroll">
        <b-container fluid>
          <b-row>
            <b-col id="customerCard" cols="12" md="6" v-if="!isParent" class="paddingRight">
              <b-table-simple class="tableResponsive" small borderless caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th colspan="12" class="text-left">{{ $t('subTitle.customerCard') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-if="showQrCode && config.SHOW_CUSTOMER_QR_CODE">
                    <b-td colspan="12">
                      <div class="text-center">
                        <radial-progress-bar style="margin:auto"
                          :diameter="config.QRCODE_RADIAL_PROGRESSBAR_SIZE"
                          :completed-steps="30 - qrCodeSeconds"
                          :total-steps="30"
                          startColor="var(--main-color)"
                          stopColor="var(--main-color)"
                          innerStrokeColor ="lightgray">
                          <qr-code :value="qrCode" :size="config.QRCODE_SIZE"></qr-code>
                          {{qrCodeSeconds}}s
                        </radial-progress-bar>
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr v-if="!isSchoolMode">
                    <b-td colspan="12" style="padding-left: 0; padding-right: 0;">
                      <b-button v-if="config.TOPUP.enabled" style="margin-left: 0;" class="customerCardBalanceButton btn btn-primary" @click="openModalTopUp()">{{ $t('button.charge') }}</b-button>
                      <b-button v-if="config.AUTO_TOPUP_ENABLED" style="margin-left: 0;" class="customerCardBalanceButton btn btn-primary" @click="openAutoTopupModal()">{{ $t('button.autoCharge') }}</b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot foot-variant="dark">
                  <b-tr v-if="customerCardWithCreditFunktion">
                    <b-td colspan="8" class="text-left" variant="secondary"><b>{{ $t('text.customerCardCreditBalance') }}</b></b-td>
                    <b-td colspan="4" class="text-right" variant="secondary"><b>{{ Utils.formatCurrency(creditBalance, config.ACCOUNT_BALANCE_CURRENCY, true, config.ACCOUNT_BALANCE_SEPARATOR) }}</b></b-td>
                  </b-tr>
                  <b-tr v-else>
                    <b-td colspan="8" class="text-left" variant="secondary"><b>{{ $t('text.customerCardBalance') }}</b></b-td>
                    <b-td colspan="4" class="text-right" variant="secondary"><b>{{ Utils.formatCurrency(prepayBalanceCash, config.ACCOUNT_BALANCE_CURRENCY, true, config.ACCOUNT_BALANCE_SEPARATOR) }}</b></b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
              <b-table-simple class="tableResponsive" v-if="config.ACCOUNT_PAGE_SHOW_PENDING_TOPUP && pendingCustomerCardPayments.length > 0" small borderless caption-top responsive>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th colspan="12" class="text-center">{{ $t('subTitle.customerCardBalancePending') }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th colspan="8" class="text-left">{{ $t('text.customerCardBalancePendingDate') }}</b-th>
                    <b-th colspan="4" class="text-right">{{ $t('text.customerCardBalancePendingAmount') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="item in pendingCustomerCardPayments" v-bind:key="item.paymentTimestamp">
                    <b-td class="text-left" colspan="8">{{ formatDate(item.paymentTimestamp) }}</b-td>
                    <b-td class="text-right" colspan="4">{{ formatDate(item.amount) }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
            <b-col id="customerData" cols="12" md="6" class="paddingLeftSm">
              <b-table-simple class="tableResponsive" small borderless caption-top responsive>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th class="text-left" colspan="12">{{ $t('subTitle.customerData') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td class="text-left" colspan="12">
                      <ul class="text-left customerDataTable">
                        <li v-if="addressLoaded">{{ checkEmpty(addresses[0].firstName)}} {{checkEmpty(addresses[0].lastName)}}</li>
                        <li v-if="addressLoaded">{{ checkEmpty(addresses[0].streetAddress)}}</li>
                        <li v-if="addressLoaded">{{ checkEmpty(addresses[0].postcode)}} {{checkEmpty(addresses[0].city)}}</li>
                        <li v-if="addressLoaded && config.SHOW_CUSTOMER_COUNTRY_NAME">{{ checkEmpty(addresses[0].countryName) }}</li>
                        <li v-if="addressLoaded">{{ checkEmpty(addresses[0].telephoneNumber) }}</li>
                      </ul>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td style="padding-right: 0; padding-left: 0;">
                      <b-button v-if="config.USE_ALLERGEN_FILTERS" class="customerCardBalanceButton btn btn-primary" @click="openDietaryModal()">{{ $t('button.dietaryInfos') }}</b-button>
                      <b-button v-if="config.CUSTOMER_DATA.editable" class="customerCardBalanceButton btn btn-primary" @click="modify">{{$t('button.editCustomer')}}</b-button>
                      <b-button v-if="config.SHOW_ACCOUNT_PAGE_CHANGE_PASSWORD" class="customerCardBalanceButton btn btn-primary" @click="changePassword">{{$t('button.changePassword')}}</b-button>
                      <b-button v-if="config.SHOW_ACCOUNT_PAGE_ORDER_HISTORY && !isParent" class="customerCardBalanceButton btn btn-primary" @click="showOrderHistory()">{{ $t('button.orderHistory') }}</b-button>
                      <b-button v-if="config.SHOW_ACCOUNT_PAGE_TRANSACTION_HISTORY && !isParent" class="customerCardBalanceButton btn btn-primary" @click="showTransactionHistory()">{{ $t('button.transactionHistory') }}</b-button>
                      <b-button v-if="isParent" class="customerCardBalanceButton btn btn-primary" @click="showStudentAccounts()">{{ $t('button.studentAccounts') }}</b-button>
                      <!--<b-button class="customerCardBalanceButton btn btn-primary" @click="showMealPlan()">{{ $t('button.mealPlan') }}</b-button>-->
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-container>

        <div class="col-12 customerJustify" style="padding-left: 0;">
          <div class="col-12 customerData">

            <div style="padding-bottom:50px;" v-if="config.SHOW_ACCOUNT_PAGE_ADVANCED_CUSTOMER_AREA">
              <div v-for="item in pointCampaignsDefinitions" v-bind:key="item.campaignId"  style="text-align:left;  background:white; padding: 5px; border-bottom:0.2px solid var(--main-color); margin:5px;">
                <span style="font-size:20px;">{{ getCampaignName(item.id) }}</span>
                <br/>
                <span v-if="item.points > 0">
                            <span style="font-size:15px;"> {{ $t('text.rewardPointsBalance') }} </span>
                            <span style="color:var(--main-color); font-size:20px;"> {{ item.points }} </span>
                        </span>
              </div>
            </div>
            <h5 v-if="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES">{{$t('subTitle.customerAddresses')}}</h5>
            <div v-if="!config.IS_PURE_ACCOUNTMANAGER && config.SHOW_ACCOUNT_PAGE_ADRESSES" style="margin-bottom:120px;">
              <select class="vueselect-wrapper col-xl-8 col-sm-7" id="addressPicker" style="padding:0;"  @change="closeAddressForm">
                <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', ' + address.streetAddress + ', ' + address.postcode + ' ' +  address.city + ', ' + address.countryName + ' - ' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
              </select>

              <b-button type="button" @click="editAddress" class="btn btn-primary btnAddress col-2" style="margin: 0px 0px 5px 0px;height: 48px; padding:0;">
                <i class="editIcon fa fa-pencil">   </i>
              </b-button>

              <b-button type="button" @click="addNewAddress" class="btn btn-primary btnAddress col-2" style="margin: 0px 0px 5px 0px;height: 48px; padding:0;">
                <i class="editIcon fa fa-plus">   </i>
              </b-button>
            </div>

            <div id="addressFormWrapper">
              <div class="col-12 addressFormPadding"  v-if="showEditAddressForm">
                <h2>{{$t('subTitle.editAddress')}}</h2>
                <b-button type="button" v-if="selectedAddress.isPrimary == false" @click="deleteAddress" class="btn btn-inverted col-1" style="margin: 0px 0px 5px 0px;height: 48px;min-width:10%;">
                  <i class="editIcon fa fa-trash">   </i>
                </b-button>
                <address-form :addressId="selectedAddress.id" :addressModel="selectedAddress" :isEdit="true" :callbackClose="closeAddressForm" :customer="customer"></address-form>
              </div>
              <div class="col-12 addressFormPadding"  v-if="showNewAddressForm">
                <h2>{{$t('subTitle.addAddress')}}</h2>
                <address-form :addressId="0" :addressModel="addAddress" :isEdit="false" :callbackClose="closeAddressForm" :customer="customer"></address-form>
              </div>
            </div>
          </div>
        </div>
      </div>
        
        <modal ref="autoTopupModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('title.autoCharge')}}</h2>
            </template>

            <template v-slot:body>

                <b-form @submit="autoTopupFormSubmit">
                    <!-- <b-form-group id="input-group-1"> -->
                        <b-form-checkbox
                        id="checkbox-1"
                        v-model="autoTopupForm.active"
                        name="checkbox-1"
                        value="1"
                        unchecked-value="0"
                        switch
                        >
                        {{$t('text.enabled')}}
                        </b-form-checkbox>
                    <!-- </b-form-group> -->

                    <b-form-group
                            id="input-group-2"
                            :label="$t('text.threshold')"
                            label-for="input-2"
                            :description="$t('text.thresholdDescription')"
                            :append="' ' + config.ACCOUNT_BALANCE_CURRENCY"
                        >
                    <b-form-input
                        id="input-2"
                        v-model="autoTopupForm.thresholdValue"
                        type="number"
                        placeholder="0"
                        step="0.01"
                        required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-3" :label="$t('text.rechargeAmount')" label-for="input-3" :description="$t('text.rechargeAmountDescription')">
                        <b-form-input
                        id="input-3"
                        v-model="autoTopupForm.rechargeValue"
                        type="number"
                        placeholder="0"
                        step="0.01" 
                        required                            
                        ></b-form-input>
                    </b-form-group>

                    <b-button type="submit" variant="primary">{{$t("button.confirm")}}</b-button>
                    <b-button type="reset" variant="primary" @click="$refs.autoTopupModal.closeModal()">{{$t("button.cancel")}}</b-button>
                </b-form>
            </template>

            <template v-slot:footer>
                <!-- <div class="d-flex align-items-center justify-content-between">
                <b-button class="btn btn--secondary" @click="$refs.autoTopupModal.closeModal()">Cancel</b-button>
                <b-button class="btn btn--primary" @click="$refs.autoTopupModal.closeModal()">Save</b-button>
                </div> -->
            </template>
        </modal>

        <modal ref="dietaryModal">
            <template v-slot:header>
                <h2 id="modalTitle">{{$t('title.dietaryInfo')}}</h2>
            </template>

            <template v-slot:body>

                <b-form @submit="dietaryFormSubmit">
                    <h4 v-if="alergenOptions.length > 0">{{$t('subTitle.allergens')}}</h4>
                    <b-form-checkbox-group
                        id="allergenCheckboxes"
                        v-model="selectedAllergens"
                        :options="alergenOptions"
                        name="allergens"
                        class="mb-4"
                        v-if="alergenOptions.length > 0"
                    >
                    </b-form-checkbox-group>

                    <h4 v-if="propertiesOptions.length > 0">{{$t('subTitle.properties')}}</h4>
                    <b-form-checkbox-group
                        id="propertiesCheckboxes"
                        v-model="selectedProperties"
                        :options="propertiesOptions"
                        name="dietaryProperties"
                        class="mb-4"
                        v-if="propertiesOptions.length > 0"
                    >

                    </b-form-checkbox-group>

                    <b-button type="submit" variant="primary">{{$t('button.confirm')}}</b-button>
                    <b-button type="reset" variant="primary" @click="$refs.dietaryModal.closeModal()">{{$t('button.cancel')}}</b-button>
                </b-form>
            </template>

            <template v-slot:footer>
            </template>
        </modal>
    </div>
</template>

<script>
import Modal from '../sub-components/ModalAccessible.vue'
import VueLoading from 'vue-loading-overlay'
import $ from 'jquery'
import AddressForm from '../main-components/AddressForm.vue'
import QrcodeVue from 'qrcode.vue'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import base32Encode from 'base32-encode'
import RadialProgressBar from 'vue-radial-progress'
import Utils from "@/utils/utils";
import {UserTypes} from "@/constants/constants";

// import CircularCountDownTimer from "vue-circular-count-down-timer";
// import VueEllipseProgress from 'vue-ellipse-progress';
// import base32Encode from 'base32-encode'
// import crypto from 'crypto'
// import derivePassword from 'derive-password-bytes'

export default {
    data(){
        return {
            topUpAmount:null,
            selectedAddress: {
                Type: Object
            },
            addresses: {
                Type: Array,
                default: []
            },
            pendingCustomerCardPayments:{
                Type: Array,
                default: []
            },
            isLoading: false,
            showEditAddressForm: false,
            showNewAddressForm: false,
            cardNum: "",
            qrCode:"",
            qrCodeSeconds: 30,
            intervalId: 0,
            pointsBalance:0,
            showQrCode: false,
            pointCampaigns: [],   
            pointCampaignsDefinitions: [],
            addressLoaded: false,
            prepayBalanceCash: 0,
            showCustomerAdvancedArea: false,
            showTopUpPayment: false,
            showSaferpay: false,
            showSecurepay: false,
            showNovalnet: false,
            showPayone: false,
            showAdyen: false,
            paymentList: [],
            currentPayment: "",
            currentPaymentSubCode: "",
            autoTopupForm: {
                active: false,
                thresholdValue: 0,
                rechargeValue: 0,
            },
            selectedAllergens: [],
            previousSelectedAllergens: [],
            alergenOptions: [],
            selectedProperties: [],
            previousSelectedProperties: [],
            propertiesOptions: [],
            customerCardWithCreditFunktion: false,
            creditBalance: 0,
            currencyCode: undefined,
            isParent: false,
            isChild: false,
        }
    },
    computed:{
      Utils() {
        return Utils
      },
        config(){
            return window.config;
        },
        isSchoolMode() {
          return this.isParent || this.isChild;
        },
        currentlySelectedPayment (){            
            if(this.currentPayment == "Saferpay")
            {
                console.log(this.currentPayment + "~~" + this.currentPaymentSubCode);
               return this.currentPayment + "~~" + this.currentPaymentSubCode;
            }
            else if(this.currentPayment == "PayOne")
            {
                if(this.currentPaymentSubCode !== "")
                {
                    return this.currentPayment + "~~" + this.currentPaymentSubCode;
                }
                else
                {
                    return this.currentPayment;
                }
            } 
            else
            {
                return this.currentPayment;
            }            
        },        
    },
    components:{
        'loading' : VueLoading,
        'address-form' : AddressForm,
        'modal': Modal,
        'qr-code' : QrcodeVue,
        'radial-progress-bar': RadialProgressBar,
    },
    methods:{
      adjustInnerScrollHeight() {
        const innerScroll = this.$el.querySelector('.innerScroll');
        const siteHeader = this.$el.querySelector('.siteHeader');
        const subPageHeader = this.$el.querySelector('.subPageHeader');
        const footer = this.$el.querySelector('.footer');

        const siteHeaderHeight = siteHeader ? siteHeader.offsetHeight : 0;
        const subPageHeaderHeight = subPageHeader ? subPageHeader.offsetHeight : 0;
        const footerHeight = footer ? footer.offsetHeight : 0;

        const availableHeight = window.innerHeight - siteHeaderHeight - subPageHeaderHeight - footerHeight;

        if (innerScroll) {
          innerScroll.style.height = `${availableHeight}px`;
        }
        console.log("resize end");
      },
        async refreshUserData() {
          var config = window.config;
          var vueInstance = this;

          const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
            json: "true",
          });

          if (result.exception !== undefined) {
            vueInstance.$router.go(-1);
            return;
          }
          if (result.requiresLogin) {
            await vueInstance.$router.push({path: '/Login'});
            return;
          }

          vueInstance.customer = result.customer;
          vueInstance.addresses = result.customer.addresses;
          vueInstance.addressLoaded = true;
          vueInstance.pointsBalance = result.rewardPointsAvailable;
          vueInstance.selectedAddress = result.customer.addresses[0];

          if (result.wondCustomer != undefined) {
            vueInstance.pointCampaignsDefinitions = result.wondCustomer.pointCampaigns;
            vueInstance.cardNum = result.wondCustomer.cardNum;
            if (result.wondCustomer.cardType.toUpperCase() === "CREDIT") {
              let separator = Utils.getDecimalSeparator(result.wondCustomer.formattedCreditBalance);
              vueInstance.creditBalance = result.wondCustomer.creditBalance.toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
              vueInstance.customerCardWithCreditFunktion = true;
            } else {
              let separator = Utils.getDecimalSeparator(result.wondCustomer.formattedPrepayBalanceCash);
              vueInstance.prepayBalanceCash = (result.wondCustomer.prepayBalanceCash + result.wondCustomer.prepayBalanceVoucher + result.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
            }
            vueInstance.startQrCodeInterval();

            if (result.wondCustomer.pendingTopup != undefined) {
              vueInstance.pendingCustomerCardPayments = result.wondCustomer.pendingTopup;
            }
          }

          if (result.customer != undefined) {
            if (result.customer.type != undefined) {
              if (result.customer.type == 2) {
                window.isGuestCheckout = true;
              }
            }
          }
        },
        timeRemaining(){
            var remainingTimeMessage = "";
            var remainingTimeInMillisecounds = window.remainingSessionTime;
            if (remainingTimeInMillisecounds > 0) {
                var timeString = "";
                if ((remainingTimeInMillisecounds / 60000) > 1) {
                    timeString = Math.floor(remainingTimeInMillisecounds / 60000) + " min";
                }else{
                    timeString = "< 1 min";
                }
                remainingTimeMessage = this.$t('text.timeRemaining') + "" + timeString + " ";
            }
            return remainingTimeMessage;
        },
        closeTopUp(status){
            var vueInstance = this;
            if(status >= 0)
            {
                setTimeout(async () => {
                  vueInstance.$refs.topUpModal.closeModal();
                  vueInstance.makeToast('success', vueInstance.$t('notification.chargeSuccessful'));

                  await vueInstance.doGet('TCPStartJ.action', {
                    json: "true",
                    emptyBasket: "true"
                  });
                  window.shoppingCart = null;
                }, 300);    
                
                setTimeout(() => {
                    vueInstance.refreshUserData();
                    vueInstance.$forceUpdate();
                }, 3000);
            }                
        },
        onSubmitTopUp(paymentMethod){
            this.currentPayment = paymentMethod.code;
            if(paymentMethod.subCode != null || paymentMethod.subCode != undefined)
            {
                if( paymentMethod.subCode != ""){
                    this.currentPaymentSubCode = paymentMethod.subCode;
                }
            }
            this.onSubmit();
        },
        async onSubmit() {
          this.isLoading = true;
          var vueInstance = this;

          const result = await vueInstance.doPost('TCPPaymentSubmitJ.action', {
            deliveryAddress: "0",
            paymentMethod: this.currentlySelectedPayment,
            json: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          if (result.paymentMethod == "Saferpay~~P" || result.paymentMethod == "Saferpay~~T") {
            vueInstance.isLoading = false;
            console.log(vueInstance.currentPayment);
            vueInstance.showSaferpay = true;
            vueInstance.$forceUpdate();
            return;
          }

          if (result.paymentMethod == "Securepay") {
            vueInstance.isLoading = false;
            console.log(vueInstance.currentPayment);
            vueInstance.showSecurepay = true;
            vueInstance.$forceUpdate();
            return;
          }

          if (result.paymentMethod == "Novalnet") {
            vueInstance.isLoading = false;
            console.log(vueInstance.currentPayment);
            vueInstance.showNovalnet = true;
            vueInstance.$forceUpdate();
            return;
          }

          if (result.paymentMethod == "PayOne") {
            vueInstance.showPayone = true;
            console.log(vueInstance.currentPayment);
            vueInstance.isLoading = false;
            vueInstance.$forceUpdate();
            return;
          }

          if (result.paymentMethod == "Adyen") {
            vueInstance.isLoading = false;
            console.log(vueInstance.currentPayment);
            vueInstance.showAdyen = true;
            vueInstance.$forceUpdate();
            return;
          }

          if (result.paymentMethod == "StripeModule") {
            return;
          }

          if (result.exception !== undefined) {
            vueInstance.isLoading = false;
            vueInstance.makeToast('danger', this.$t("notification.chargeError"));
            return;
          }

          vueInstance.isLoading = false;
          window.shoppingCart = null;
          await vueInstance.$router.push({path: '/Payment'});
        },
        processPayment(){
            return;
        },
        async confirmAndPayOrder() {
          var vueInstance = this;
          vueInstance.isLoading = true;

          const result = await vueInstance.doGet('TCPPaymentJ.action', {
            json: "true",
            orderType: window.orderType,
            comment: this.comment
          });

          if (result.exception !== undefined) {
            vueInstance.isLoading = false;
            return;
          }

          if (result.requiresLogin == true) {
            vueInstance.isLoading = false;
            return;
          }

          vueInstance.processPayment();
          vueInstance.paymentList = result.paymentDetails.filter(function (x) {
            return (x.code != "customercard" && x.code != "cod")
          });
          if (vueInstance.paymentList.length == 1) {
            vueInstance.onSubmitTopUp(vueInstance.paymentList[0]);
          }
          vueInstance.showTopUpPayment = true;
          vueInstance.isLoading = false;

          window.onmessage = async function (e) {
            let result = e.data.toString();
            console.log("WINDOW ONMESSAGE EVENT " + result);

            if (result == "S" || result == "A" || result == "F" ||
                result.startsWith("payoneA") || result.startsWith("payoneB") || result.startsWith("payoneD")) {
              vueInstance.isLoading = true;
            }

            if (result == "S") {
              if (vueInstance.currentPaymentSubCode == "P") {
                //TODO
                vueInstance.closeTopUp(0);
                vueInstance.isLoading = false;
                return;
              }

              await vueInstance.doPost('ProcessSaferpayJ.action', {
                json: "true",
                vresult: result
              });

              vueInstance.isLoading = false;
              //TODO
              vueInstance.closeTopUp(0);
            } else if (result == "A") {
              vueInstance.showSaferpay = false;
              vueInstance.showSecurepay = false;
              vueInstance.showNovalnet = false;
              vueInstance.showPayone = false;
              vueInstance.showAdyen = false;
              vueInstance.isLoading = false;

            } else if (result == "F") {
              vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
              vueInstance.showSaferpay = false;
              vueInstance.showSecurepay = false;
              vueInstance.showNovalnet = false;
              vueInstance.showPayone = false;
              vueInstance.showAdyen = false;
              vueInstance.isLoading = false;
            } else if (result.startsWith("payoneA")) {

              await vueInstance.doGet('ProcessPayOneJ.action', {
                json: "true",
                vresult: result.replace('payone', '')
              });

              vueInstance.isLoading = false;
              vueInstance.$refs.topUpModal.closeModal();
            } else if (result.startsWith("payoneB")) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.paymentAbort'));
              vueInstance.$refs.topUpModal.closeModal();
            } else if (result.startsWith("payoneD")) {
              vueInstance.makeToast('danger', vueInstance.$t('notification.paymentError'));
              vueInstance.$refs.topUpModal.closeModal();
            }
          }
        },
        clearModal() {
            var vueInstance = this;
            vueInstance.topUpAmount = null; 
            vueInstance.showTopUpPayment = false;
            vueInstance.currentPayment = "";
            vueInstance.currentPaymentSubCode = "";
            vueInstance.showSaferpay = false;
            vueInstance.showSecurepay = false;
            vueInstance.showNovalnet = false;
            vueInstance.showPayone = false;
            vueInstance.showAdyen = false;
        },
        async openModalTopUp() {
          await this.$router.push({path: '/TopUp'});
        },
        async openAutoTopupModal() {
          var vueInstance = this;
          this.isLoading = true;

          const result = await vueInstance.doGet('TCPEditCustomerJ.action', {
            json: "true",
          });

          let recurringPaymentData = result.recurringPayment;
          if (recurringPaymentData.active != undefined) {
            vueInstance.autoTopupForm.active = recurringPaymentData.active;
          }
          if (recurringPaymentData.thresholdValue != undefined) {
            vueInstance.autoTopupForm.thresholdValue = recurringPaymentData.thresholdValue;

          }
          if (recurringPaymentData.rechargeValue != undefined) {
            vueInstance.autoTopupForm.rechargeValue = recurringPaymentData.rechargeValue;
          }
          vueInstance.isLoading = false;

          vueInstance.$refs.autoTopupModal.openModal()
        },
        async openDietaryModal() {
          var vueInstance = this;
          this.isLoading = true;

          const result = await vueInstance.doGet('TCPGetDietaryInfoTypesJ.action', {
            json: "true",
          });

          vueInstance.isLoading = false;

          vueInstance.previousSelectedAllergens = vueInstance.selectedAllergens;
          vueInstance.alergenOptions = [];
          vueInstance.previousSelectedProperties = vueInstance.selectedProperties;
          vueInstance.propertiesOptions = [];

          if (result.allergens != undefined) {
            $.each(result.allergens, function (index, value) {
              vueInstance.alergenOptions.push({text: value.name, value: value.id});
            });
          }

          if (result.properties != undefined) {
            $.each(result.properties, function (index, value) {
              vueInstance.propertiesOptions.push({text: value.name, value: value.id});
            });
          }

          vueInstance.$refs.dietaryModal.openModal();
        },
        async autoTopupFormSubmit(event) {
          var vueInstance = this;
          this.isLoading = true;

          event.preventDefault();

          await vueInstance.doPost('TCPEditCustomerSubmitJ.action', {
            json: "true",
            recurringPayment: {
              active: vueInstance.autoTopupForm.active,
              thresholdValue: vueInstance.autoTopupForm.thresholdValue,
              rechargeValue: vueInstance.autoTopupForm.rechargeValue,
            }
          });

          vueInstance.isLoading = false;

          vueInstance.makeToast('success', vueInstance.$t('notification.autoChargeConfigUpdate'));

          vueInstance.$refs.autoTopupModal.closeModal();
        },
        async dietaryFormSubmit(event) {
          var vueInstance = this;
          event.preventDefault();

          this.isLoading = true;

          let attributes =  [];

          $.each(vueInstance.selectedAllergens, function (index, value) {
            attributes.push({attributeId: value, value: "1"});
          });

          $.each(vueInstance.previousSelectedAllergens, function (index, value) {
            if (vueInstance.selectedAllergens.indexOf(value) < 0) {
              attributes.push({attributeId: value, value: "0"});
            }
          });

          $.each(vueInstance.selectedProperties, function (index, value) {
            attributes.push({attributeId: value, value: "1"});
          });

          $.each(vueInstance.previousSelectedProperties, function (index, value) {
            if (vueInstance.selectedProperties.indexOf(value) < 0) {
              attributes.push({attributeId: value, value: "0"});
            }
          });

          const result = await vueInstance.doPost('TCPEditCustomerDietaryAttributesSubmitJ.action', {
            json: "true",
            attributes: attributes
          });

          vueInstance.isLoading = false;
          window.dietaryInfo = result.wondCustomer.dietaryInfo;
          vueInstance.makeToast('success', vueInstance.$t('notification.dietaryInfosConfigUpdate'));
          vueInstance.$refs.dietaryModal.closeModal();
        },
        async confirmTopUp() {
          var vueInstance = this;
          vueInstance.isLoading = true;
          if (vueInstance.topUpAmount == null || vueInstance.topUpAmount == 0) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.chargeAmountEmpty'));
            return;
          }

          if (isNaN(vueInstance.topUpAmount)) {
            vueInstance.makeToast('danger', vueInstance.$t('notification.notNumber'));
            return;
          }

          await vueInstance.doPost('TCPConfigurePrepaymentJ.action', {
            json: "true",
            amount: vueInstance.topUpAmount.toString(),
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          vueInstance.isLoading = false;
          await vueInstance.confirmAndPayOrder();
        },
        checkEmpty(stringToCheck) {
            if(stringToCheck.indexOf("---") > -1)
            {
                return "";
            }
            else
            {
                return stringToCheck;
            }
        },
        formatDate(dateString){
            if(dateString == null || dateString == "" || dateString == undefined)
            {
                return "";
            }
            var date = new Date(dateString);
            return date.toLocaleDateString();
        },
        formatPrice(priceString){
            var formatter = new Intl.NumberFormat(this.config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
            return formatter.format(priceString);
        },
        getCampaignName(campaignId){
            for(var i  = 0; i < this.pointCampaignsDefinitions.length; i++)
            {
                if(this.pointCampaignsDefinitions[i].id == campaignId)
                {
                    return this.pointCampaignsDefinitions[i].description;
                }
            }

            return "";
        },
        wordToByteArray(wordArray) {
            var byteArray = [], word, i, j;
            for (i = 0; i < wordArray.length; ++i) {
                word = wordArray[i];
                for (j = 3; j >= 0; --j) {
                    byteArray.push((word >> 8 * j) & 0xFF);
                }
            }
            return byteArray;
        },
        startQrCodeInterval() {
            var vueInstance = this;
            vueInstance.generateQrCode();            
            vueInstance.intervalId = setInterval(() => {                               
                vueInstance.qrCodeSeconds = vueInstance.qrCodeSeconds - 1;

                if(vueInstance.qrCodeSeconds == 0)
                {               
                    vueInstance.generateQrCode();
                }                
            }, 1000);
        },
        generateQrCode() {
            var config = window.config;
            var vueInstance = this;

            // 1- Gets cardNumber
            let date = new Date();
            var newDateObj = moment(date).add(30,'seconds').utc().format('yyyyMMDDHHmmss'); 
            var barcode = this.cardNum + newDateObj;

            //checksum
            var result = '*';
            var code39Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ-. $/+%";       
            var sum = 0;
            for (var i = 0; i < barcode.length; i++)
            {
                sum += code39Chars.indexOf(barcode.charAt(i));
            }
            result = code39Chars[sum % 43];
            barcode = barcode + result;     
             
            var C = CryptoJS;
            var bytes = C.enc.Utf8.parse(barcode);                     
            // const password = 'f6@#baka';
            // var salt = [ 0x49, 0x76, 0x61, 0x6e, 0x20, 0x4d, 0x65, 0x64, 0x76, 0x65, 0x64, 0x65, 0x76 ];
            // var saltUInt8 = new Uint8Array(salt);        
            // var saltText = "";
            // saltText = new TextDecoder().decode(saltUInt8);            
            //  console.log(saltText);

            var key = new Uint8Array(32);
            var IV = new Uint8Array(16);
            key = new Uint8Array( [60, 175, 77, 70, 58, 137, 151,252,5,30,164,241,182,3,136,151,129,196,108,49,255,108,131,159,207,90,24,11,38,215,110,138] );              
            IV = new Uint8Array( [140,184,62,82,119,188,41,89,188,112,122,223,51,65,170,21] );
            var aes = C.algo.AES.createEncryptor(C.lib.WordArray.create(key), {
                mode: C.mode.CBC,
                padding: C.pad.Pkcs7,
                iv: C.lib.WordArray.create(IV)
            });

            var encrypted = aes.finalize(bytes);
            var uint8Array = this.wordToByteArray(encrypted.words);
            var uint9Array = new Uint8Array(uint8Array);                
            var qrcode = base32Encode(uint9Array, 'RFC4648', { padding: false });
            qrcode = "TCPOSAPP" + qrcode;

            if (config.USE_SHORT_QRCODE == true) {
              const response = vueInstance.doGet('TCPGetShortQRCodeJ.action',{ json: "true", code: qrcode })
              vueInstance.qrCode = response.exception !== undefined || response.QRCodeProperties === undefined ? "ERROR" : response.QRCodeProperties.shortCode;
            } else {
                vueInstance.qrCode = qrcode
            }

            vueInstance.qrCodeSeconds=30;
            vueInstance.showQrCode = true;
            vueInstance.$forceUpdate();
        },
        editAddress() {
            this.showEditAddressForm = true;
            this.showNewAddressForm = false;

            var selectedAddressId = $('#addressPicker').val(); 
            var selectedAddr = this.addresses.filter(function(x) { return x.id == selectedAddressId })[0];          
            this.selectedAddress = selectedAddr;

            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 400 }, 300);
            }, 150);

        } ,
        async updateCustomerObject() {
          var vueInstance = this;

          const result = await vueInstance.doGet('TCPAfterLoginJ.action', {
            json: "true",
          });

          if (result.exception !== undefined) {
            console.log(result.exception);
            return;
          }

          vueInstance.customer = result.customer;
          window.customer = vueInstance.customer;
          window.isGuestCheckout = result.customer != undefined && result.customer.type != undefined && result.customer.type == 2 ? true : false;
        },
        closeAddressForm(){
            setTimeout(() => {                        
                this.showEditAddressForm = false;
                this.showNewAddressForm = false;
            }, 100);

            this.refreshAddresses();
        },
        async deleteAddress() {
          let vueInstance = this;

          await vueInstance.doGet('TCPDeleteAddrJ.action', {
            json: "true",
            addrId: vueInstance.selectedAddress.id
          });

          vueInstance.confirmDeleteAddress();
        },
        async confirmDeleteAddress() {
          let vueInstance = this;
          await vueInstance.doPost('TCPDeleteAddrSubmitJ.action', {
            json: "true",
            xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined
          });

          vueInstance.makeToast('success', vueInstance.$t('notification.deleteSuccessful'));
          vueInstance.closeAddressForm();
        },
        addNewAddress(){
            this.showEditAddressForm = false;
            this.showNewAddressForm = true;
            // $("#addressFormWrapper").animate({ height: 1000 }, 900);
            setTimeout(() => {                             
                $(".content").animate({ scrollTop: 400 }, 300);
            }, 150);
        },
        async refreshAddresses() {
          let currentlySelectedAddress = $('#addressPicker').val();
          let vueInstance = this;

          const result = await vueInstance.doGet('TCPAddrBookJ.action', {
            json: "true",
          });

          if (result.exception !== undefined) {
            vueInstance.$router.go(-1);
            return;
          }

          if (result.requiresLogin) {
            await vueInstance.$router.push({path: '/Login'});
            return;
          }

          // vueInstance.customer = result.customer;
          vueInstance.addresses = result.addresses;
          vueInstance.selectedAddress = result.addresses.filter(addr => addr.id.toString() === currentlySelectedAddress.toString())[0];
          vueInstance.selectedAddressId = vueInstance.selectedAddress.id;
          vueInstance.addressLoaded = true;
          await vueInstance.updateCustomerObject();
          vueInstance.$forceUpdate();
        },
        goBack(){
            this.$router.go(-1);
        },
        modify(){
            window.customer = this.customer;
            this.$router.push({path: '/Edit', params: { customer: { "customer": this.customer} } });
        },
        changePassword(){
            this.$router.push({path: '/PasswordReset' });
        },
        showOrderHistory(){
          this.$router.push({path: '/OrderHistory' });
        },
        showTransactionHistory(){
          this.$router.push({path: '/TransactionHistory' });
        },
        showStudentAccounts(){
          this.$router.push({path: '/StudentAccounts' });
        },
        showMealPlan(){
          this.$router.push({path: '/MealPlan' });
        },
        async logout() {
          let vueInstance = this;

          const result = await vueInstance.doGet('TCPLogoutJ.action', {});
          if (result.errorCode < 0) {
            vueInstance.makeToast('danger', result.errorMsg);
            return;
          }

          window.customerLoggedIn = null;
          await vueInstance.$router.push({path: '/Login'});
        },
    },
    async created() {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'hidden';
      }

      let vueInstance = this;
      const config = this.config;

      if (window.customerLoggedIn == null) {
        await vueInstance.$router.push({path: '/Login'});
        return;
      }

      const resultAfterLogin = await vueInstance.doGet('TCPAfterLoginJ.action', {
        json: "true",
      });

      if (resultAfterLogin.exception !== undefined) {
        vueInstance.$router.go(-1);
        return;
      }
      if (resultAfterLogin.requiresLogin) {
        let path;
        if (window.immediate) {
          path = "/QRLanding?s=" + window.shopId + "&i=" + window.immediate;
        } else {
          path = "/Login";
        }
        await vueInstance.$router.push({path: path});
        return;
      }

      vueInstance.isParent = UserTypes.PARENT === resultAfterLogin.customer.custom3 ? true : false;
      vueInstance.isChild = UserTypes.CHILD === resultAfterLogin.customer.custom3 ? true : false;

      if (config.USE_ALLERGEN_FILTERS && resultAfterLogin.wondCustomer.allergenIds != undefined) {
        vueInstance.selectedAllergens = resultAfterLogin.wondCustomer.allergenIds;
      }

      if (config.USE_ALLERGEN_FILTERS && resultAfterLogin.wondCustomer.propertyIds != undefined) {
        vueInstance.selectedProperties = resultAfterLogin.wondCustomer.propertyIds;
      }

      window.isGuestCheckout = resultAfterLogin.customer != undefined && resultAfterLogin.customer.type != undefined && resultAfterLogin.customer.type == 2 ? true : false;

      vueInstance.customer = resultAfterLogin.customer;
      vueInstance.addresses = resultAfterLogin.customer.addresses;
      vueInstance.addressLoaded = true;
      vueInstance.pointsBalance = resultAfterLogin.rewardPointsAvailable;
      vueInstance.selectedAddress = resultAfterLogin.customer.addresses[0];
      vueInstance.currencyCode = resultAfterLogin.basketTile.currencyCode;

      if (resultAfterLogin.wondCustomer != undefined) {
        vueInstance.pointCampaignsDefinitions = resultAfterLogin.wondCustomer.pointCampaigns;
        vueInstance.cardNum = resultAfterLogin.wondCustomer.cardNum;
        if (resultAfterLogin.wondCustomer.cardType.toUpperCase() === "CREDIT") {
          let separator = Utils.getDecimalSeparator(resultAfterLogin.wondCustomer.formattedCreditBalance);
          vueInstance.creditBalance = resultAfterLogin.wondCustomer.creditBalance.toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
          vueInstance.customerCardWithCreditFunktion = true;
        } else {
          let separator = Utils.getDecimalSeparator(resultAfterLogin.wondCustomer.formattedPrepayBalanceCash);
          vueInstance.prepayBalanceCash = (resultAfterLogin.wondCustomer.prepayBalanceCash + resultAfterLogin.wondCustomer.prepayBalanceVoucher + resultAfterLogin.wondCustomer.prepayBalanceBonus).toFixed(config.CUSTOMER_BALANCE_DECIMAL_PLACES).replace('.', separator);
        }
        vueInstance.startQrCodeInterval();

        if (resultAfterLogin.wondCustomer.pendingTopup != undefined) {
          vueInstance.pendingCustomerCardPayments = resultAfterLogin.wondCustomer.pendingTopup;
        }
      }
    },
    mounted() {
      const mainElement = document.getElementById('app');
      if (mainElement) {
        mainElement.focus();
      }

      Utils.adjustInnerScrollHeight();
      window.addEventListener('resize', Utils.adjustInnerScrollHeight);
    },

    beforeDestroy() {
      // Event-Listener entfernen, um Speicherlecks zu vermeiden
      window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
    },

    beforeRouteLeave (to,from,next) {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'auto';
      }

        if(to.fullPath === "/Edit"){
            window.customer = this.customer;
        }

        clearInterval(this.intervalId);
        next();
    },
}
</script>

<style scoped>

   #content-wrapper{
     padding-top:0px;
     overflow-y: hidden;
   }

   #addressPicker{
        height:50px;
    }

    .btnAddress{
        min-width:10%;
    }

    .customerDataTable
    {
        padding: 10px 0px 0px 0px;
        margin-bottom:0;
        width: 50%;
        display: inline-block;
        text-align:start;
    }

    .customerDataTable ul, li
    {
        width: 100%;             
        list-style: none;

    }

    .customerJustify
    {
        width: 100%;    
        display: inline-block;
        padding-right: 0;
    }

    .customerData
    {       
        //overflow-y:auto;
        /* height: 500px; */
        display:contents;
        height:auto;
        text-align: left;     
        width: 100%;
        border-right:2px solid var(--main-color);         
    }

    @media screen and (max-width:768px) {
        .customerData
        {
            padding:0;
            border-right:0px;
        }
        .btnAddress{
            min-width:50%;
        }
    }

    @media (min-width: 768px) {
        .customerJustify
        {
            float: left;
            width: 50%;
        }        
        .customerData
        {
            float: left;
        }
    }

    @media screen and (max-width:770px) {
        #addressPicker{
            width:100% !important;
            max-width: 100% !important;
        }
    }

    .customerCardBalanceButton {
      margin-right: 0;
      margin-left: 0;
      width: 100%;
    }

   @media only screen and (max-width: 768px) {
     .accountPageWrapper .innerScroll {
       height: calc(100vh - 220px) !important;
       width: 100% !important;
       overflow-x: visible !important;
       overflow-y: auto !important;
     }
   }

   @media only screen and (max-width: 400px) {
     .accountPageWrapper .innerScroll {
       height: calc(100vh - 250px) !important;
       width: 100% !important;
       overflow-x: visible !important;
       overflow-y: auto !important;
     }
   }

   @media only screen and (max-width: 300px) {
     .accountPageWrapper .innerScroll {
       height: calc(100vh - 300px) !important;
       width: 100% !important;
       overflow-x: visible !important;
       overflow-y: auto !important;
     }
   }

</style>