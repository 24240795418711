<template>
    <div class="forgotPasswordPageWrapper">


      <div class="col-12 subPageHeader" style="position:relative;">
        <div class="row">
          <h1>{{ $t('title.passwordForgot') }}</h1>

          <div class="ml-auto row" style="padding-right: 7px;">
            <b-button ref="backButton"  class="btn btn-primary  btn-justify btn-right" @click="goBack(false)">
              {{$t('button.back')}}
            </b-button>
          </div>
        </div>
      </div>

      <div class="innerScroll">
        <b-container fluid>
          <b-form>
            <b-form-row>
              <b-col cols="12" md="12">
                <b-form-group class="form-group fontField" label-for="emailAddr">
                  <template v-slot:label>
                    {{ $t('field.email') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" v-model="$v.form.emailAddr.$model"
                                :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback" :maxLength="config.CUSTOMER_DATA.FIELDS.emailAddr.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row cols="12" md="12">
              <b-button type="submit" @click.stop="onClickCaptcha"  :disabled="submitStatus === 'PENDING' || $v.$invalid" class="btn btn-lg btn-primary btn-block btnSubmit g-recaptcha">{{ $t('button.submit') }}</b-button>
            </b-form-row>
          </b-form>
        </b-container>
      </div>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import Swal from "sweetalert2";
import Utils from "@/utils/utils";

export default {
    mounted() {
      const mainElement = document.getElementById('app');
      if (mainElement) {
        mainElement.focus();
      }

      Utils.adjustInnerScrollHeight();
      window.addEventListener('resize', Utils.adjustInnerScrollHeight);
    },
    beforeDestroy() {
      window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
    },
     data() {
        return {
          form: {
            emailAddr: undefined,
          },
            submitStatus: null,
            forgotPw: {
                email: "",
                // repeat: ""
            },
            shake: false,
            good: "",   
            responseToken: ""        
        }	
	},
    computed: {
      config() {
        return window.config;
      },
    },
    validations: {
      form: {
        emailAddr: {
          required,
          email,
        },
      },
    },
    methods: {
      validateState(name) {
        const {$dirty, $error} = this.$v.form[name];
        return $dirty ? !$error : null;
      },
        // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            var vueInstance = this;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA.enabled == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                        vueInstance.responseToken = token;
                        vueInstance.onSubmit(e);          
                    });
                });
            }
            else
            {
                vueInstance.onSubmit(e);
            }
       
        },
        goBack(){
            this.$router.go(-1);
        },
       onSubmit: async function (event) {
         event.preventDefault();

         this.$v.$touch();
         if (this.$v.$invalid) {
           this.submitStatus = 'ERROR';
           return;
         } else {
           this.submitStatus = 'OK'
         }

         let vueInstance = this;

         await vueInstance.doPost('TCPForgotPasswordSubmitJ.action', {
           json: "true",
           emailAddr: this.form.emailAddr,
           xsrfToken: window.xtoken != undefined ? btoa(window.xtoken) : undefined,
           responseToken: this.responseToken != "" ? this.responseToken : undefined
         });

         vueInstance.makeToast('success', vueInstance.$t('notification.forgotPasswordSuccessful'));
         await vueInstance.$router.push({path: '/Login'});
         this.shake = false;
       }
  },
    async created() {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'hidden';
      }
    },
    beforeRouteLeave(to, from, next) {
      var scrollDiv = document.getElementById('contentSection');
      if (scrollDiv) {
        scrollDiv.style.overflowY = 'auto';
      }

      Swal.close();
      next();
    }
}
</script>

<style scoped>

  #content-wrapper
  {
    overflow-y: hidden;
    padding-top: 0px;
  }
</style>