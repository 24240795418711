<template>
  <div class="editCustomerPageWrapper" style="padding-left: 15px; padding-right: 15px;">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="col-12 subPageHeader" style="position:relative;">
      <div class="row">
        <h1 style="word-wrap: anywhere;">{{  $t('title.transactionHistory')}}</h1>
        <div class="ml-auto row" style="padding-right: 7px;">
          <b-button class="btn btn-primary  btn-justify btn-right" @click="goBack(false)">
            {{$t('button.back')}}
          </b-button>
        </div>
      </div>
    </div>

    <div class="innerScroll">
      <date-search-table id="dateSearchTable" :callback="getTransactions" style="padding-left: 0; padding-right: 0;" />
      <b-container fluid>
        <b-row class="d-none d-md-flex">
          <b-table-simple small borderless responsive striped hover >
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.date') }}</b-th>
                <b-th class="text-left">{{ $t('table.transId') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.till') }}</b-th>
                <b-th class="text-left">{{ $t('table.prepayment') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left" />
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentTransactions)" class="va-middle">
              <b-tr v-for="item in recentTransactions" v-bind:key="item.id" @click="openTransactionDetails(item.id)" v-on:keydown.enter="openTransactionDetails(item.id)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.transNum }}</b-td>
                <b-td class="text-left">{{ item.shopDescription }}</b-td>
                <b-td class="text-left">{{ item.tillDescription }}</b-td>
                <b-td class="text-left">{{ item.prepayment ? $t('text.yes') : $t('text.no') }}</b-td>
                <b-td class="text-right">{{ item.prepayment ? item.formattedPaymentTotal : item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openTransactionDetails(item)">
                      <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                    </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="7">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentTransactions)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="5"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="1"><b>{{ transactionsFormattedTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
        <b-row class="d-md-none">
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.date') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left" />
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentTransactions)" class="va-middle">
              <b-tr v-for="item in recentTransactions" v-bind:key="item.id" @click="openTransactionDetails(item.id)" v-on:keydown.enter="openTransactionDetails(item.id)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.shopDescription }}</b-td>
                <b-td class="text-right">{{ item.prepayment ? item.formattedPaymentTotal : item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openTransactionDetails(item.id)">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="4">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentTransactions)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="2"><b>{{ transactionsFormattedTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
        <b-row>
          <b-table-simple small borderless responsive striped hover>
            <b-thead head-variant="light">
              <b-tr>
                <b-th class="text-left" colspan="7">{{ $t('table.paymentsList') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(paymentsListTotal)" class="va-middle">
              <b-tr v-for="item in paymentsListTotal" v-bind:key="item.paymentDescription">
                <b-td class="text-left" colspan="5">{{ item.paymentDescription }}</b-td>
                <b-td class="text-right" colspan="2">{{ item.formattedAmount }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-row>
      </b-container>
    </div>

    <modal ref="transactionDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.transaction') }}</h2>
      </template>

      <template v-slot:body>
        <transaction-details :transaction="currentTransactionSelected"></transaction-details>
        <div class="align-items-center justify-content-between">
          <b-button class="btn btn-primary mr-2" @click="$refs.transactionDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import TransactionDetails from "../sub-components/TransactionDetails.vue";
import Modal from "../sub-components/ModalAccessible.vue";
import DateSearchTable from "@/components/main-components/DateSearchTable.vue";
import Utils from "@/utils/utils";
import VueLoading from "vue-loading-overlay";

export default {
  components: {
    'loading': VueLoading,
    'modal': Modal,
    'transaction-details': TransactionDetails,
    'date-search-table': DateSearchTable,
  },
  data() {
    return {
      isLoading: false,
      recentTransactions: {
        Type: Array,
        default: []
      },
      paymentsListTotal: {
        Type: Array,
        default: []
      },
      currentTransactionSelected: {
        Type: Object
      },
      transactionsFormattedTotal: 0.00,
      transactionsPaymentFormattedTotal: 0.00,
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    }
  },
  methods: {
    async getTransactions(dateFrom, dateTo) {
      var vueInstance = this;
      vueInstance.isLoading = true;

      const result = await vueInstance.doGet('TCPGetTransactionsJ.action', {
        json: "true",
        showDetails: "true",
        dateFromStr: dateFrom,
        dateToStr: dateTo,
      });

      vueInstance.isLoading = false;

      vueInstance.recentTransactions = result.transactions;
      vueInstance.paymentsListTotal = result.paymentsListTotal;
      vueInstance.transactionsFormattedTotal = result.transactionsFormattedTotal;
      vueInstance.transactionsPaymentFormattedTotal = result.transactionsPaymentFormattedTotal;
    },
    openTransactionDetails(id) {
      var vueInstance = this;
      var transaction = vueInstance.recentTransactions.filter(trans => trans.id.toString() === id.toString())[0];
      if (transaction == undefined) {
        console.log("Could not load transaction data!");
        return;
      }

      vueInstance.currentTransactionSelected = transaction;
      vueInstance.$refs.transactionDetailsModal.openModal();
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  async created() {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'hidden';
    }

    await this.getTransactions(Utils.getStartOfMonth(), Utils.getEndOfMonth());
  },
  mounted() {
    const mainElement = document.getElementById('app');
    if (mainElement) {
      mainElement.focus();
    }

    Utils.adjustInnerScrollHeight();
    window.addEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  beforeDestroy() {
    window.removeEventListener('resize', Utils.adjustInnerScrollHeight);
  },

  beforeRouteLeave (to,from,next) {
    var scrollDiv = document.getElementById('contentSection');
    if (scrollDiv) {
      scrollDiv.style.overflowY = 'auto';
    }

    clearInterval(this.intervalId);
    next();
  }
}
</script>
<style scoped>

.innerScroll{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 280px);
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .innerScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 340px);
    width: 100%;
  }
}

#content-wrapper
{
  overflow-y: hidden;
}

.va-middle td{
  vertical-align: middle;
}

</style>