import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import merge from 'lodash/merge';

Vue.use(VueI18n);

async function loadLocaleMessages() {
    const locales = ['en', 'fr', 'de'];
    const defaultMessages = {};
    const customMessages = {};

    for (const locale of locales) {
        const defaultMessagesResponse = await axios.get(`${process.env.BASE_URL}settings/messages/default/${locale}.json`);
        const customMessagesResponse = await axios.get(`${process.env.BASE_URL}settings/messages/custom/${locale}.json`);
        defaultMessages[locale] = defaultMessagesResponse.data;
        customMessages[locale] = customMessagesResponse.data;
    }

    return merge({}, defaultMessages, customMessages);
}

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {},
});

loadLocaleMessages().then(messages => {
    i18n.setLocaleMessage('en_GB', messages.en);
    i18n.setLocaleMessage('de_DE', messages.de);
    i18n.setLocaleMessage('fr_FR', messages.fr);
});

export default i18n;
