import ShopPage from './components/page-components/ShopPage.vue'
import OrderOptionsPage from './components/page-components/OrderOptionsPage.vue'
import MenuPage from './components/page-components/MenuPage.vue'
import ShoppingCart from './components/page-components/ShoppingCartPage.vue'
import LoginPage from './components/page-components/LoginPage.vue'
import ForgotPasswordPage from './components/page-components/ForgotPasswordPage.vue'
import PasswordResetPage from './components/page-components/PasswordResetPage.vue'
import RegistrationPage from './components/page-components/RegistrationPage.vue'
import OrderConfirmationPage from './components/page-components/OrderConfirmationPage.vue'
import PaymentPage from './components/page-components/PaymentPage.vue'
import AccountPage from './components/page-components/AccountPage.vue'
import CheckoutFinishedPage from './components/page-components/CheckoutFinishedPage.vue'
import AddressSelectionPage from './components/page-components/AddressSelectionPage.vue'
import EditCustomerPage from './components/page-components/EditCustomerPage.vue'
import ArticlePage from './components/page-components/ArticlePage.vue'
import PaymentEndPage from './components/page-components/PaymentEndPage.vue'
import QRLandingPage from './components/page-components/QRLandingPage.vue'
import TablePayPage from './components/page-components/TablePayPage.vue'
import AccountConfirm from './components/page-components/AccountConfirm.vue'
import GuestCheckout from './components/page-components/GuestCheckoutPage.vue'
import TransactionHistoryPage from "@/components/page-components/TransactionHistoryPage.vue";
import OrderHistoryPage from "@/components/page-components/OrderHistoryPage.vue";
import MealPlanPage from "@/components/page-components/MealPlanPage.vue";
import StudentAccounts from "@/components/page-components/StudentAccounts.vue";
import RegisterAsParentPage from "@/components/page-components/RegisterAsParentPage.vue";
import RegisterAsChildPage from "@/components/page-components/RegisterAsChildPage.vue";
import TopUp from "@/components/page-components/TopUp.vue";
import ErrorSSOLandingPage from "@/components/page-components/ErrorSSOLandingPage.vue";

const routes = [
    { path: '/Shop', name: 'Shop', component: ShopPage,beforeEnter:(to,from,next) => {
        next();
    }, props: route => ({ code: route.query.code, jwt: route.query.id_token, shopId: route.query.s, validationType: route.query.validationType }) },
    { path: '/OrderOptions', name: 'OrderOptions', component: OrderOptionsPage },    
    { path: '/Account', name: 'Account', component: AccountPage },
    { path: '/Menu', name: 'Menu', component: MenuPage, 
        beforeEnter: (to, from, next) => {            
            if(window.shopSelected != undefined) {
                next();
            } else {
                this.$router.push({ path:'/Shop' });
            }
        }
    },   
    { path: '/Cart', name: 'Cart', component: ShoppingCart },
    { path: '/Login', name: 'Login', component: LoginPage },
    { path: '/ForgotPassword', name: 'ForgotPassword', component: ForgotPasswordPage },
    { path: '/Register', name: 'Register', component: RegistrationPage, props: (route) => ({
        isGuest: "isGuest",
        ...route.params
    }) },
    { path: '/RegisterAsParent', name: 'RegisterAsParent', component: RegisterAsParentPage },
    { path: '/RegisterAsChild', name: 'RegisterAsChild', component: RegisterAsChildPage },
    { path: '/Guest', name: 'Guest', component: GuestCheckout, props: (route) => ({
        isGuest: "isGuest",
        ...route.params
    }) },
    { path: '/OrderConfirmation', name: 'OrderConfirmation', component: OrderConfirmationPage },
    { path: '/CheckoutFinished', name: 'CheckoutFinished', component: CheckoutFinishedPage },
    { path: '/PaymentEnd', name: 'PaymentEnd', component: PaymentEndPage, props: route => ({ payment: route.query.p, result: route.query.r }) },
    { path: '/Payment', name: 'Payment', component: PaymentPage },
    { path: '/PasswordReset', name: 'PasswordReset', component: PasswordResetPage },
    { path: '/Address', name: 'Address', component: AddressSelectionPage },
    { path: '/Article', name: 'Article', component: ArticlePage,  props: route => ({ article: route.query.a }) },
    { path: '/Edit', name: 'Edit', component: EditCustomerPage,  props: (route) => ({
        user: "userData",
        ...route.params
    })},
    { path: '/ErrorSSO', name: 'ErrorSSO', component: ErrorSSOLandingPage },
    { path: '/TablePay', name: 'TablePay', component: TablePayPage },
    { path: '/TopUp', name: 'TopUp', component: TopUp },
    { path: '/MealPlan', name: 'MealPlan', component: MealPlanPage },
    { path: '/OrderHistory', name: 'OrderHistory', component: OrderHistoryPage },
    { path: '/TransactionHistory', name: 'TransactionHistory', component: TransactionHistoryPage },
    { path: '/StudentAccounts', name: 'StudentAccounts', component: StudentAccounts },
    { path: '/QRLanding', name: 'QRLanding', component: QRLandingPage, props: route => ({ table: route.query.t, shop: route.query.s, home: route.query.h, code: route.query.code, jwt: route.query.id_token, immediate: route.query.i }) },
    { path: '/https://www.google.hr',  beforeEnter() { location.href = 'https://www.google.hr' }},
    { path: '/Confirm', name: 'Confirm', component: AccountConfirm,  props: route => ({ k: route.query.key, m: route.query.m, i: route.query.i}) }
    // { path: '*', redirect: '/dist' }
];

export default routes;